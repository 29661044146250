<template>
    <v-card
      class="mx-auto"
      width="100%"
      v-if="statistic != null"
    >
    <v-card-title>
      <v-container fluid >
        <v-row align="center"
          justify="center">
          <v-col cols="12" sm="4" md="4" class="text-center font-weight-bold">
            <v-text-field type="date" label="Vom" v-model="from"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="text-center font-weight-bold">
            <v-text-field  type="date" v-model="until" label="Bis"></v-text-field>
          </v-col>
          <v-col  cols="12" sm="4" md="4" class="text-center font-weight-bold">
            <v-btn @click="loadStatistic()" :disabled="until === '' || from === ''" color="indigo accent-4"  outlined>Zeitraum ändern</v-btn>
          </v-col>
      </v-row>
    </v-container>
    </v-card-title>
    <v-container fluid >
      <v-row>
        <v-row align="center"
          justify="center">
          <v-col cols="12" sm="6" md="6">
            <div class="text-center font-weight-bold">
             <v-col>Gesamtflugzeit: {{ statistic.totalFlightTime }}</v-col>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="text-center font-weight-bold">
              <v-col>Gesamtanzahl an Landungen: {{ statistic.totalLandings }}</v-col>
            </div>
          </v-col>
      </v-row>

      </v-row>
      <v-divider></v-divider>
      <v-row align="center"
          justify="center">
          <v-col>
            <div class="text-center">
              <h2>Flugerfahrung als Pilot-In-Command</h2>
            </div>
          </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Flugzeug-Klasse
          </th>
          <th class="text-left">
            Flugstunden
          </th>
          <th class="text-left">
            Landungen
          </th>
          <th class="text-left">
            davon F-Schlepps
          </th>
          <th class="text-left">
            davon Überprüfungen
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Segelflugzeuge</td>
          <td>{{ statistic.picFlightsGlider }}</td>
          <td>{{ statistic.picFlightsGliderLandings }}</td>
          <td>{{ statistic.picTowGlider }} (als Segler)</td>
          <td> {{ statistic.checkFlightsGlider}} / {{ statistic.checkFlightsGliderLandings }} </td>
        </tr>
        <tr>
          <td>Motorsegler (TMG)</td>
          <td>{{ statistic.picFlightsMoSe }}</td>
          <td>{{ statistic.picFlightsMoSeLandings }}</td>
          <td>{{ statistic.picTowMoSe }} (als Schlepper)</td>
          <td>{{ statistic.checkFlightsMoSe}} / {{ statistic.checkFlightsMoSeLandings }}</td>
        </tr>
        <tr>
          <td>Ultraleicht (UL)</td>
          <td>{{ statistic.picFlightsUL }}</td>
          <td>{{ statistic.picFlightsULLandings }}</td>
          <td>{{ statistic.picTowUL }} (als Schlepper)</td>
          <td>{{ statistic.checkFlightsUL}} / {{ statistic.checkFlightsULLandings }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row align="center"
          justify="center">
          <v-col>
            <div class="text-center">
              <h2>Flugerfahrung als Flugschüler</h2>
            </div>
          </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Flugzeug-Klasse
          </th>
          <th class="text-left">
            Flugstunden
          </th>
          <th class="text-left">
            Landungen
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Segelflugzeuge</td>
          <td>{{ statistic.studentFlightsGlider }}</td>
          <td>{{ statistic.studentFlightsGliderLandings }}</td>
        </tr>
        <tr>
          <td>Motorsegler (TMG)</td>
          <td>{{ statistic.studentFlightsMoSe }}</td>
          <td>{{ statistic.studentFlightsMoSeLandings }}</td>
        </tr>
        <tr>
          <td>Ultraleicht (UL)</td>
          <td>{{ statistic.studentFlightsUL }}</td>
          <td>{{ statistic.studentFlightsULLandings }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row align="center"
          justify="center">
          <v-col>
            <div class="text-center">
              <h2>Flugerfahrung als Fluglehrer</h2>
            </div>
          </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Flugzeug-Klasse
          </th>
          <th class="text-left">
            Flugstunden
          </th>
          <th class="text-left">
            Landungen
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Segelflugzeuge</td>
          <td>{{ statistic.instructorFlightsGlider }}</td>
          <td>{{ statistic.instructorFlightsGliderLandings }}</td>
        </tr>
        <tr>
          <td>Motorsegler (TMG)</td>
          <td>{{ statistic.instructorFlightsMoSe }}</td>
          <td>{{ statistic.instructorFlightsMoSeLandings }}</td>
        </tr>
        <tr>
          <td>Ultraleicht (UL)</td>
          <td>{{ statistic.instructorFlightsUL }}</td>
          <td>{{ statistic.instructorFlightsULLandings }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    </v-card>
  </template>
<script>
import moment from 'moment'
export default {
  name: 'UserFlightStatisticCard',
  data: () => ({
    statistic: null,
    from: '',
    until: ''
  }),
  props: {
    customUserID: {
      type: Number,
      default () {
        return null
      },
      required: false
    }
  },
  methods: {
    formatDate (date) {
      return date != null ? moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY') : 'Abhängig'
    },
    loadStatistic () {
      var that = this
      var data = { dStart: this.from, dEnd: this.until, customUserID: this.customUserID }
      this.$store.dispatch('loadUserFlightStatistic', data).then(response => {
        that.statistic = this.replaceNullValues(response.data.result)
      })
    },
    replaceNullValues (dataObject) {
      for (const [key, value] of Object.entries(dataObject)) {
        if (value === null) {
          dataObject[key] = 'Keine'
        }
      }
      return dataObject
    }
  },
  mounted () {
    this.from = moment().subtract(2, 'years').format('YYYY-MM-DD')
    this.until = moment().format('YYYY-MM-DD')
    this.loadStatistic()
  }
}
</script>
