<template>
  <div>
    <v-dialog v-model="dialog" v-if="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Bearbeiten</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedItem.firstname" :rules="rules.standardRequiredRule" label="Vorname"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedItem.lastname" :rules="rules.standardRequiredRule" label="Nachname"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedItem.userName" disabled label="Username" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete :items="[
                  { short: 'M', long: 'Male' },
                  { short: 'F', long: 'Female' }
                ]" v-model="editedItem.gender" item-text="long" item-value="short" label="Gender"
                  required></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="editedItem.birthday" label="Geburtstag" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.birthday" :active-picker.sync="activePicker"
                    :max="new Date().toISOString().substr(0, 10)" min="1950-01-01"
                    @change="saveBirthDate"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.mail" :rules="rules.emailRules" label="E-Mail" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedItem.tel" :rules="rules.phoneNumberRequiredRule" label="Tel" type="text"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedItem.tel2" :rules="rules.standardRequiredRule" type="text" label="Tel 2"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedItem.country" :rules="rules.standardRequiredRule" label="Land"
                  required></v-text-field>
              </v-col>
              <v-col cols="7" sm="7" md="7">
                <v-text-field v-model="editedItem.town" :rules="rules.standardRequiredRule" label="Stadt"
                  required></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field v-model="editedItem.zip" :rules="rules.standardIntegerRequiredRule" type="text"
                  label="PLZ" required></v-text-field>
              </v-col>
              <v-col cols="10" sm="10" md="10">
                <v-text-field v-model="editedItem.street" :rules="rules.standardRequiredRule" label="Straße"
                  required></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field v-model="editedItem.houseNo" :rules="rules.standardIntegerRequiredRule" label="Nr."
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="editedItem.club" :rules="rules.standardRequiredRule" label="Club" disabled
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete :items="$store.state.MembershipTypeList" v-model="editedItem.membership" item-text="title"
                  item-value="typeID" label="Mitglied" required disabled></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red darken-1" text @click="close">
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="save">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAccountingDetails" v-if="dialogAccountingDetails" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Konto Informationen</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="computedUserInfo.accountingName" disabled label="Abrechnungsname"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="computedUserInfo.reducedRate" disabled label="Vermindert"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="computedUserInfo.membershipFee1" disabled label="MB1"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="computedUserInfo.membershipFee1Date" disabled label="MB1 Datum"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="computedUserInfo.membershipFee2" disabled label="MB2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="computedUserInfo.membershipFee2Date" disabled label="MB2 Datum"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="computedUserInfo.bankOffset" disabled label="Bank Offset"></v-text-field>
              </v-col>
              <v-col cols="12" sm="9" md="9">
                <v-text-field v-model="computedUserInfo.bankName" disabled label="Bank Name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="computedUserInfo.bic" disabled label="BIC"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-text-field v-model="computedUserInfo.iban" disabled label="IBAN"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="computedUserInfo.mandateReference" disabled label="Mandat Referenz"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="computedUserInfo.mandateDate" disabled label="Mandat Datum"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea v-model="computedUserInfo.bankComment" rows="2" disabled label="Bank Kommentar"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red darken-1" text @click="dialogAccountingDetails = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogChangePassword" v-if="dialogChangePassword" max-width="500px">
      <v-card>
        <v-card-title color="orange">
          <span class="text-h5">Passwort ändern</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field v-model="changePassword.password" label="Passwort (mind. 6 zeichen)" required></v-text-field>
            </v-row>
            <v-row>
              <v-text-field v-model="changePassword.confirmPassword" label="Passwort bestätigen" required></v-text-field>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeChangePassword">
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text
            :disabled="changePassword.password.length < 6 || changePassword.password != changePassword.confirmPassword"
            @click="changePasswordSubmit">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-row no-gutter>
        <v-col cols="12" xs="12" sm="6" md="4" xl="4">
          <v-container>
            <v-row>
              Name: {{ $store.state.UserInfo.lastname }},
              {{ $store.state.UserInfo.firstname }} (
              <strong>{{ $store.state.UserInfo.userName }}</strong> )
            </v-row>
            <v-row>
              Geburtstag: {{ formatDate($store.state.UserInfo.birthday) }}
            </v-row>
            <v-row> Geschlecht: {{ $store.state.UserInfo.gender }} </v-row>
            <v-row> Email: {{ $store.state.UserInfo.mail }} </v-row>
            <v-row> Tel: {{ $store.state.UserInfo.tel }} </v-row>
            <v-row> Tel (alternativ): {{ $store.state.UserInfo.tel2 }} </v-row>
          </v-container>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" xs="12" sm="6" md="4" xl="4">
          <v-container>
            <v-row> Land: {{ $store.state.UserInfo.country }} </v-row>
            <v-row>
              Stadt: {{ $store.state.UserInfo.zip }},
              {{ $store.state.UserInfo.town }}
            </v-row>
            <v-row> Straße: {{ $store.state.UserInfo.street }} </v-row>
            <v-row> Hausnummer: {{ $store.state.UserInfo.houseNo }} </v-row>
            <v-row>
              Position: {{ $store.state.UserInfo.position != null ? $store.state.UserInfo.position : 'Keine' }}
            </v-row>
          </v-container>
        </v-col>
        <!-- <v-divider vertical></v-divider>
        <v-col cols="12" xs="12" sm="12" md="4" xl="3">
          <v-container class="px-0" fluid>
            <v-row no-gutter>
              <v-col style="padding-top: 0px;">
                Segelflug
                <v-radio-group v-model="radioGroupGliding" style="margin-top: 0px; margin-bottom: 2px;" hide-details>
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio v-bind="attrs" v-on="on" :label="`Gefahr`" :value="3" color="red" readonly style="margin-bottom: 4px;"></v-radio>
                    </template>
                    <v-img src="@/assets/trainingsbarometer/red.png"></v-img>
                  </v-tooltip>
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio v-bind="attrs" v-on="on" :label="`Ungeübt`" :value="2" color="orange" readonly style="margin-bottom: 4px;"></v-radio>
                    </template>
                    <v-img src="@/assets/trainingsbarometer/orange.png"></v-img>
                  </v-tooltip>
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio v-bind="attrs" v-on="on" :label="`Geübt`" :value="1" color="green" readonly></v-radio>
                    </template>
                    <v-img src="@/assets/trainingsbarometer/green.png"></v-img>
                  </v-tooltip>
                </v-radio-group>
                <h6>Starts: {{ computedUserInfo.takeoffsGlider || 0 }}</h6><h6> Flugzeit: {{ (computedUserInfo.flightTimeGlider/60).toFixed(2) }}h</h6>
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col  style="padding-top: 0px;">
                Motorflug
                <v-radio-group v-model="radioGroupMose" style="margin-top: 0px; margin-bottom: 2px;" hide-details>
                  <v-radio :label="`Gefahr`" :value="3" color="red" readonly style="margin-bottom: 4px;"></v-radio>
                  <v-radio :label="`Unsicher`" :value="2" color="orange" readonly style="margin-bottom: 4px;"></v-radio>
                  <v-radio :label="`Geübt`" :value="1" color="green" readonly ></v-radio>
                </v-radio-group>
                <h6>Starts: {{ computedUserInfo.takeoffsMotorGlider || 0 }}</h6><h6> Flugzeit: {{ (computedUserInfo.flightTimeMotorGlider/60).toFixed(2) }}h</h6>
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col  style="padding-top: 0px;">
                UL
                <v-radio-group v-model="radioGroupUL" style="margin-top: 0px; margin-bottom: 2px;" hide-details>
                  <v-radio :label="`Gefahr`" :value="3" color="red" readonly style="margin-bottom: 4px;"></v-radio>
                  <v-radio :label="`Unsicher`" :value="2" color="orange" readonly style="margin-bottom: 4px;"></v-radio>
                  <v-radio :label="`Geübt`" :value="1" color="green" readonly></v-radio>
                </v-radio-group>
                <h6>Starts: {{ computedUserInfo.takeoffsUL || 0}}</h6><h6> Flugzeit: {{ (computedUserInfo.flightTimeUL/60).toFixed(2) }}h</h6>
              </v-col>
            </v-row>
          </v-container>
        </v-col> -->
        <v-divider vertical></v-divider>
        <v-col cols="12" xs="12" sm="4" md="4" xl="2" style="padding: 0px">
          <v-container style="padding-left: 2px;padding-right: 2px;">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Position
                    </th>
                    <th class="text-left">
                      Stand
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Flugkosten</td>
                    <td class="red--text">{{ computedBalance.flightCost || '0.00' }}€</td>
                  </tr>
                  <tr>
                    <td>Transaktionen</td>
                    <td :class="classColor(computedBalance.transactions) + '--text'">{{ computedBalance.transactions ||
                      '0.00' }}€</td>
                  </tr>
                  <tr class="font-weight-bold">
                    <td>Total</td>
                    <td :class="classColor(computedBalance.total) + '--text'">{{ computedBalance.total || '0.00' }}€</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" xs="12" sm="3" md="4" xl="2">
          <v-container>
            <v-row>
              <v-btn outlined color="green" @click="
                editedItem = $store.state.UserInfo;
              dialog = true; " :disabed="$store.state.UserInfo === null">Profil bearbeiten
              </v-btn>
            </v-row>
            <v-row style="padding-top: 5px">
              <v-btn outlined color="orange" @click="
                dialogChangePassword = true;
                              " :disabed="$store.state.UserInfo === null">Passwort ändern
              </v-btn>
            </v-row>
            <v-row style="padding-top: 5px">
              <v-btn outlined color="grey" @click="
                dialogAccountingDetails = true;
                              " :disabed="$store.state.UserInfo === null">Konto Information
              </v-btn>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'InformationCard',
  data: () => ({
    dialog: false,
    menu: false,
    canChangeUsers: false,
    activePicker: null,
    search: '',
    trainingsBarometer: { // Highest Taget Score: 30h => 1800min; 40 Takeoffs ==> 72.000
      minOrangeScore: 9000,
      minGreenScore: 36000 // ca 50%
    },
    rules: {
      standardRequiredRule: [
        value => !!value || 'Muss angegeben werden!',
        value => value.length < 32 || 'Maximal 32 Zeichen erlaubt'
      ],
      standardIntegerRequiredRule: [
        value => !!value || 'Muss angegeben werden!'
      ],
      phoneNumberRequiredRule: [
        value => !!value || 'Telefonnummer muss angegeben werden!',
        value => value.length > 11 || 'Mindestens 12 Zahlen benötigt'
      ],
      emailRules: [
        v => !!v || 'E-Mail muss angegeben werden',
        v => /.+@.+\..+/.test(v) || 'Korrekte E-Mail angeben!'
      ]
    },
    editedItem: {
      userName: '',
      firstname: '',
      lastname: '',
      mail: '',
      tel: '',
      tel2: '',
      street: '',
      membership: '',
      houseNo: '',
      zip: '',
      town: '',
      country: '',
      club: '',
      gender: '',
      birthday: ''
    },
    dialogChangePassword: false,
    dialogAccountingDetails: false,
    changePassword: {
      password: '',
      confirmPassword: ''
    }
  }),
  computed: {
    computedBalance () {
      var balance = this.$store.state.UserBalance
      if (typeof (balance) === 'object' && Object.keys(balance).length > 0) {
        return balance
      }
      return []
    },
    computedUserInfo () {
      return this.$store.state.UserInfo
    },
    radioGroupGliding () {
      const takeoffsGlider = this.computedUserInfo.takeoffsGlider
      const minutesGlider = this.computedUserInfo.flightTimeGlider
      const score = takeoffsGlider * minutesGlider
      switch (score) {
        case (score <= this.trainingsBarometer.minOrangeScore):
          return 3
        case (score <= this.trainingsBarometer.minGreenScore):
          return 2
        case (score > this.trainingsBarometer.minGreenScore):
          return 1
        default:
          return 3 // default: red
      }
    },
    radioGroupMose () {
      const takeoffsMotorGlider = this.computedUserInfo.takeoffsMotorGlider
      const minutesMotorGlider = this.computedUserInfo.flightTimeMotorGlider
      const score = takeoffsMotorGlider * minutesMotorGlider
      switch (score) {
        case (score <= this.trainingsBarometer.minOrangeScore):
          return 3
        case (score <= this.trainingsBarometer.minGreenScore):
          return 2
        case (score > this.trainingsBarometer.minGreenScore):
          return 1
        default:
          return 3 // default: red
      }
    },
    radioGroupUL () {
      const takeoffsUL = this.computedUserInfo.takeoffsUL
      const minutesUL = this.computedUserInfo.flightTimeUL
      const score = takeoffsUL * minutesUL
      switch (score) {
        case (score <= this.trainingsBarometer.minOrangeScore):
          return 3
        case (score <= this.trainingsBarometer.minGreenScore):
          return 2
        case (score > this.trainingsBarometer.minGreenScore):
          return 1
        default:
          return 3 // default: red
      }
    }
  },
  methods: {
    formatDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    classColor (value) {
      if (parseFloat(value) < 0) {
        return 'red'
      }

      return 'green'
    },
    editItem (item) {
      this.editedIndex = this.$store.state.PilotList.indexOf(item)
      this.$store.dispatch('getFullUserInfo', item.userID).then(response => {
        var data = response.data.result
        //  console.log(data)
        for (var [key, value] of Object.entries(data)) {
          if (!isNaN(value) && value !== null) {
            data[key] = parseInt(value)
          }
        }
        this.editedItem = data
      })
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.editedItem = this.$store.state.UserInfo
    },
    closeChangePassword () {
      this.dialogChangePassword = false
    },
    saveBirthDate (date) {
      this.$refs.menu.save(date)
    },
    save () {
      // validate USER
      // Edit User
      this.$store.dispatch('validateUser', this.editedItem).then(response => {
        //  console.log(response)
        if (response.data.noErrors === 0) {
          // Update USER
          this.$store.dispatch('updateUser', this.editedItem).then(response => {
            if (parseInt(response.data.noErrors) === 0) {
              this.$store.dispatch('loadPilotList')
              this.$store.dispatch('loadUserInfo')
              this.close()
            } else {
              this.$store.commit('setApiErrorResponse', response.data)
            }
          })
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    changePasswordSubmit () {
      this.$store.dispatch('changePassword', { password: this.changePassword.password }).then(response => {
        if (parseInt(response.data.noErrors) === 0) {
          this.closeChangePassword()
          this.$store.commit('logout')
          this.$router.push({ name: 'login' })
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    }
  },
  async mounted () {
    this.$store.dispatch('loadUserBalance')
  }
}
</script>
