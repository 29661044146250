<template>
<v-container fluid>
  <CURDTable tableId="expertFlightsTable" title="Datenbank Tabelle Tags" insertButtonText="neuer Datensatz" :enableDateSpan="false"
              loadDataPath="VUE_APP_API_PATH_TAGS_GET_ALL" dataUIDField="tagID"
              insertDataPath="VUE_APP_API_PATH_TAGS_ADD" updateDataPath="VUE_APP_API_PATH_TAGS_EDIT" removeDataPath="VUE_APP_API_PATH_TAGS_DELETE"
              showSearch remove insert actionsLeft></CURDTable>
</v-container>
</template>

<script>
import CURDTable from '../../components/interactiveAdminTable.vue'
export default {
  name: 'ExpertView',
  data: () => ({
  }),
  components: {
    CURDTable
  }

}
</script>../../components/interactiveAdminTable.vue
