<template>
<v-container fluid>
   <CURDTable tableId="costCenterTable" title="Kostenstellen" newItemText="Neue Kostenstelle"
              editItemText="Transaktion Bearbeiten" :dataSchema="dataSchemaCostCenterBalance" loadDataFunction="loadAllCostCenterBalance" insertDataFunction="costCenter"
              storeData="CostCenterBalanceList" showNumberOfItems showSearch update info insert  @double-click-on-row="navigateToCostCenterOverview" enableDateSpan></CURDTable>
 </v-container>
</template>

<script>
import CURDTable from '../../components/curdTable.vue'
export default {
  name: 'AdminCostCenters',
  data: () => ({
    dataSchemaCostCenterBalance: [
      { dataId: 'costCenterID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'name', type: 'text', text: 'Name', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'transactionsPosSum', type: 'float', steps: 0.01, text: 'Einnahmen Transaktionen', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'transactionsNegSum', type: 'float', steps: 0.01, text: 'Ausgaben Transaktionen', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'flightCostPosSum', type: 'float', steps: 0.01, text: 'Einnahmen Flug', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'flightCostNegSum', type: 'float', steps: 0.01, text: 'Ausgaben Flug', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'paybackPosSum', type: 'float', steps: 0.01, text: 'Einnahmen Erstattung', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'paybackNegSum', type: 'float', steps: 0.01, text: 'Ausgaben Erstattung', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'total', type: 'float', steps: 0.01, text: 'Total', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'comment', type: 'text', text: 'Kommentar', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true }
    ]
  }),
  components: {
    CURDTable
  },
  methods: {
    navigateToCostCenterOverview (value) {
      //  this.$router.push({ name: 'adminCostCentersInfo', params: { id: value.costCenterID } })
      const routeData = this.$router.resolve({ name: 'adminCostCentersInfo', params: { id: value.costCenterID } })
      window.open(routeData.href, '_blank')
    }
  }

}
</script>
