<template>
  <v-container fluid>
    <v-row>
      <v-container>
        Zum Laden der Bauteile, das jeweilige Flugzeug in der Flugzeug-Tabelle <strong>doppelt</strong> anklicken.
      </v-container>
    </v-row>
    <v-row>
      <v-expansion-panels accordion v-model="panel" id="panel">
        <v-expansion-panel @click="plane = {}">
          <v-expansion-panel-header class="title" v-if="(panel !== 0)">Flugzeug-Auswahl</v-expansion-panel-header>
          <v-expansion-panel-content>
            <CURDTable tableId="planeTable" title="Flugzeuge" newItemText="Neues Flugzeug"
              editItemText="Flugzeug Bearbeiten" :dataSchema="dataSchemaPlane" loadDataFunction="loadPlaneList"
              insertDataFunction="insertPlane" updateDataFunction="updatePlane" storeData="PlaneList" showNumberOfItems
              showSearch update insert @double-click-on-row="changePlane"></CURDTable>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :disabled="panel !== 1">
          <v-expansion-panel-header class="title" v-if="(panel !== 1)">Teileliste und Datein</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels accordion v-model="subpanel" id="subpanel">
              <v-expansion-panel>
                <v-expansion-panel-header class="title">Flugzeugteile | {{ plane.callsign }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <CURDTable tableId="planePartTable" :title="`Teile | ${plane.callsign}`" newItemText="Neues Teil"
                    editItemText="Teil bearbeiten" infoItemText="Info" :dataSchema="dataSchemaPlanePart"
                    loadDataFunction="getPlaneParts" :loadDataFunctionPayload="plane" removeDataFunction="deletePlanePart"
                    insertDataFunction="insertPlanePart" updateDataFunction="updatePlanePart" storeData="PlanePartsList"
                    showNumberOfItems showSearch update remove info insert dialogWidth="800px" showSelectHeaders></CURDTable>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="title">Flugzeug - Dokumente | {{ plane.callsign }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <CURDTable tableId="planeDocsTable" :title="`Dokumente | ${plane.callsign}`" newItemText="Neues Dokument"
                    editItemText="Dokument bearbeiten" infoItemText="Info" :dataSchema="dataSchemaPlaneDocs"
                    loadDataFunction="getPlaneDocs" :loadDataFunctionPayload="plane"
                    insertDataFunction="insertPlaneDocs"  storeData="PlaneDocsList"
                    showNumberOfItems showSearch info insert dialogWidth="800px" rightClickMenu remove removeDataFunction="deletePlaneDocs"></CURDTable>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="title">Flugzeug - Schadensmeldungen | {{ plane.callsign }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <CURDTable tableId="planeDocsTable" :title="`Meldungen | ${plane.callsign}`" newItemText="Neue Meldung"
                    editItemText="Meldung bearbeiten" infoItemText="Info" :dataSchema="dataSchemaPlaneFlightReport"
                    loadDataFunction="getPlaneFlightReports" :loadDataFunctionPayload="plane" updateDataFunction="updateFlightReport"
                    insertDataFunction="insertFlightReport"  storeData="PlaneFlightReportList" removeDataFunction="deleteFlightReport"
                    showNumberOfItems showSearch info insert update dialogWidth="800px" rightClickMenu remove></CURDTable>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>
<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
<script>
import CURDTable from '../components/curdTable.vue'
export default {
  name: 'PlaneMaintenance',
  data: () => ({
    panel: 0, // PlaneList = 0, Parts-List = 1
    subpanel: 0, // PlaneParts= 0, Plane-Documents = 1
    plane: {},
    dataSchemaPlane: [
      { dataId: 'callsign', type: 'text', text: 'Callsign', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'planeName', type: 'text', text: 'Flugzeug', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'type', type: 'autocomplete', text: 'Typ', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PlaneTypeList', autocompleteValue: 'typeID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'seats', type: 'number', text: 'Sitze', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 6, showInInputDialog: true },
      { dataId: 'club', type: 'text', text: 'Club', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 6, showInInputDialog: true },
      { dataId: 'arcDueDate', type: 'date', text: 'ARC bis', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'towplane', type: 'checkbox', text: 'Schleppflugzeug', showInTable: true, defaultValue: 0, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'intToYesNo' },
      { dataId: 'accounting', type: 'checkbox', text: 'accounting?', showInTable: false, defaultValue: false, sortable: true, cols: 12, colSm: 6, colMd: 6, showInInputDialog: true },
      { dataId: 'accountingMotortime', type: 'checkbox', text: 'accountingMotortime?', showInTable: false, defaultValue: false, sortable: true, cols: 12, colSm: 6, colMd: 6, showInInputDialog: true },
      { dataId: 'booking', type: 'checkbox', text: 'Buchung?', showInTable: false, defaultValue: false, sortable: true, cols: 12, colSm: 6, colMd: 6, showInInputDialog: true },
      { dataId: 'showSK', type: 'checkbox', text: 'in GlideStone anzeien?', showInTable: false, defaultValue: false, sortable: true, cols: 12, colSm: 6, colMd: 6, showInInputDialog: true },
      { dataId: 'color', type: 'text', text: 'Farbe Buchung', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'colorOwn', type: 'text', text: 'Farbe eigene Buchung', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'instructors', type: 'text', text: 'Lehrer', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'lastMotorStop', type: 'float', steps: 0.01, text: 'letzter Motorstop', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'hours', type: 'float', steps: 0.01, text: 'Stunden', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'takeoffs', type: 'number', text: 'Starts', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'priceTimes', type: 'float', steps: 0.01, text: 'priceTimes', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'minutePrice', type: 'float', steps: 0.01, text: 'minutePrice', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'minutePriceGuest', type: 'float', steps: 0.01, text: 'minutePriceGuest', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'minutePriceHonorary', type: 'float', steps: 0.01, text: 'minutePriceHonorary', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'minutePricePassive', type: 'float', steps: 0.01, text: 'minutePricePassive', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'minutePriceReduced', type: 'float', steps: 0.01, text: 'minutePriceReduced', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'towPriceMinute', type: 'float', steps: 0.01, text: 'towPriceMinute', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true }
    ],
    dataSchemaPlanePart: [
      { dataId: 'partID', type: 'text', text: '#ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, selectHeaderOnInit: true },
      { dataId: 'planeID', type: 'text', text: 'PlaneID', defaultValue: '', showInTable: false, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'description', type: 'text', text: 'Beschreibung', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'serialNo', type: 'text', text: 'Serien Nr', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'partNo', type: 'text', text: 'Teile Nr', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'active', type: 'checkbox', text: 'Verbaut?', defaultValue: 0, showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 2, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'intToYesNo' },
      { dataId: 'previousTakeoffs', type: 'number', text: 'Starts vor Einbau', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 5, showInInputDialog: true },
      { dataId: 'previousFlightTime', type: 'float', steps: 0.01, text: 'Zeit vor Einbau', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 5, showInInputDialog: true },
      { dataId: 'installDate', type: 'date', text: 'installiert am', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'installFlightTime', type: 'float', steps: 0.01, text: 'Zeit bei Installation', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'installTakeoffs', type: 'number', text: 'Starts bei Installation', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'uninstallDate', type: 'date', text: 'ausgebaut am', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'uninstallFlightTime', type: 'float', steps: 0.01, text: 'Zeit bei Ausbau', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'uninstallTakeoffs', type: 'number', text: 'Starts bei Ausbau', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'checkDate', type: 'date', text: 'Überprüfung am', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'checkFlightTime', type: 'float', steps: 0.01, text: 'Überprüfung bei Zeit', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'checkTakeoffs', type: 'number', text: 'Überprüfung bei Starts', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'manufacturer', type: 'text', text: 'Hersteller', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'link', type: 'text', text: 'Link', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 6, showInInputDialog: true },
      { dataId: 'lastPrice', type: 'float', steps: 0.01, text: 'letzer Preis', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 2, showInInputDialog: true },
      { dataId: 'comment', type: 'text', text: 'Kommentar', defaultValue: '', showInTable: true, widthInTable: '120px', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true }
    ],
    dataSchemaPlaneDocs: [
      { dataId: 'planeID', type: 'number', text: 'PlaneID', defaultValue: '', showInTable: false, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'category', type: 'autocomplete', text: 'Kategorie', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PlaneDocsCategories', autocompleteValue: 'categoryID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'title', type: 'text', text: 'Titel', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'validUntil', type: 'date', text: 'Gültig bis', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'comment', type: 'text', text: 'Kommentar', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'file', type: 'file', text: 'Datei', showInTable: true, defaultValue: [], sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'stringToDownloadButton', downloadId: 'documentID', downloadPath: process.env.VUE_APP_API_PATH_PLANE_DOCS_DOWNLOAD }
    ],
    dataSchemaPlaneFlightReport: [
      { dataId: 'reportID', type: 'number', text: 'ReportID', defaultValue: '', showInTable: false, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'planeID', type: 'number', text: 'PlaneID', defaultValue: '', showInTable: false, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'creatorID', type: 'autocomplete', text: 'Mitglied', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'creationDate', type: 'date', text: 'erstellt am', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'comment', type: 'textarea', text: 'Meldung', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, widthInTable: '500px' },
      { dataId: 'status', type: 'checkbox', text: 'geprüft', showInTable: true, defaultValue: 0, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'intToYesNo' },
      { dataId: 'grounded', type: 'checkbox', text: 'grounded?', showInTable: true, defaultValue: 0, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'intToYesNo' }
    ]
  }),
  components: {
    CURDTable
  },
  methods: {
    changePlane (item) {
      this.plane = item
      this.panel = 1

      //  STATIC WARNING!!!!
      this.dataSchemaPlanePart[1].defaultValue = item.planeID
      this.dataSchemaPlaneDocs[0].defaultValue = item.planeID
      this.dataSchemaPlaneFlightReport[1].defaultValue = item.planeID
    }
  },
  computed: {
  },
  async mounted () {
  }
}
</script>
