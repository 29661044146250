<template>
  <v-container fluid>
    <v-btn @click="$router.push({ name: 'adminMembers' })" rounded color="primary">       <v-icon
        left
        dark
      >
        fa-arrow-left
      </v-icon>Zurück
</v-btn>
    <div class="display-1">
      {{computedUserName}}´s Kontostand beträgt: <span :class="classColor(computedBalance.total) + '--text'"> {{ computedBalance.total || '0.00' }}€ </span>
    </div>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <CURDTable tableId="userDocsTable" title="Dokumente" newItemText="Neues Dokument"
                    editItemText="Dokument bearbeiten" infoItemText="Info" :dataSchema="dataSchemaUserDocs"
                    loadDataFunction="loadUserDocuments" :loadDataFunctionPayload="$route.params.id"
                    insertDataFunction="insertUserDocuments"  storeData="UserDocsList"
                    showNumberOfItems showSearch info insert dialogWidth="800px" rightClickMenu remove removeDataFunction="deleteUserDocuments" v-if="dataSchemaUserDocs[0].defaultValue != null"></CURDTable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BillTable v-if="$store.state.PilotList.length > 0" :userIDExternal="parseInt($route.params.id)"></BillTable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <UserLicenses :customUserID="parseInt($route.params.id)"></UserLicenses>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <UserFlightPrivilegesIframe :customUserID="parseInt($route.params.id)"></UserFlightPrivilegesIframe>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <UserFlightStatisticCard :customUserID="parseInt($route.params.id)"></UserFlightStatisticCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <FlightList v-bind:flightsExternal="flights" :titleExternal='"Flüge die " + computedUserName + " bezahlt"' :showPrice="true"
          :denseExternal="true" :showOnlyMyPricePart="true" v-if="flights.length > 0" @toggle-data-reload="loadFlightData"></FlightList>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BillTable from '../../components/BillList.vue'
import FlightList from '../../components/FlightList.vue'
import CURDTable from '../../components/curdTable.vue'
import UserFlightStatisticCard from '../../components/UserFlightStatisticCard.vue'
import UserLicenses from '../../components/UserLicenses.vue'
import UserFlightPrivilegesIframe from '../../components/UserFlightPrivilegesIframe.vue'
export default {
  name: 'BillList',
  data: () => ({
    flights: [],
    dataSchemaUserDocs: [
      { dataId: 'userID', type: 'number', text: 'userID', defaultValue: null, showInTable: false, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'category', type: 'autocomplete', text: 'Kategorie', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'UserDocsCategoriesList', autocompleteValue: 'categoryID', autocompleteText: 'categoryName', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'title', type: 'text', text: 'Titel', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'comment', type: 'text', text: 'Kommentar', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'file', type: 'file', acceptFileType: 'image/*, .pdf', text: 'Datei', showInTable: true, defaultValue: [], sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'stringToDownloadButton', downloadId: 'documentID', downloadPath: process.env.VUE_APP_API_PATH_USER_DOCMENTS_DOWNLOAD },
      { dataId: 'access', type: 'autocomplete', text: 'Sichtbarkeit', defaultValue: 100, showInTable: false, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'AccessRoles', autocompleteValue: 'value', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' }
    ]
  }),
  components: {
    BillTable,
    FlightList,
    CURDTable,
    UserFlightStatisticCard,
    UserLicenses,
    UserFlightPrivilegesIframe
  },
  methods: {
    classColor (value) {
      if (parseFloat(value) < 0) {
        return 'red'
      }

      return 'green'
    },
    async loadFlightData () {
      this.flights = await this.$store.dispatch('loadFlightsPaidByUser', this.$route.params.id)
    }
  },
  computed: {
    computedUserName () {
      if (!this.$store.state.PilotList.length > 0) return 'Loading...'
      return this.$store.state.PilotList.filter(e => e.userID === parseInt(this.$route.params.id))[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === parseInt(this.$route.params.id))[0].lastname
    },
    computedBalance () {
      var balance = this.$store.state.UserBalance
      if (typeof (balance) === 'object' && Object.keys(balance).length > 0) {
        return balance
      }
      return []
    }
  },
  async mounted () {
    this.$store.dispatch('loadUserBalance', this.$route.params.id)
    await this.loadFlightData()
    this.dataSchemaUserDocs[0].defaultValue = this.$route.params.id
  }
}
</script>
