<template>
  <v-container fluid>
                  <CURDTable tableId="userDocsTable" title="Meine Dokumente" newItemText="Neues Dokument"
                    editItemText="Dokument bearbeiten" infoItemText="Info" :dataSchema="dataSchemaUserDocs"
                    loadDataFunction="loadUserDocuments" :loadDataFunctionPayload="$store.state.User.userID"
                    insertDataFunction="insertUserDocuments"  storeData="UserDocsList"
                    showNumberOfItems showSearch info insert dialogWidth="800px"  removeDataFunction="deleteUserDocuments" v-if="dataSchemaUserDocs[0].defaultValue != null && $store.state.User.userID != -1"></CURDTable>
                    <br>
                    <CURDTable tableId="tagsTable" title="NFC/RFID-Tags" newItemText="Hinzufügen" id="tags"
              editItemText="Bearbeiten" :dataSchema="dataSchemaTags" loadDataFunction="loadUserTags"
              insertDataFunction="insertTag" updateDataFunction="updateTag" removeDataFunction="deleteTag"
              storeData="UserTags" showNumberOfItems remove></CURDTable>
  </v-container>
</template>
<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
<script>
import CURDTable from '../components/curdTable.vue'
export default {
  name: 'UserDocuments',
  data: () => ({
    dataSchemaUserDocs: [
      { dataId: 'userID', type: 'number', text: 'userID', defaultValue: null, showInTable: false, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'category', type: 'autocomplete', text: 'Kategorie', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'UserDocsCategoriesList', autocompleteValue: 'categoryID', autocompleteText: 'categoryName', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'title', type: 'text', text: 'Titel', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'comment', type: 'text', text: 'Kommentar', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'file', type: 'file', acceptFileType: 'image/*, .pdf', text: 'Datei', showInTable: true, defaultValue: [], sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'stringToDownloadButton', downloadId: 'documentID', downloadPath: process.env.VUE_APP_API_PATH_USER_DOCMENTS_DOWNLOAD },
      { dataId: 'access', type: 'autocomplete', text: 'Sichtbarkeit', defaultValue: 100, showInTable: false, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'AccessRoles', autocompleteValue: 'value', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' }
    ],
    dataSchemaTags: [
      { dataId: 'tagID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'userID', type: 'autocomplete', text: 'Mitglied', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'uuid', type: 'text', text: 'UUID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'lastUseDate', type: 'date', text: 'zuletzt benutzt am', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDateTime' },
      { dataId: 'lastUseStation', type: 'text', text: 'letzte gescannt', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'tagType', type: 'autocomplete', text: 'Typ', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'TagTypeList', autocompleteValue: 'typeID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' }
    ]
  }),
  components: {
    CURDTable
  },
  methods: {
  },
  computed: {
  },
  mounted () {
    this.dataSchemaUserDocs[0].defaultValue = this.$store.state.User.userID
  }
}
</script>
