<template>
  <v-container fluid>
  <v-divider></v-divider>
  <h4 class="display-1">Statistik</h4>
  <v-row style="padding-bottom: 20px;">
    <v-col cols="12" md="6">
      <FlightTypeChart/>
    </v-col>
    <v-col cols="12" md="6">
      <LaunchMethodChart/>
    </v-col>
    <v-col cols="12" md="6">
      <PlaneFlightTimeChart/>
    </v-col>
    <v-col cols="12" md="6">
      <PlaneUsageChart/>
    </v-col>
  </v-row>
  <v-divider></v-divider>
  <v-row style="padding-bottom: 20px; padding-top: 20px;">
    <v-col cols="12" md="4">
      <!-- Top 5 Segelflüge-->
      <v-data-table
        :headers="headersTopFlights"
        :items="[]"
        disable-sort
        hide-default-footer
          no-data-text="Keine Flüge vorhanden..."
        class="elevation-1"
        dense>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Top 5 Segelflüge</v-toolbar-title>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" md="4">
       <!-- Top 5 Motorflüge-->
       <v-data-table
        :headers="headersTopFlights"
        :items="[]"
        disable-sort
        hide-default-footer
          no-data-text="Keine Flüge vorhanden..."
        class="elevation-1"
        dense>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Top 5 Motorflüge</v-toolbar-title>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" md="4">
       <!-- Top 5 TowPlane-->
       <v-data-table
        :headers="headersTopTowFlights"
        :items="[]"
        disable-sort
        hide-default-footer
          no-data-text="Keine Flüge vorhanden..."
        class="elevation-1"
        dense>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Top 5 F-Schlepps</v-toolbar-title>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
  <v-row style="padding-bottom: 20px;">
    <v-col cols="12" md="6">
      <LaunchPerMonthChart/>
    </v-col>
    <v-col cols="12" md="6">
      <FlightTimePerMonthChart/>
    </v-col>
  </v-row>
  <v-divider></v-divider>
    <h4 class="display-1">Flugzeug-Statistik</h4>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" v-for="plane in statisticData.PlaneFlightTimes" :key="plane.planeID">
        <PlaneCard :planeInformation="plane" :longestFlights="longestFlightsForPlane(plane.planeID)"></PlaneCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlaneCard from '../components/StatisticsPlaneCard.vue'
import LaunchMethodChart from '../components/statistics/LaunchMethodChart.vue'
import FlightTypeChart from '../components/statistics/FlightTypeChart.vue'
import PlaneUsageChart from '../components/statistics/PlaneUsageChart.vue'
import PlaneFlightTimeChart from '../components/statistics/PlaneFlightTimeChart.vue'
import LaunchPerMonthChart from '../components/statistics/LaunchPerMonthChart.vue'
import FlightTimePerMonthChart from '../components/statistics/FlightTimePerMonthChart.vue'

export default {
  name: 'Statistics',
  data: () => ({
    headersTopFlights: [
      { text: 'Pilot', value: 'pilot' },
      { text: 'Flugzeug', value: 'callsign' },
      { text: 'Start/Ziel', value: 'locations' },
      { text: 'Zeit', value: 'duration' }
    ],
    headersTopTowFlights: [
      { text: 'Pilot', value: 'pilot' },
      { text: 'Flugzeug', value: 'callsign' },
      { text: 'Schlepper', value: 'locations' },
      { text: 'Schleppflugzeug', value: 'locations' },
      { text: 'Zeit', value: 'duration' }
    ]
  }),
  components: {
    PlaneCard,
    LaunchMethodChart,
    FlightTypeChart,
    PlaneUsageChart,
    PlaneFlightTimeChart,
    LaunchPerMonthChart,
    FlightTimePerMonthChart
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    },
    statisticData () {
      return this.$store.state.Statistic
    },
    planeLongestFlightsData () {
      return this.statisticData.PlaneLongestFlights
    }
  },
  methods: {
    longestFlightsForPlane (planeID) {
      const flights = this.planeLongestFlightsData
      for (var i = 0; i < flights.length; i++) {
        if (flights[i][0].planeID === planeID) {
          return flights[i]
        }
      }
      return []
    }
  },
  async mounted () {
    await this.$store.dispatch('loadStatistics', this.currentYear)
  }
}
</script>
