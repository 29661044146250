<template>
  <div>
  <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
        @click:outside="closeCheckDialog"
        v-if="checkItem != null"
      >
          <v-card>
            <v-toolbar
              color="orange"
              dark
            >Rückzahlung Überprüfen</v-toolbar>
            <v-card-text>
              <v-row style="margin-top: 5px">
            <v-spacer></v-spacer>
            <v-btn color="primary" :href="fileDownloadLink" @click="confirmButtons.download = true"  target="_blank" download><v-icon left>fa-angle-double-down</v-icon>Download<v-icon right>fa-angle-double-down</v-icon></v-btn>
             <v-spacer></v-spacer>
            </v-row>
            <v-container>
            <v-row>
            <v-col cols="12" md="10">Ist der exakte Preis von <strong>{{checkItem.amount}}€</strong> auf der Rechnung vorhanden?</v-col>
            <v-col cols="12" md="1"><v-btn style="width: 100%" :color="confirmButtons.amount ? 'success' : 'grey'" @click="confirmButtons.amount = !confirmButtons.amount"  outlined><v-icon >fa-check</v-icon></v-btn></v-col>
            </v-row>
            <v-row>
            <v-col cols="12" md="10">Ist das Rechnungsdatum <strong>{{formatDate(checkItem.billingDate)}}</strong> korrekt?</v-col>
            <v-col cols="12" md="1"><v-btn style="width: 100%" :color="confirmButtons.billingDate ? 'success' : 'grey'" @click="confirmButtons.billingDate = !confirmButtons.billingDate" outlined><v-icon >fa-check</v-icon></v-btn></v-col>
             </v-row>
            <v-row>
            <v-col cols="12" md="10">Passt die Kategorie <strong>{{computedCategoryList.filter(e => e.typeID == checkItem.category)[0].title}}</strong> zur Rechnung?</v-col>
            <v-col cols="12" md="1"><v-btn style="width: 100%" :color="confirmButtons.category ? 'success' : 'grey'" @click="confirmButtons.category = !confirmButtons.category" outlined><v-icon >fa-check</v-icon></v-btn></v-col>
            </v-row>
            <v-row>
            <v-col cols="12">
              <v-textarea v-model="comment" rows="2" label="Kommentar (optional)" outlined></v-textarea>
            </v-col>
            </v-row>
            </v-container>
              <v-row>
              <v-btn color="red" outlined  @click="submitApproval(-1)">
              <v-icon left>fa-ban</v-icon>
              Ablehnen
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" outlined :disabled="!allButtonsConfirmed" @click="submitApproval(1)">
              Akzeptieren
              <v-icon right>fa-check</v-icon>
              </v-btn>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="closeCheckDialog"
              >Schließen</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    <v-data-table
      v-if="$store.state.PilotList.length > 0"
      :headers="headers"
      :items="requests"
      no-data-text="Keine offenen Rückzahlungen"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Rückzahlungsanfragen</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>
            <template v-slot:item.creationTime="{ item }"
        ><span>{{
         formatDateTime(item.creationTime)
        }}</span></template
      >
      <template v-slot:item.creatorID="{ item }"
        ><span>{{item.creatorID != null ? $store.state.PilotList.filter(e => e.userID == item.creatorID)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID == item.creatorID)[0].lastname : item.pilotName}}</span></template
      >
      <template v-slot:item.amount="{ item }"
        ><span>{{
          item.amount
        }}€</span></template
      >
             <template v-slot:item.category="{ item }"
        ><span>{{item.category != null ? computedCategoryList.filter(e => e.typeID == item.category)[0].title : 'ERROR' }}</span></template
      >
      <template v-slot:item.paymentMethod="{ item }">
        <span>{{ $store.state.PaymentTypeList.filter(e => e.paymenttypeID === item.paymentMethod)[0].title }}</span>
      </template>
      <template v-slot:item.paybackTo="{ item }"
        ><span>{{item.paybackTo != null ? $store.state.PilotList.filter(e => e.userID == item.paybackTo)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID == item.paybackTo)[0].lastname : item.pilotName}}</span></template
      >
      <template v-slot:item.paybackType="{ item }">
        <span>{{ $store.state.PaybackTypeList.filter(e => e.categoryID === item.paybackType)[0].title }}</span>
      </template>
            <template v-slot:item.action="{ item }"
        ><span>
        <v-btn
      color="orange"
      style="color: white;"
      rounded
      @click="openCheckDialog(item)"
    >
      <v-icon left>
        mdi-magnify
      </v-icon>
      Überprüfen
    </v-btn>
        </span></template
      >
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    headers: [
      { text: 'Datum', value: 'creationTime' },
      { text: 'Ersteller', value: 'creatorID' },
      { text: 'Betrag', value: 'amount' },
      { text: 'Kategorie', value: 'category' },
      { text: 'Kommentar', value: 'comment' },
      { text: 'Zahlung', value: 'paybackType' },
      { text: 'An', value: 'paybackTo' },
      { text: 'Aktion', value: 'action' }
    ],
    checkItem: null,
    comment: '',
    dialog: false,
    confirmButtons: {
      amount: false,
      billingDate: false,
      category: false,
      download: false
    }
  }),
  computed: {
    computedCategoryList () {
      return this.$store.state.PaybackCategoryList
    },
    requests () {
      return this.$store.state.paybackRequests
    },
    fileDownloadLink () {
      if (this.checkItem != null) {
        return 'https://' + this.$store.state.User.auth.username + ':' + this.$store.state.User.auth.password + '@' + process.env.VUE_APP_MAIN_API_DOMAIN + process.env.VUE_APP_API_PATH_DOWNLOAD_PAYBACK_PATH + '?id=' + this.checkItem.files + '&user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password
      }
      return ''
    },
    allButtonsConfirmed () {
      return Object.values(this.confirmButtons).every(v => v === true)
    }
  },
  watch: {
  },
  methods: {
    openCheckDialog: function (item) {
      this.checkItem = item
      this.dialog = true
    },
    closeCheckDialog: function () {
      this.checkItem = null
      this.dialog = false
      this.confirmButtons.amount = false
      this.confirmButtons.billingDate = false
      this.confirmButtons.category = false
      this.confirmButtons.download = false
      this.comment = ''
    },
    submitApproval: function (approved) {
      const check = { app: approved, id: this.checkItem.receiptID, checkComment: this.comment }
      this.$store.dispatch('submitPaybackCheck', check).then(response => {
        if (parseInt(response.data.noErrors) === 0) {
          this.$store.dispatch('getPaybackRequests')
          this.closeCheckDialog()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    moment: function () {
      return moment()
    },
    formatDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    formatDateTime: function (timestamp) {
      return moment(timestamp).format('DD.MM.YYYY HH:mm')
    },
    TimestampToTime: function (timestamp) {
      return moment(timestamp).format('HH:mm')
    },
    calculateTimeDifference: function (takeoff, landing) {
      var ms = moment(landing).diff(moment(takeoff))
      var d = moment.duration(ms)
      return Math.floor(d.asHours()) + moment.utc(ms).format(':mm')
    },
    isNumber (n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
    }
  },
  mounted () {
    this.$store.dispatch('getPaybackRequests')
  }
}
</script>
