<template>
  <div>
    <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                            v-model="editedItem.userID"
                            :items="$store.state.PilotList"
                            clearable
                            dense
                            outlined
                            item-value="userID"
                            item-text="fullname"
                            label="Mitglied"
                          >
                          </v-autocomplete>
                        </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-text-field v-model="editedItem.amount" type="number" step="0.01" label="Betrag"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                            v-model="editedItem.paymentMethod"
                            :items="$store.state.PaymentTypeList"
                            clearable
                            dense
                            outlined
                            item-value="paymenttypeID"
                            item-text="title"
                            label="Rückzahlungsart"
                          >
                          </v-autocomplete>
                        </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-text-field v-model="editedItem.date" type="date" label="Datum"></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-text-field v-model="editedItem.comment"  label="Kommentar"></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-text-field v-model="editedItem.withdrawInfo"  label="Anhänge"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                Abbrechen
                   </v-btn>
                                 <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="save"
              >
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-text-field v-model="search" label="SUCHEN (Datum nur YYYY-MM-DD)"></v-text-field>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="computedBillList"
      no-data-text="Keine Abrechnungen vorhanden..."
      class="elevation-1"
      id="billtable"
      @contextmenu:row="rightClickHandler"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{computedNumberOfBills}} Transaktionen</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title v-if="externalTransactions == null">Summe Transaktionen: <strong :style="('color: ' + classColor(computedTransactionPrice))">{{ computedTransactionPrice }}€ </strong></v-toolbar-title>
          <v-toolbar-title v-else><v-btn
              color="success"
              dark
              class="mb-2"
              @click="dialog = true"
            >
              Neue Transaktion
            </v-btn></v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:item.amount="{ item }"
        ><span :style="('color:' + item.color)"><strong>{{
          item.amount
        }}€</strong></span></template
      >
      <template v-slot:item.date="{ item }"
        >{{ formatDate(item.date) }}</template
      >
      <template v-slot:item.paymentMethod="{ item }">
        <span>{{ $store.state.PaymentTypeList.filter(e => e.paymenttypeID === item.paymentMethod)[0].title }}</span>
      </template>
    </v-data-table>
     <!-- RIGHT CLICK MENU -->
     <v-menu v-model="TableMenu.showMenu" :position-x="TableMenu.x" :position-y="TableMenu.y" absolute offset-y >
      <v-list dense>
        <v-list-item dense @click="editLastSelectedItem" disabled>
          <v-list-item-icon>
            <v-icon small>mdi-lead-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>
        <v-list-item dense @click="deleteLastSelectedItem" disabled>
          <v-list-item-icon>
            <v-icon small>mdi-delete-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- RIGHT CLICK MENU  END -->
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data: () => ({
    dialog: false,
    editedIndex: -1,
    search: '',
    lastSelectedTableItem: null,
    editedItem: {
      userID: null,
      amount: 0.00,
      date: null,
      comment: '',
      paymentMethod: null,
      withdrawInfo: null
    },
    defaultItem: {
      userID: null,
      amount: 0.00,
      date: null,
      comment: '',
      paymentMethod: null,
      withdrawInfo: null
    },
    TableMenu: {
      showMenu: false,
      x: null,
      y: null
    },
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'transactionID' },
      { text: 'Mitglied', value: 'pilotName' },
      { text: 'Betrag', value: 'amount' },
      { text: 'Datum', value: 'date' },
      { text: 'Zahlart', value: 'paymentMethod' },
      { text: 'Kommentar', value: 'comment' },
      { text: 'Anhänge', value: 'withdrawInfo' }
    ]
  }),
  computed: {
    computedBillList () {
      if (this.$store.state.PilotList.length > 0) {
        var transactions = []
        if (this.externalTransactions != null) {
          transactions = this.externalTransactions
        } else {
          transactions = this.$store.state.UserTransactions
        }
        transactions.forEach(transaction => {
          transaction.color = parseFloat(transaction.amount.replace(',', '.')) < 0 ? 'red' : 'green'
          try {
            transaction.pilotName = transaction.userID != null ? this.$store.state.PilotList.filter(e => e.userID === transaction.userID)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === transaction.userID)[0].lastname : transaction.pilotName
            transaction.amount = transaction.amount.replace('.', ',')
          } catch (error) {
            console.log('Cannot find UserID ' + transaction.userID + ' ! Is this Pilot maybe not "active"?')
          }
        })
        return transactions
      }
      return []
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Neue Transaktion' : 'Transaktion bearbeiten'
    },
    computedNumberOfBills () {
      return this.computedBillList.length
    },
    computedTransactionPrice () {
      if (this.externalTransactions == null) {
        var price = 0.00
        var balance = this.$store.state.UserBalance
        if (typeof (balance) === 'object' && Object.keys(balance).length > 0 && 'transactions' in balance) {
          price = parseFloat(balance.transactions)
        }
        return price.toFixed(2)
      }
      return null
    }
  },
  props: {
    externalTransactions: {
      type: Array,
      default () {
        return null
      },
      required: false
    },
    userIDExternal: {
      type: Number,
      default () {
        return undefined
      },
      required: false
    }
  },
  watch: {
  },
  methods: {
    rightClickHandler (event, item) {
      // do something with event and/or item

      //  console.log(item)
      this.TableMenu.x = event.clientX
      this.TableMenu.y = event.clientY
      this.TableMenu.showMenu = true
      this.lastSelectedTableItem = item
    },
    editLastSelectedItem () {
      this.editedIndex = this.lastSelectedTableItem.index
      this.editedItem = Object.assign({}, this.lastSelectedTableItem.item)
      this.dialog = true
    },
    deleteLastSelectedItem () {
      this.editedIndex = this.lastSelectedTableItem.index
      this.editedItem = Object.assign({}, this.lastSelectedTableItem.item)
      if (confirm('Delete ID ' + this.editedItem.transactionID + ' ?')) {
        this.$store
          .dispatch('deleteTransaction', this.lastSelectedTableItem.item)
          .then(response => {
          //  console.log(response)
            if (response.data.noErrors === 0) {
              this.$emit('reloadTransactions')
            } else {
              this.$store.commit('setApiErrorResponse', response.data)
            }
          })
      } else {
        this.lastSelectedTableItem = null
      }
    },
    classColor (value) {
      if (parseFloat(value) < 0) {
        return 'red'
      }

      return 'green'
    },
    moment: function () {
      return moment()
    },
    formatDate: function (timestamp) {
      return moment(timestamp).format('DD.MM.YYYY')
    },
    TimestampToTime: function (timestamp) {
      return moment(timestamp).format('HH:mm')
    },
    calculateTimeDifference: function (takeoff, landing) {
      var ms = moment(landing).diff(moment(takeoff))
      var d = moment.duration(ms)
      return Math.floor(d.asHours()) + moment.utc(ms).format(':mm')
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // Update Transaction
        this.$store.dispatch('updateTransaction', this.editedItem).then(response => {
          //  console.log(response)
          if (parseInt(response.data.noErrors) === 0) {
            // Update Transaction
            this.$emit('reloadTransactions')
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        // Insert Transaction
        this.$store.dispatch('insertTransaction', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update Transaction
            this.$emit('reloadTransactions')
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    },
    isNumber (n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
    }
  },
  mounted () {
    this.$store.dispatch('loadUserTransactionsList', this.userIDExternal)
    // DISABLE RIGHT CLICK
    document.getElementById('billtable').addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault()
      },
      false
    )
  }
}
</script>
