<template>
  <div>
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="800"
    >
      <v-card>
        <v-card-title class="text-h5 red white--text">
          Du hast zwei Möglichkeiten:
        </v-card-title>

        <v-card-text>
         <v-row class="text-center">
          <v-col cols="12" md="6">
            <v-container class="text-center d-flex">
              <v-row class="text-center">
                <v-col cols="12">
              <div class="text-h6">Überweise den Betrag selbstständig.</div>
              </v-col>
              <v-col cols="12">
                <qrcode-vue :value="loadQRCode" :size="200" level="H"></qrcode-vue>
              </v-col>
              <v-col cols="12">
            Scanne diesen QR-Code mit deiner Banking-APP
            <div>Dauer: 1-4 Tage</div>
            </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-divider vertical class="hidden-sm-and-down"></v-divider>
          <v-col cols="12" md="6">
            <v-container class="text-center d-flex">
              <v-row class="text-center">
                <v-col cols="12">
                    <div class="text-h6 text-center">Lass den Betrag abbuchen.</div>
                </v-col>
                <v-col cols="12" style="height: 200px;" class="d-flex align-center justify-center">
                <v-btn color="red" outlined @click="requestTransfer()" :disabled="computedBalance.total > $store.state.UserInfo.bankMinimum">
                <v-icon left>fa-file-invoice-dollar</v-icon>
                Einzug anfordern
                </v-btn>
              </v-col>
              <v-col cols="12">
                                  <br>
                Dir wird eine Rechnung per E-Mail zugesandt.
                (Verfügbar ab einem Kontostand von {{ $store.state.UserInfo.bankMinimum }}€)
                <div>Dauer: 2-3 Wochen</div></v-col>
              </v-row>
            </v-container>
          </v-col>
         </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
    <v-container fluid>
    <div class="display-1">
      Dein aktueller Kontostand beträgt: <span :class="classColor(computedBalance.total) + '--text'"> {{ computedBalance.total || '0.00' }}€ </span>
    </div>
    <v-btn color="red" outlined @click="dialog = true" :disabled="computedBalance.total >= 0">
              <v-icon left>fa-file-invoice-dollar</v-icon>
              Eigene Rechnung anfordern
            </v-btn>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <BillTable></BillTable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <FlightList v-bind:flightsExternal="flights" titleExternal='Flüge die ich bezahle' :showPrice="true" @toggle-data-reload="loadFlightData"
          :denseExternal="true" :showOnlyMyPricePart="true" v-if="flights.length > 0"></FlightList>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import BillTable from '../components/BillList.vue'
import FlightList from '../components/FlightList.vue'
import QrcodeVue from 'qrcode.vue'
import moment from 'moment'
export default {
  name: 'BillList',
  data: () => ({
    flights: [],
    dialog: false
  }),
  components: {
    BillTable,
    FlightList,
    QrcodeVue
  },
  methods: {
    classColor (value) {
      if (parseFloat(value) < 0) {
        return 'red'
      }

      return 'green'
    },
    requestTransfer () {
      if (!confirm('Möchtest du die Rechnung jetzt erstellen?')) {
        return
      }
      var invoiceData = []
      invoiceData.ids = this.$store.state.User.userID
      invoiceData.mailText = process.env.VUE_APP_INVOICE_DEFAULT_TEXT
      this.$store.dispatch('sendInvoiceToMultipleUsers', invoiceData).then(response => {
        if (parseInt(response.data.noErrors) === 0) {
          alert('Rechnung wurde verschickt!')
          this.dialog = false
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    async loadFlightData () {
      this.flights = await this.$store.dispatch('loadFlightsPaidByUser')
    }
  },
  computed: {
    computedBalance () {
      var balance = this.$store.state.UserBalance
      if (typeof (balance) === 'object' && Object.keys(balance).length > 0) {
        return balance
      }
      return []
    },
    loadQRCode () {
      if (this.computedBalance.total * -1 > 0) {
        const generateQrCode = require('sepa-payment-qr-code')
        const qr = generateQrCode({
          name: 'Fliegerclub Bad Berka – Weimar e. V.',
          iban: 'DE73820641880005321115',
          amount: parseFloat(this.computedBalance.total * -1),
          unstructuredReference: `Eigener Ausgleich Mitgliedskonto ${this.$store.state.UserInfo.mandateReference} [${this.$store.state.UserInfo.userID}], stand ${moment().format('D.M.YYYY H:m:s')}, QR-CODE`,
          information: 'QR Code zur Überweisung einer selbst erstellten Rechnung.'
        })
        return qr
      }
      return null
    }
  },
  async mounted () {
    this.$store.dispatch('loadUserTransactionsList')
    await this.loadFlightData()
    this.$store.dispatch('loadUserBalance')
  }
}
</script>
