
<template>
  <v-row justify="space-around" v-if="dialog">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
      >
          <v-card>
            <v-toolbar
              color="red"
              dark
            >Error Respose! <v-spacer></v-spacer> {{error.noErrors}} Errors</v-toolbar>
            <v-card-text style="white-space: pre;">
              <span>{{ error.errorMsg }}</span>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="$store.commit('deleteApiErrorResponse')"
              >Verstanden</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
  }),
  methods: {
    loadError: function () {
      this.errorData = this.$store.state.ApiErrorResponse
      this.dialog = true
    }
  },
  computed: {
    dialog () {
      if (this.$store.state.ApiErrorResponse.noErrors < 0 || this.$store.state.ApiErrorResponse.noErrors > 0) {
        return true
      }
      return false
    },
    error () {
      return this.$store.state.ApiErrorResponse
    }
  }
}
</script>
