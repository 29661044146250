<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
              <v-spacer></v-spacer>
              <v-btn @click="deleteLicense" text color="error" :disabled="editedIndex === -1">Löschen</v-btn>
        </v-card-title>

        <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.userID"
                      :items="$store.state.PilotList"
                      item-value="userID"
                      item-text="fullname"
                      label="Pilot"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.category"
                      :items="$store.state.LicenseCategories"
                      item-value="categoryID"
                      item-text="title"
                      label="Kategorie"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.type"
                      :items="$store.state.LicenseTypes"
                      item-value="licenseID"
                      item-text="licenseName"
                      label="Lizenz"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.validFrom"
                      type="date"
                      label="Ausgestellt am (Optional!)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.validUntil"
                      type="date"
                      label="Gültig bis"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                Abbrechen
                   </v-btn>
                                 <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="save"
              >
                Speichern
              </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
    :headers="computedHeaders"
    :items="licenses"
    single-expand
    fixed-header
    height="1000"
    :items-per-page="-1"
    :expanded.sync="expanded"
    item-key="userID"
    show-expand
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Freigabeübersicht</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large color="green">
        mdi-check-all
        </v-icon>
        Freigabe aktiv
        <v-spacer></v-spacer>
        <v-icon large color="red">
        mdi-close
       </v-icon>
       Freigabe abgelaufen
       <v-spacer></v-spacer>
        <v-icon large color="grey">
          mdi-minus
        </v-icon>
        Keine Freigabe angelegt
        <v-spacer></v-spacer>
        <v-switch v-model="hideInstructors" label="Lehrer ausblenden" style="padding-top: 20px" color="red"></v-switch>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = true" color="success">Freigabe hinzufügen</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.SplWID="{ item }">
      <licenseTemplateIcon :licenseID="item.SplWID" :licenseValidUntil="item.SplWvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.SplFID="{ item }">
      <licenseTemplateIcon :licenseID="item.SplFID" :licenseValidUntil="item.SplFvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.SplEID="{ item }">
      <licenseTemplateIcon :licenseID="item.SplEID" :licenseValidUntil="item.SplEvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.SplTmgEID="{ item }">
      <licenseTemplateIcon :licenseID="item.SplTmgEID" :licenseValidUntil="item.SplTmgEvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.SplTmgFID="{ item }">
      <licenseTemplateIcon :licenseID="item.SplTmgFID" :licenseValidUntil="item.SplTmgFvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplAtmgEID="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplAtmgEID" :licenseValidUntil="item.LaplAtmgEvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplAtmgFID="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplAtmgFID" :licenseValidUntil="item.LaplAtmgFvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplSEID="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplSEID" :licenseValidUntil="item.LaplSEvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplSFID="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplSFID" :licenseValidUntil="item.LaplSFvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplSWID="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplSWID" :licenseValidUntil="item.LaplSWvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplStmgEID="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplStmgEID" :licenseValidUntil="item.LaplStmgEvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplStmgFID="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplStmgFID" :licenseValidUntil="item.LaplStmgFvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.PplAtmgEID="{ item }">
      <licenseTemplateIcon :licenseID="item.PplAtmgEID" :licenseValidUntil="item.PplAtmgEvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.PplATmgFID="{ item }">
      <licenseTemplateIcon :licenseID="item.PplaTmgFID" :licenseValidUntil="item.PplaTmgFvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.ULEID="{ item }">
      <licenseTemplateIcon :licenseID="item.ULEID" :licenseValidUntil="item.ULEvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.ULFID="{ item }">
      <licenseTemplateIcon :licenseID="item.ULFID" :licenseValidUntil="item.ULFvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>

    <template v-slot:item.SplWID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.SplWID_instructor" :licenseValidUntil="item.SplWvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.SplFID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.SplFID_instructor" :licenseValidUntil="item.SplFvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.SplEID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.SplEID_instructor" :licenseValidUntil="item.SplEvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.SplTmgEID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.SplTmgEID_instructor" :licenseValidUntil="item.SplTmgEvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.SplTmgFID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.SplTmgFID_instructor" :licenseValidUntil="item.SplTmgFvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplAtmgEID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplAtmgEID_instructor" :licenseValidUntil="item.LaplAtmgEvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplAtmgFID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplAtmgFID_instructor" :licenseValidUntil="item.LaplAtmgFvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplSEID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplSEID_instructor" :licenseValidUntil="item.LaplSEvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplSFID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplSFID_instructor" :licenseValidUntil="item.LaplSFvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplSWID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplSWID_instructor" :licenseValidUntil="item.LaplSWvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplStmgEID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplStmgEID_instructor" :licenseValidUntil="item.LaplStmgEvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.LaplStmgFID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.LaplStmgFID_instructor" :licenseValidUntil="item.LaplStmgFvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.PplAtmgEID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.PplAtmgEID_instructor" :licenseValidUntil="item.PplAtmgEvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.PplaTmgFID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.PplaTmgFID_instructor" :licenseValidUntil="item.PplaTmgFvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.ULEID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.ULEID_instructor" :licenseValidUntil="item.ULEvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.ULFID_instructor="{ item }">
      <licenseTemplateIcon :licenseID="item.ULFID_instructor" :licenseValidUntil="item.ULFvalidUntil_instructor" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>

    <template v-slot:item.ulMedLAPLID="{ item }">
      <licenseTemplateIcon :licenseID="item.ulMedLAPLID" :licenseValidUntil="item.ulMedLAPLvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.ulMed1ID="{ item }">
      <licenseTemplateIcon :licenseID="item.ulMed1ID" :licenseValidUntil="item.ulMed1validUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.ulMed2ID="{ item }">
      <licenseTemplateIcon :licenseID="item.ulMed2ID" :licenseValidUntil="item.ulMed2validUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:item.ulZUPID="{ item }">
      <licenseTemplateIcon :licenseID="item.ulZUPID" :licenseValidUntil="item.ulZUPvalidUntil" :item="item" v-on:openlicense="openlicenseDialog"></licenseTemplateIcon>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <UserFlightPrivilegesIframe :customUserID="item.userID"></UserFlightPrivilegesIframe>
      </td>
    </template>
  </v-data-table>
  </div>
</template>
<script>
import licenseTemplateIcon from '../../components/admin/licenseTemplateIcon.vue'
import UserFlightPrivilegesIframe from '../../components/UserFlightPrivilegesIframe.vue'
export default {
  data () {
    return {
      expanded: [],
      hideInstructors: true,
      preHeaders: [
        { text: 'Name', value: 'fullName' }
      ],
      licenseHeaders: [
        { text: 'SPL W', value: 'SplWID' },
        { text: 'SPL F', value: 'SplFID' },
        { text: 'SPL E', value: 'SplEID' },
        { text: 'SPL TMG E', value: 'SplTmgEID' },
        { text: 'SPL TMG F', value: 'SplTmgFID' },
        { text: 'LAPL(A) TMG E', value: 'LaplAtmgEID' },
        { text: 'LAPL(A) TMG F', value: 'LaplAtmgFID' },
        { text: 'LAPL(S) E', value: 'LaplSEID' },
        { text: 'LAPL(S) F', value: 'LaplSFID' },
        { text: 'LAPL(S) W', value: 'LaplSWID' },
        { text: 'LAPL(S) TMG E', value: 'LaplStmgEID' },
        { text: 'LAPL(S) TMG F', value: 'LaplStmgFID' },
        { text: 'PPL(A) TMG E', value: 'PplAtmgEID' },
        { text: 'PPL(A) TMG F', value: 'PplATmgFID' },
        { text: 'UL E', value: 'ULEID' },
        { text: 'UL F', value: 'ULFID' }
      ],
      instructorHeaders: [
        { text: 'SPL W FI', value: 'SplWID_instructor' },
        { text: 'SPL F FI', value: 'SplFID_instructor' },
        { text: 'SPL E FI', value: 'SplEID_instructor' },
        { text: 'SPL TMG E FI', value: 'SplTmgEID_instructor' },
        { text: 'SPL TMG F FI', value: 'SplTmgFID_instructor' },
        { text: 'LAPL(A) TMG E FI', value: 'LaplAtmgEID_instructor' },
        { text: 'LAPL(A) TMG F FI', value: 'LaplAtmgFID_instructor' },
        { text: 'LAPL(S) E FI', value: 'LaplSEID_instructor' },
        { text: 'LAPL(S) F FI', value: 'LaplSFID_instructor' },
        { text: 'LAPL(S) W FI', value: 'LaplSWID_instructor' },
        { text: 'LAPL(S) TMG E FI', value: 'LaplStmgEID_instructor' },
        { text: 'LAPL(S) TMG F FI', value: 'LaplStmgFID_instructor' },
        { text: 'PPL(A) TMG E FI', value: 'PplAtmgEID_instructor' },
        { text: 'PPL(A) TMG F FI', value: 'PplaTmgFID_instructor' },
        { text: 'UL E FI', value: 'ULEID_instructor' },
        { text: 'UL F FI', value: 'ULFID_instructor' }
      ],
      postHeaders: [
        { text: 'Medical LAPL', value: 'ulMedLAPLID' },
        { text: 'Medical C 1', value: 'ulMed1ID' },
        { text: 'Medical C 2', value: 'ulMed2ID' },
        { text: 'ZÜP', value: 'ulZUPID' },
        { text: '', value: 'data-table-expand' }
      ],
      licenses: [],
      dialog: false,
      editedItem: {
        userID: null,
        validFrom: '',
        validUntil: '',
        category: '',
        type: ''
      },
      editedIndex: -1,
      defaultItem: {
        userID: null,
        validFrom: '',
        validUntil: '',
        category: '',
        type: ''
      }
    }
  },
  methods: {
    loadUserLicenses () {
      this.$store.dispatch('loadAllUserLicense').then(response => {
        this.licenses = response.data.result
      })
    },
    deleteLicense () {
      if (confirm('Lizenz ' + this.editedItem.licenseName + ' löschen?') === true) {
        this.$store.dispatch('deleteUserLicense', this.editedItem).then(response => {
          if (parseInt(response.data.noErrors) === 0) {
            this.loadUserLicenses()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    },
    openlicenseDialog (id, userID) {
      if (id === null) {
        this.dialog = true
        this.editedItem.userID = userID
      } else {
        this.$store.dispatch('getLicenseInfo', id).then(response => {
          console.log(response)
          this.editedIndex = this.licenses.indexOf(this.licenses.filter(e => e.userID === userID)[0])
          this.editedItem = response.data.result
          this.dialog = true
        })
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem (item) {
      this.$store.dispatch('deleteUserLicense', item).then(response => {
        if (parseInt(response.data.noErrors) === 0) {
          this.loadUserLicenses()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    save () {
      if (this.editedIndex > -1) {
        this.$store.dispatch('updateUserLicense', this.editedItem).then(response => {
          //  console.log(response)
          if (parseInt(response.data.noErrors) === 0) {
            // Update License
            this.loadUserLicenses()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        this.$store.dispatch('insertUserLicense', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update License
            this.loadUserLicenses()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Neue Berechtigung' : 'Bearbeiten'
    },
    computedHeaders () {
      var headers = []
      headers = headers.concat(this.preHeaders)
      headers = headers.concat(this.licenseHeaders)
      if (!this.hideInstructors) {
        headers = headers.concat(this.instructorHeaders)
      }
      headers = headers.concat(this.postHeaders)
      return headers
    }
  },
  mounted () {
    this.loadUserLicenses()
  },
  components: { licenseTemplateIcon, UserFlightPrivilegesIframe }
}
</script>
