<template>
    <div>
    <v-icon large color="grey" v-if="licenseID === null" @click="sendOpen()">
      mdi-minus
      </v-icon>
      <v-icon large color="green" v-else-if="licenseID != null && !licenseExpired(licenseValidUntil)" @click="sendOpen()">
      mdi-check-all
      </v-icon>
      <v-icon large color="red" v-else @click="sendOpen()">
      mdi-close
      </v-icon>
    </div>
</template>
<script>
export default {
  props: {
    licenseID: {
      required: true,
      default: null
    },
    licenseValidUntil: {
      required: true,
      default: null
    },
    item: {
      required: true,
      default: null
    }
  },
  methods: {
    licenseExpired (date) {
      // console.log(date)
      if (new Date(date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
        return true
      }
      return false
    },
    sendOpen () {
      this.$emit('openlicense', this.licenseID, this.item.userID)
    }
  }
}
</script>
