<template>
  <v-card
    class="mx-auto my-12"
    max-width="374"
    style="margin-bottom: 0px !important"
  >

    <v-card-title>{{ planeInformation.callsign }}<div class=" text-subtitle-1">&nbsp;({{planeInformation.planeName}})</div> <v-spacer></v-spacer>
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        ><v-rating
          :value="computedRating"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating></span>
      </template>
      <span>
      Beliebtheit (Startzahl x Flugzeit in Stunden / 100)
      </span>
    </v-tooltip>
      </v-card-title>

    <v-card-text>
      <v-chip-group
        column
      >

        <v-chip color="primary white--text">{{planeInformation.landingsPeriod || 0}} Landungen</v-chip>

        <v-chip color="deep-purple accent-4  white--text">{{ timeConvert(planeInformation.totalFlightTime)}} Flugzeit</v-chip>
      </v-chip-group>
      <div>
      </div>
    </v-card-text>
    <v-card-title>Längste Flüge</v-card-title>
    <v-data-table
    :headers="headers"
    :items="computedLongestFlights"
    disable-sort
     hide-default-footer
      no-data-text="Keine Flüge vorhanden..."
    class="elevation-1"
    dense
  ></v-data-table>
  </v-card>
</template>
<style>
.custom {
    transform: translateY(35px) !important;
}
</style>
<script>
export default {
  name: 'PlaneCard',
  data: () => ({
    headers: [
      { text: 'Pilot', value: 'pilot' },
      { text: 'Flug', value: 'locations' },
      { text: 'Zeit', value: 'duration' }
    ]
  }),
  props: {
    planeInformation: {
      type: Object,
      default () {
        return []
      },
      required: true
    },
    longestFlights: {
      type: Array,
      default () {
        return []
      },
      required: false
    }
  },
  methods: {
    timeConvert (n) {
      var num = parseInt(n)
      if (Number.isNaN(num)) {
        num = 0
      }
      var hours = (num / 60)
      var rhours = Math.floor(hours)
      var minutes = (hours - rhours) * 60
      var rminutes = Math.round(minutes)
      if (rhours < 10) {
        rhours = '0' + rhours
      }
      if (rminutes < 10) {
        rminutes = '0' + rminutes
      }
      return rhours + ':' + rminutes
    }
  },
  computed: {
    computedRating () {
      const num = parseInt(this.planeInformation.totalLandings)
      const hours = parseInt(parseInt(this.planeInformation.totalFlightTime) / 60)
      var index = parseInt(num * hours)
      //  console.log(this.planeInformation.callsign, num, hours, index)
      if (isNaN(num) || isNaN(hours) || isNaN(index)) return 0.0
      if (index < 500) { return 0.0 } else
      if (index < 1000) { return 0.5 } else
      if (index < 2000) { return 1.0 } else
      if (index < 3000) { return 1.5 } else
      if (index < 4000) { return 2.0 } else
      if (index < 5000) { return 2.5 } else
      if (index < 6000) { return 3.0 } else
      if (index < 7000) { return 3.5 } else
      if (index < 8000) { return 4.0 } else
      if (index < 9000) { return 4.5 } else { return 5.0 }
    },
    computedLongestFlights () {
      var flights = this.longestFlights
      for (var i = 0; i < flights.length; i++) {
        flights[i].pilot = flights[i].pilotID != null ? this.$store.state.PilotList.filter(e => e.userID === parseInt(flights[i].pilotID))[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === parseInt(flights[i].pilotID))[0].lastname : flights[i].pilotName
        flights[i].locations = flights[i].takeoffLocation + ' / ' + flights[i].landingLocation
        flights[i].duration = this.timeConvert(flights[i].flightDuration)
      }
      return flights
    }
  },
  mounted () {
  }
}
</script>
