<template>
<v-container fluid>
 <v-row>
    <!-- SHOULD BE REMOVED-->
    <!-- <v-overlay absolute> Bald verfügbar...</v-overlay> -->
  <v-col cols="12" sm="12" md="8" lg="10">Left r
    <v-container>
      <v-row>Umfrage</v-row>
      <v-row>Ergebnisse</v-row>
    </v-container>
  </v-col>
<v-divider vertical inset></v-divider>
  <v-col cols="12" sm="12" md="4" lg="2">Anstehende Umfragen</v-col>
 </v-row>
</v-container>
</template>

<script>

export default {
  name: 'Surveys'
}
</script>
