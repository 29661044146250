<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.taskTitle"
                      type="text"
                      label="Titel"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="editedItem.taskDescription"
                      type="text"
                      rows="3"
                      label="Beschreibung"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.taskDuration"
                      type="text"
                      label="geschätzte Dauer"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.taskStart"
                      type="date"
                      label="frühster Beginn (OPTIONAL: STANDARD HEUTE)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.taskDeadline"
                      type="date"
                      label="Deadline (Endzeitpunkt)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                            v-model="editedItem.taskAssignedTo"
                            :items="$store.state.PilotList"
                            clearable
                            dense
                            outlined
                            item-value="userID"
                            item-text="fullname"
                            label="Zugewiesen an"
                          >
                          </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                Abbrechen
                   </v-btn>
                                 <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="save"
              >
                Speichern
              </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEvent"
      width="500"
    >
      <v-card>
        <v-card-title>
              <span class="text-h5">{{ formTitleEvent }}</span>
        </v-card-title>

        <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItemEvent.name"
                      type="text"
                      label="Titel"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItemEvent.category"
                      :items="$store.state.EventCategories"
                      item-text="title"
                      item-value="categoryID"
                      label="Kategorie"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="editedItemEvent.comment"
                      type="text"
                      rows="3"
                      label="Beschreibung"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItemEvent.start"
                      type="date"
                      label="Beginn"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItemEvent.end"
                      type="date"
                      label="Ende"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItemEvent.access"
                      :items="$store.state.AccessRoles"
                      item-text="title"
                      item-value="value"
                      label="Sichbarkeit"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="closeEvent"
              >
                Abbrechen
                   </v-btn>
                                 <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="saveEvent"
              >
                Speichern
              </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar
      color="indigo darken-4"
      style="background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);"
      dark
      flat
    >

      <v-toolbar-title>Organisations - Dashboard</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs
          v-model="tab"
        >
          <v-tab>
            <v-icon>mdi-list-box-outline</v-icon>
            &nbsp;Listenansicht
          </v-tab>
          <v-tab>
            <v-icon>mdi-calendar-clock</v-icon>
            &nbsp;Kalenderansicht
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item>
      <v-container fluid>
      <v-btn
      tile
      color="warning"
      style="margin-bottom: 10px;"
      @click="dialog = true"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      Aufgabe erstellen
    </v-btn>
    <v-btn
      tile
      color="success"
      style="margin-bottom: 10px;"
      @click="dialogEvent = true"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      Termin/Event erstellen
    </v-btn>
        <v-row>
          <v-col>
            <v-text-field v-model="search" label="Suche nach Aufgabentitel"></v-text-field>
            <v-data-table
            v-if="$store.state.PilotList.length > 0"
      :headers="tasksHeader"
      :items="computedTasks"
      :search="search"
      single-expand
      :expanded.sync="expanded"
      item-key="taskID"
      show-expand
      :items-per-page="-1"
      hide-default-footer
      class="elevation-1"
      :item-class="itemRowBackground"
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row style="transform: translateY(8px);">
          <v-col cols="2">
            <v-toolbar-title>Aufgaben - Übersicht</v-toolbar-title>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.taskAssignedTo="{ item }">
      {{ item.taskAssignedTo != null ? getPilotName(item.taskAssignedTo) : 'OFFEN' }}
    </template>
    <template v-slot:item.taskDeadline="{ item }">
      {{ formatDate(item.taskDeadline) }}
    </template>
    <template v-slot:item.status="{ item }">
      {{ getTaskStatus(item) }}
    </template>
    <template v-slot:item.taskCreator="{ item }">
      {{ item.taskCreator != null ? getPilotName(item.taskCreator) : '---' }}
    </template>
    <template v-slot:item.taskApproved="{ item }">
      {{ item.taskApproved != null ? getPilotName(item.taskApproved) : '---' }}
    </template>
    <template v-slot:item.takeTask="{ item }">
      <v-btn :disabled="!(item.taskAssignedTo != $store.state.User.userID && item.taskDoneBy === null)" color="orange" @click="takeTask(item)">Übernehmen</v-btn>
    </template>
    <template v-slot:item.confirmTask="{ item }">
      <v-btn  v-if="item.taskAssignedTo === $store.state.User.userID" :disabled="item.taskApproved === null && item.taskDoneBy > 0" color="success" @click="doneTask(item)">Erledigt</v-btn>
      <v-btn  v-else :disabled="!(item.taskAssignedTo != $store.state.User.userID && item.taskDoneBy != null && item.taskApproved == null)" color="primary" @click="approveTask(item)">Bestätigen</v-btn>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn :disabled="item.taskDoneBy !== null" icon @click="editItem(item)"><v-icon color="success">fa-pen</v-icon></v-btn>
      <v-btn icon @click="deleteTask(item)" color="red"><v-icon>fa-trash</v-icon></v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
      {{ item.taskDescription }}
      </td>
    </template>
  </v-data-table>
          </v-col>
            <!-- <v-col cols="12" sm="12" md="12" lg="12"  v-for="task in tasks" :key="task.taskID">
            <taskCard :task="task" v-if="!$store.state.PilotList.length <= 0" @reloadTasks="loadTasks" v-on:open-edit="editItem"></taskCard>
            </v-col>  THIS PART CAN BE REMOVED LATER-->
        </v-row>
    </v-container>
      </v-tab-item>
      <v-tab-item>
      <v-container fluid>
        <v-btn
      tile
      color="warning"
      style="margin-bottom: 10px;"
      @click="dialog = true"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      Aufgabe erstellen
    </v-btn>
    <v-btn
      tile
      color="success"
      style="margin-bottom: 10px;"
      @click="dialogEvent = true"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      Termin/Event erstellen
    </v-btn>
        <v-sheet
      tile
      height="54"
      class="d-flex"
    >
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="Ansicht"
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="800px">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :type="type"
        :events="computedCalenderEvents"
        event-overlap-mode="column"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="loadTasks"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        show-week
      ></v-calendar>
    </v-sheet>
    </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
  </template>
<style>
.taskNotAssignedBg {
  background-color: rgb(243, 225, 89);
}
.taskAssignedToSomebodyElseBg {
  background-color: rgb(240, 240, 240);
}
.taskAssignedToUserBg {
  background-color: rgb(103, 236, 114);
}
.taskDeadlineReachedBg {
  background-color: rgb(255, 137, 137);
}
.taskDeadlineWithinOneWeekBg {
  background-color: rgb(255, 201, 85);
}
</style>
<script>
import moment from 'moment'
export default {
  name: 'Tasks',
  data: () => ({
    tab: null,
    search: '',
    expanded: [],
    tasksHeader: [
      { text: 'Zugewiesen', value: 'taskAssignedTo' },
      { text: 'Status', value: 'status' },
      { text: 'Aufgabe', value: 'taskTitle' },
      { text: 'Deadline', value: 'taskDeadline' },
      { text: 'Geplante Dauer', value: 'taskDuration' },
      { text: 'Ersteller', value: 'taskCreator' },
      { text: 'Prüfer', value: 'taskApproved' },
      { text: 'Übernehmen', value: 'takeTask' },
      { text: 'Erledigen/Bestätigen', value: 'confirmTask' },
      { text: 'Bearbeiten/Löschen', value: 'actions' }
    ],
    items: [
      'Listenansicht', 'Kalenderansicht'
    ],
    type: 'month',
    types: [
      { value: 'month', text: 'Monat' },
      { value: 'week', text: 'Woche' },
      { value: '4day', text: '4-Tage' },
      { value: 'day', text: 'Tag' }
    ],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: '',
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    tasks: [],
    events: [],
    dialog: false,
    dialogEvent: false,
    editedIndex: -1,
    editedIndexEvent: -1,
    editedItem: {
      taskID: '',
      taskTitle: '',
      taskDescription: '',
      taskCreator: '',
      taskCreationDate: '',
      taskDeadline: null,
      taskStart: null,
      taskAssignedTo: '',
      taskDoneBy: '',
      taskDoneDate: '',
      taskApproved: '',
      taskDuration: ''
    },
    defaultItem: {
      taskID: '',
      taskTitle: '',
      taskDescription: '',
      taskCreator: '',
      taskCreationDate: '',
      taskDeadline: null,
      taskStart: null,
      taskAssignedTo: '',
      taskDoneBy: '',
      taskDoneDate: '',
      taskApproved: '',
      taskDuration: ''
    },
    editedItemEvent: {
      eventID: '',
      name: '',
      comment: '',
      category: 1,
      access: 0,
      start: null,
      end: null
    },
    defaultItemEvent: {
      eventID: '',
      name: '',
      comment: '',
      category: 1,
      access: 0,
      start: null,
      end: null
    }
  }),
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Neue Aufgabe' : 'Bearbeiten'
    },
    formTitleEvent () {
      return this.editedIndexEvent === -1 ? 'Neues Event' : 'Bearbeiten'
    },
    computedCalenderEvents () {
      var events = []

      this.tasks.forEach(task => {
        events.push({
          name: task.taskTitle,
          start: task.taskStart ?? task.taskCreationDate,
          end: task.taskDeadline,
          color: 'orange',
          type: 'task',
          rawItem: task
        })
      })
      this.events.forEach(event => {
        events.push({
          name: event.name,
          start: event.start ?? event.creationTime,
          end: event.end,
          color: 'deep-purple',
          type: 'event',
          rawItem: event
        })
      })

      return events
    },
    computedTasks () {
      // Add Static Filter => First open Tasks, Than assigned ordered by Deadline
      var userAssignedTasks = this.tasks.filter(e => e.taskAssignedTo === this.$store.state.User.userID)
      var unassignedTasks = this.tasks.filter(e => e.taskAssignedTo === null)
      var assignedTasksOrderedByDeadline = this.tasks.filter(e => e.taskAssignedTo !== null && e.taskAssignedTo !== this.$store.state.User.userID && e.taskDeadline !== null).sort((a, b) => new Date(a.taskDeadline) - new Date(b.taskDeadline))
      var assignedTasksWithoutDeadline = this.tasks.filter(e => e.taskAssignedTo !== null && e.taskAssignedTo !== this.$store.state.User.userID && e.taskDeadline === null)

      return userAssignedTasks.concat(unassignedTasks.concat(assignedTasksOrderedByDeadline.concat(assignedTasksWithoutDeadline))).filter(e => e.taskApproved <= 0) // Remove done Tasks
    }
  },
  methods: {
    itemRowBackground (item) {
      if (item.taskAssignedTo === null) { // All unassigned Tasks
        return 'taskNotAssignedBg'
      }
      if (moment().isAfter(item.taskDeadline)) { // IF DEADLINE REACHED
        return 'taskDeadlineReachedBg'
      } else if (moment(item.taskDeadline).diff(moment(), 'days') <= 7) { // 7 DAYS BEFORE DEADLINE
        return 'taskDeadlineWithinOneWeekBg'
      }
      if (item.taskAssignedTo === this.$store.state.User.userID) { // All Tasks assigned to user
        return 'taskAssignedToUserBg'
      }
      return 'taskAssignedToSomebodyElseBg'
    },
    formatDate: function (timestamp) {
      if (timestamp === null) return 'keine'
      return moment(timestamp).format('DD.MM.YYYY')
    },
    getTaskStatus (task) {
      if (task.taskAssignedTo === null && task.taskDoneBy === null) {
        return 'offen'
      }
      if (task.taskAssignedTo > 0 && task.taskApproved === null && task.taskDoneBy === null) {
        return 'in Arbeit'
      }
      if (task.taskApproved === null && task.taskDoneBy > 0) {
        return 'Erledigt, wartet auf Prüfung'
      }
      if (task.taskApproved > 0) {
        return 'Erledigt und abgenommen'
      }
      return 'Fehler im Status'
    },
    getPilotName (id) {
      return this.$store.state.PilotList.filter(e => e.userID === id)[0].firstname + ' ' + this.$store.state.PilotList.filter(e => e.userID === id)[0].lastname
    },
    takeTask  (task) {
      this.$store.dispatch('assignTask', task).then(response => {
        if (response.data.noErrors === 0) {
          this.loadTasks()
        }
      })
    },
    doneTask  (task) {
      this.$store.dispatch('doneTask', task).then(response => {
        if (response.data.noErrors === 0) {
          this.loadTasks()
        }
      })
    },
    approveTask  (task) {
      this.$store.dispatch('approveTask', task).then(response => {
        if (response.data.noErrors === 0) {
          this.loadTasks()
        }
      })
    },
    deleteTask (task) {
      if (confirm('Möchtest du die Aufgabe "' + task.taskTitle + '" wirklich löschen?')) {
        this.$store.dispatch('deleteTask', task).then(response => {
          if (response.data.noErrors === 0) {
            this.loadTasks()
          }
        })
      }
    },
    showEvent (event) {
      console.log(event)
      if (event.event.type === 'task') {
        this.editItem(event.event.rawItem)
      } else if (event.event.type === 'event') {
        this.editItemEvent(event.event.rawItem)
      } else {
        alert('FEHLER')
      }
    },
    loadTasks () {
      var that = this
      this.$store.dispatch('loadAllTasks').then(response => {
        that.tasks = response.data.result
      })
    },
    loadEvents () {
      var that = this
      this.$store.dispatch('loadAllEvents').then(response => {
        that.events = response.data.result
      })
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeEvent () {
      this.dialogEvent = false
      this.$nextTick(() => {
        this.editedItemEvent = Object.assign({}, this.defaultItemEvent)
        this.editedIndexEvent = -1
      })
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    editItem (item) {
      this.editedIndex = this.tasks.indexOf(item)
      this.editedItem = Object.assign({}, this.tasks[this.editedIndex])
      this.dialog = true
    },
    editItemEvent (item) {
      this.editedIndexEvent = this.events.indexOf(item)
      this.editedItemEvent = Object.assign({}, this.events[this.editedIndexEvent])
      this.editedItemEvent.start = moment(this.editedItemEvent.start).format('YYYY-MM-DD')
      this.editedItemEvent.end = moment(this.editedItemEvent.end).format('YYYY-MM-DD')
      this.dialogEvent = true
    },
    save () {
      // this.editedItem.taskStart = `${this.editedItem.taskStartDate} ${this.editedItem.taskStartTime ?? ''}` // Add Time additionally if provided
      // this.editedItem.taskDeadline = `${this.editedItem.taskDeadlineDate} ${this.editedItem.taskDeadlineTime ?? ''}` // Add Time additionally if provided

      if (this.editedIndex > -1) {
        this.$store.dispatch('updateTask', this.editedItem).then(response => {
          //  console.log(response)
          if (parseInt(response.data.noErrors) === 0) {
            // Update Plane
            this.loadTasks()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        this.editedItem.userID = this.$store.state.User.userID
        this.$store.dispatch('insertTask', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update Plane
            this.loadTasks()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    },
    saveEvent () {
      // this.editedItem.taskStart = `${this.editedItem.taskStartDate} ${this.editedItem.taskStartTime ?? ''}` // Add Time additionally if provided
      // this.editedItem.taskDeadline = `${this.editedItem.taskDeadlineDate} ${this.editedItem.taskDeadlineTime ?? ''}` // Add Time additionally if provided
      var tmpEvent = Object.assign({}, this.editedItemEvent) // Copy Object to avoid error on Date input after following lines
      tmpEvent.start = `${tmpEvent.start} 00:00:00`
      tmpEvent.end = `${tmpEvent.end} 23:59:59`
      if (this.editedIndexEvent > -1) {
        this.$store.dispatch('updateEvent', tmpEvent).then(response => {
          //  console.log(response)
          if (parseInt(response.data.noErrors) === 0) {
            // Update Plane
            this.loadEvents()
            this.closeEvent()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        this.$store.dispatch('insertEvent', tmpEvent).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update Plane
            this.loadEvents()
            this.closeEvent()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  },
  mounted () {
    this.loadTasks()
    this.loadEvents()
    // this.tasks = [{ taskID: 55, title: 'Test Aufgabe', description: 'Lorem impsum', taskCreator: 11, creationDate: '2023-05-05', taskDeadline: '2023-05-08', taskAssignedTo: null, taskDoneBy: null, taskDoneDate: null, taskApproved: 0, taskDuration: '4h' },
    //   { taskID: 55, title: 'Test Aufgabe', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sit amet congue massa. Sed lacinia leo sed molestie posuere. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut pellentesque ex sed erat venenatis ultricies. In in sapien nibh. Phasellus sagittis eleifend tellus, at pharetra nisl pharetra at. Nullam elit libero, vehicula nec tincidunt in, pellentesque ac erat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent consequat dapibus risus, in consequat erat. Curabitur et est venenatis dui varius maximus. Proin elementum ante odio, in luctus leo tempus vel. Nunc fringilla velit a leo ultricies varius. Nunc vestibulum accumsan ex. Vivamus ultricies imperdiet ipsum. Quisque posuere euismod turpis, eu vehicula massa malesuada et. Nullam felis tellus, consectetur sed blandit vitae, efficitur sit amet augue. ', taskCreator: 11, creationDate: '2023-05-05', taskDeadline: '2023-05-08', taskAssignedTo: 11, taskDoneBy: null, taskDoneDate: null, taskApproved: 0, taskDuration: '4h' }]
  }
}
</script>
