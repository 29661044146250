<template>
<v-container fluid>
   <CURDTable tableId="invoiceTable" title="offene Einzüge" newItemText="Rechnung anlegen"
              editItemText="Rechnung bearbeiten" :dataSchema="dataSchemaInvoice" :customActionButtons="customActionButtons" :customTableButtonsForSelectedItems="customTableButtonsForSelectedItems"
              loadDataFunction="loadAdminInvoice" removeDataFunction="deleteAdminInvoice" itemSelectKey="transferID"
              storeData="AdminInvoice" showNumberOfItems showSearch remove info itemsSelectableMultiple itemsSelectable v-on:generateTransactionFromInvoice="generateTransaction"></CURDTable>
 </v-container>
</template>

<script>
import CURDTable from '../../components/curdTable.vue'
export default {
  name: 'AdminInvoice',
  data: () => ({
    dataSchemaInvoice: [
      { dataId: 'transferID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'pending', type: 'number', text: 'pending', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'amount', type: 'float', steps: 0.01, text: 'Wert', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'userID', type: 'autocomplete', text: 'User', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'userName', type: 'text', text: 'UName', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'accountOwner', type: 'text', text: 'Account Owner', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'mandatRef', type: 'text', text: 'mandatRef', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'verwendungszweck', type: 'text', text: 'Zweck', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'pmtID', type: 'text', text: 'PMTID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'clubIBAN', type: 'text', text: 'clubIBAN', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'clubBIC', type: 'text', text: 'clubBIC', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'endRef', type: 'text', text: 'endRef', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'userIBAN', type: 'text', text: 'userIBAN', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false }
    ],
    customActionButtons: [
      { type: 'icon', function: 'emit', functionName: 'generateTransactionFromInvoice', color: 'success', icon: 'fa-sign-out-alt' }
    ],
    customTableButtonsForSelectedItems: [
      { function: 'emit', functionName: 'generateTransactionFromInvoice', color: 'success', text: 'Transaktionen generieren' }
    ]
  }),
  components: {
    CURDTable
  },
  methods: {
    generateTransaction (data) {
      this.$store.dispatch('generateTransactionFromInvoice', data).then(response => {
        if (response.data.noErrors === 0) {
          this.$store.dispatch('loadAdminInvoice')
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    }
  }
}
</script>
