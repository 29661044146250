<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.category"
                      :items="$store.state.LicenseCategories"
                      item-value="categoryID"
                      item-text="title"
                      label="Kategorie"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="editedItem.type"
                      :items="$store.state.LicenseTypes"
                      item-value="licenseID"
                      item-text="licenseName"
                      label="Lizenz"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.validFrom"
                      type="date"
                      label="Ausgestellt am (Optional!)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.validUntil"
                      type="date"
                      label="Gültig bis"
                      :disabled="(editedItem.category === 1 || editedItem.category === 2) && $store.state.UserInfo.adm !== 1"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                Abbrechen
                   </v-btn>
                                 <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="save"
              >
                Speichern
              </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-btn
      tile
      color="success"
      style="margin-bottom: 10px;"
      @click="dialog = true"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      Lizenz / Freigabe hinzufügen
    </v-btn>
        <v-row>
            <v-col cols="12" sm="12" md="4" lg="3" xl="2"  v-for="license in computedLicenses" :key="license.ulicenseID">
            <licenseCard :license="license" v-on:open-edit="editItem" v-on:delete-license="deleteItem"></licenseCard>
          </v-col>
        </v-row>
    </v-container>
  </div>
  </template>
<script>
import licenseCard from '../components/LicenseCard.vue'
import moment from 'moment'
export default {
  name: 'UserLicenses',
  data: () => ({
    dialog: false,
    userID: null,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      validFrom: '',
      validUntil: '',
      category: '',
      type: ''
    },
    licenseData: []
  }),
  components: {
    licenseCard
  },
  props: {
    customUserID: {
      type: Number,
      default () {
        return null
      },
      required: false
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Neue Berechtigung' : 'Bearbeiten'
    },
    computedLicenses () {
      return this.licenseData
    }
  },
  methods: {
    loadLicenses () {
      this.$store.dispatch('loadUserLicenses', this.customUserID).then(response => {
        this.licenseData = response.data.result
      })
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem (item) {
      this.editedIndex = this.licenseData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.$store.dispatch('deleteUserLicense', item).then(response => {
        if (parseInt(response.data.noErrors) === 0) {
          this.loadLicenses()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    save () {
      if (this.editedIndex > -1) {
        this.$store.dispatch('updateUserLicense', this.editedItem).then(response => {
          //  console.log(response)
          if (parseInt(response.data.noErrors) === 0) {
            // Update License
            this.loadLicenses()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        if (this.customUserID !== null) {
          this.editedItem.userID = this.customUserID
        } else {
          this.editedItem.userID = this.$store.state.User.userID
        }
        if (this.editedItem.category === 1 || this.editedItem.category === 2) {
          this.editedItem.validUntil = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
        this.$store.dispatch('insertUserLicense', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update License
            this.loadLicenses()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    }
  },
  mounted () {
    this.defaultItem.validUntil = moment().subtract(1, 'days').format('YYYY-MM-DD')
    this.editedItem = Object.assign({}, this.defaultItem)
    this.loadLicenses()
  }
}
</script>
