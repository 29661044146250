<template>
  <div>
    <v-text-field v-if="showSearch" v-model="search" label="Suchen" class="mx-4"></v-text-field>
    <v-data-table :headers="computedSelectedHeaders" :items="computedItems" :search="search" multi-sort
      @contextmenu:row="rightClickHandler" :id="tableId" class="elevation-1" @dblclick:row="handleDbClick"
      :items-per-page="itemsPerPage" v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}" :key="anIncreasingNumber"
      :mobile-breakpoint="computedMobileBreakingPoint" :show-select="itemsSelectable" :single-select="!itemsSelectableMultiple" v-model="selectedItems" :item-key="itemSelectKey">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> {{ showNumberOfItems? computedNumberOfItems: null }} {{ title }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <input type="date" label="Von" v-model="from" v-if="enableDateSpan">
          <input type="date" v-model="until" label="Bis"  v-if="enableDateSpan">
          <v-btn @click="loadDataByDateSpan(from, until)" :disabled="until === '' || from === ''" color="indigo accent-4"  outlined  v-if="enableDateSpan">Filter</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-for="(button, index) in customTableButtonsForSelectedItems" :key="index" :color="button.color" :disabled="selectedItems.length <= 0"
          @click="button.function == 'emit' ? $emit(button.functionName, selectedItems) : button.function == 'dispatch' ? $store.dispatch(button.functionName, selectedItems) : ''">
            {{ button.text }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-autocomplete v-if="showSelectHeaders" dense  class="hidden-md-and-down" v-model="selectedHeaders" :items="computedItemHeader" label="Spalten"  multiple return-object style="transform:translateY(10px); overflow-x: auto; height: 45px;">
            <!-- <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">(+{{ selectedHeaders.length - 1 }} others)</span>
            </template> -->
          </v-autocomplete>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" :max-width="dialogWidth">
            <template v-slot:activator="{ on, attrs }" v-if="insert">
              <v-btn color="success" dark class="mb-2" v-bind="attrs" v-on="on">
                {{ insertButtonText }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col v-for="input in computedInputFields" :key="input.dataId" :cols="input.cols" :sm="input.colSm"
                      :md="input.colMd">
                      <v-text-field v-if="input.type === 'text' | input.type === 'number' | input.type === 'email'"
                        v-model="editedItem[input.dataId]" :type="input.type"
                        :label="typeof input.customInputLabel !== 'undefined' ? input.customInputLabel : input.text"
                        :disabled="disableInputs || ( input.disableInputOnInsert && editedIndex === -1) || ( input.disableInputOnEdit && editedIndex != -1)"></v-text-field>
                        <v-textarea v-else-if="input.type === 'textarea'"
                        v-model="editedItem[input.dataId]" :type="input.type"
                        :label="typeof input.customInputLabel !== 'undefined' ? input.customInputLabel : input.text"
                        :disabled="disableInputs || ( input.disableInputOnInsert && editedIndex === -1) || ( input.disableInputOnEdit && editedIndex != -1)"></v-textarea>
                      <v-text-field v-else-if="input.type === 'float'" v-model="editedItem[input.dataId]" type="number"
                        :label="typeof input.customInputLabel !== 'undefined' ? input.customInputLabel : input.text"
                        :step="input.steps" :disabled="disableInputs || ( input.disableInputOnInsert && editedIndex === -1) || ( input.disableInputOnEdit && editedIndex != -1)"></v-text-field>
                      <v-text-field v-else-if="input.type === 'date'" v-model="editedItem[input.dataId]" type="date"
                        :label="typeof input.customInputLabel !== 'undefined' ? input.customInputLabel : input.text"
                        :disabled="disableInputs || ( input.disableInputOnInsert && editedIndex === -1) || ( input.disableInputOnEdit && editedIndex != -1)"></v-text-field>
                      <v-checkbox v-else-if="input.type === 'checkbox'" v-model="editedItem[input.dataId]"
                        :true-value="1" :false-value="0"
                        :label="typeof input.customInputLabel !== 'undefined' ? input.customInputLabel : input.text"
                        :disabled="disableInputs || ( input.disableInputOnInsert && editedIndex === -1) || ( input.disableInputOnEdit && editedIndex != -1)"></v-checkbox>
                      <v-autocomplete v-else-if="input.type === 'autocomplete' | input.type === 'select'"
                        :disabled="disableInputs || ( input.disableInputOnInsert && editedIndex === -1) || ( input.disableInputOnEdit && editedIndex != -1)" v-model="editedItem[input.dataId]"
                        :items="$store.state[input.autocompleteItems]" clearable dense outlined
                        :item-value="input.autocompleteValue" :item-text="input.autocompleteText"
                        :multiple="input.autocompleteMultiple"
                        :label="typeof input.customInputLabel !== 'undefined' ? input.customInputLabel : input.text">
                      </v-autocomplete>
                      <v-file-input v-else-if="input.type === 'file'" :accept="input.acceptFileType" v-model="editedItem[input.dataId]"
                        :label="typeof input.customInputLabel !== 'undefined' ? input.customInputLabel : input.text"
                        :disabled="disableInputs || ( input.disableInputOnInsert && editedIndex === -1) || ( input.disableInputOnEdit && editedIndex != -1)" outlined dense></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="red darken-1" text @click="close">
                  Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="save" :disabled="disableInputs">
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="info" small class="mr-2" @click="openInfoItem(item)" color="blue">
          mdi-information-outline
        </v-icon>
        <v-icon v-if="update" small class="mr-2" @click="openEditItem(item)" color="green">
          mdi-pencil
        </v-icon>
        <v-icon v-if="remove" small @click="openDeleteItem(item)" color="red">
          mdi-delete
        </v-icon>
        <!-- CUSTOM ACTION BUTTONS-->
        <div v-for="(button, index) in customActionButtons" :key="index">
          <v-icon v-if="button.type === 'icon' && button.function === 'dispatch'" small @click="$store.dispatch(button.functionName, item)" :color="button.color">
          {{ button.icon }}
        </v-icon>
        <v-icon v-if="button.type === 'icon' && button.function === 'emit'" small @click="$emit(button.functionName, item)" :color="button.color">
          {{ button.icon }}
        </v-icon>
        <v-btn v-if="button.type === 'button' && button.function === 'emit'" small @click="$emit(button.functionName, item)" :color="button.color">
          {{ button.text }}
        </v-btn>
        <v-btn v-if="button.type === 'button' && button.function === 'dispatch'" small @click="$store.dispatch(button.functionName, item)" :color="button.color">
          {{ button.text }}
        </v-btn>
        </div>
      </template>
      <template v-for="custom in computedTableTemplates" v-slot:[`item.${custom.dataId}`]="{ item }">
        <span v-if="custom.tableCustomTemplateReplace === 'stringToDownloadButton'"
          :key="custom.datId + '_customTemplateA'">
          <v-btn :href="generateLink(custom.downloadPath, item[custom.downloadId], custom)" color="primary" target="_blank"
            download>Download</v-btn>
        </span>
        <span v-else-if="custom.tableCustomTemplateReplace === 'floatToCurrency'"
          :key="custom.datId + '_customTemplateC'"
          v-html="replaceTableData(custom.tableCustomTemplateReplace, item[custom.dataId], custom)">
        </span>
        <span v-else :key="custom.datId + '_customTemplateB'">{{
          replaceTableData(custom.tableCustomTemplateReplace,
          item[custom.dataId], custom)
        }}</span>
      </template>
      <template v-slot:no-data>
        Keine Daten vorhanden.
      </template>
    </v-data-table>
    <!-- RIGHT CLICK MENU -->
    <v-menu v-model="TableMenu.showMenu" :position-x="TableMenu.x" :position-y="TableMenu.y" absolute offset-y
      v-if="rightClickMenu">
      <v-list dense>
        <v-list-item dense @click="openInfoItem(lastSelectedTableItem.item)" v-if="info">
          <v-list-item-icon>
            <v-icon small>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Info</v-list-item-title>
        </v-list-item>
        <v-list-item dense @click="openEditItem(lastSelectedTableItem.item)" v-if="update">
          <v-list-item-icon>
            <v-icon small>mdi-lead-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>
        <v-list-item dense @click="openDeleteItem(lastSelectedTableItem.item)" v-if="remove">
          <v-list-item-icon>
            <v-icon small>mdi-delete-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- RIGHT CLICK MENU  END -->
  </div>
</template>
<script>
import moment from 'moment'
import Sortable from 'sortablejs'
// Add back the sortHandle class if it gets stripped away by external code
function watchClass (targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch)
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i]
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch)
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle')
          }
        }
      }
    }
  })
  observer.observe(targetNode, { attributes: true })
}

export default {
  props: {
    tableId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    newItemText: {
      type: String,
      required: false,
      default () {
        return 'Neuer Eintrag'
      }
    },
    editItemText: {
      type: String,
      required: false,
      default () {
        return 'Eintrag bearbeiten'
      }
    },
    infoItemText: {
      type: String,
      required: false,
      default () {
        return 'Information'
      }
    },
    dataSchema: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    customActionButtons: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    customTableButtonsForSelectedItems: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    showNumberOfItems: {
      type: Boolean,
      required: false,
      default: false
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    insert: {
      type: Boolean,
      required: false,
      default: false
    },
    insertButtonText: {
      type: String,
      required: false,
      default () {
        return 'Hinzufügen'
      }
    },
    actionsLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    update: {
      type: Boolean,
      required: false,
      default: false
    },
    remove: {
      type: Boolean,
      required: false,
      default: false
    },
    info: {
      type: Boolean,
      required: false,
      default: false
    },
    rightClickMenu: {
      type: Boolean,
      required: false,
      default: false
    },
    loadDataFunction: {
      type: String,
      required: false
    },
    loadDataFunctionPayload: {
      required: false,
      default: null
    },
    insertDataFunction: {
      type: String,
      required: false
    },
    updateDataFunction: {
      type: String,
      required: false
    },
    removeDataFunction: {
      type: String,
      required: false
    },
    storeData: {
      type: String,
      required: true
    },
    dialogWidth: {
      type: String,
      required: false,
      default: '500px'
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    },
    showSelectHeaders: {
      type: Boolean,
      required: false,
      default: false
    },
    disableMobileView: {
      type: Boolean,
      required: false,
      default: false
    },
    itemsSelectable: {
      type: Boolean,
      required: false,
      default: false
    },
    itemSelectKey: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    itemsSelectableMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    enableDateSpan: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    search: '',
    dialog: false,
    dialogDelete: false,
    disableInputs: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    lastSelectedTableItem: null,
    selectedItems: [],
    TableMenu: {
      showMenu: false,
      x: null,
      y: null
    },
    selectedHeaders: [],
    anIncreasingNumber: 1,
    from: '',
    until: ''
  }),
  computed: {
    computedHeadersInTable () {
      return this.dataSchema.filter(e => e.showInTable === true)
    },
    computedItemHeader () {
      // Filter all Items in dataSchema where showInTable TRUE
      var headersToRender = this.computedHeadersInTable
      var headers = []
      // Filter to Header Array
      headersToRender.forEach(element => {
        headers.push({ text: element.text, value: element.dataId, sortable: element.sortable, width: element.widthInTable })
      })

      // Add Action Header if update or remove Prop isset AND richtclickMenu is DISABLED (FALSE)
      var actionHeader = { text: 'Aktion', value: 'actions', sortable: false }
      if (this.rightClickMenu === false) {
        if (this.update === true || this.remove === true || this.info === true) {
          this.actionsLeft ? headers.unshift(actionHeader) : headers.push(actionHeader)
        }
      }

      return headers
    },
    formTitle () {
      if (this.editedIndex === -1) {
        return this.newItemText
      } else {
        if (this.disableInputs) {
          return this.infoItemText
        } else {
          return this.editItemText
        }
      }
    },
    computedNumberOfItems () {
      return this.$store.state[this.storeData].length
    },
    computedInputFields () {
      return this.dataSchema.filter(e => e.showInInputDialog === true)
    },
    computedItems () {
      return this.$store.state[this.storeData]
    },
    computedTableTemplates () {
      return this.computedHeadersInTable.filter(e => e.tableCustomTemplate === true)
    },
    computedSelectedHeaders () {
      if (this.selectedHeaders.length === 0) {
        return this.computedItemHeader
      }
      return this.selectedHeaders
    },
    computedMobileBreakingPoint () {
      if (this.disableMobileView) {
        return '0' // 0 = Disable Mobile Table Breaking
      }
      return '600' // Vuetify Default Value
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    loadDataFunctionPayload (newVal, oldVal) {
      this.loadData()
    }
  },
  mounted () {
    // DISABLE RIGHT CLICK
    document.getElementById(this.tableId).addEventListener('contextmenu', function (e) { e.preventDefault() }, false)
    // Load or Reload data in Store
    this.loadData()
    // Create Default Item from Data Schema an assign defaultItem to editedItem
    this.dataSchema.forEach(element => {
      this.defaultItem[element.dataId] = element.defaultValue
    })
    this.editedItem = Object.assign({}, this.defaultItem)
  },
  created () {
    this.selectedHeaders = this.computedItemHeader
  },
  methods: {
    generateLink (path, id, dataSchema) {
      path = path.split('//')[1]
      return 'https://' + this.$store.state.User.auth.username + ':' + this.$store.state.User.auth.password + '@' + path + '?id=' + id + '&user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password
    },
    loadReferenceList (reference, dataSchema) {
      try {
        return this.$store.state[dataSchema.autocompleteItems].filter(e => e[dataSchema.autocompleteValue] === reference)[0][dataSchema.autocompleteText]
      } catch (error) {
        console.warn(`Kann Referenz nicht finden! Liste: ${dataSchema.autocompleteItems}, Identifier: ${dataSchema.autocompleteValue}, Value in Filter: ${reference}`)
        return 'NO REF'
      }
    },
    floatToCurrency (value, dataSchema) {
      var x = parseFloat(value).toFixed(2)
      if (x < 0) {
        return `<span style="color: red"><strong>${x}€</strong></span>`
      }
      return `<span style="color: green"><strong>+${x}€</strong></span>`
    },
    formatDate (date, dataSchema) {
      return moment(date).format('DD.MM.YYYY')
    },
    formatDateTime (date, dataSchema) {
      return moment(date).format('DD.MM.YYYY HH:MM')
    },
    intToYesNo (value, dataSchema) {
      var x = parseInt(value)
      var res = ''
      switch (x) {
        case 0:
          res = 'NO'
          break
        case 1:
          res = 'YES'
          break
        default:
          res = 'Undefined'
          break
      }
      return res
    },
    replaceTableData (functionName, payload, customHeader) {
      // eslint-disable-next-line no-eval
      return eval(`this.${functionName}`)(payload, customHeader)
    },
    loadData () {
      console.log('load Data Triggered')
      if (this.loadDataFunctionPayload != null) {
        this.$store.dispatch({ type: this.loadDataFunction, payload: { customPayload: this.loadDataFunctionPayload } })
      } else {
        this.$store.dispatch({ type: this.loadDataFunction })
      }
    },
    loadDataByDateSpan () {
      //  console.log('load Data Triggered')
      if (this.loadDataFunctionPayload != null) {
        console.log(this.loadDataFunctionPayload)
        this.$store.dispatch({ type: this.loadDataFunction, payload: { d: this.from, sd: this.until, customPayload: this.loadDataFunctionPayload } })
      } else {
        this.$store.dispatch({ type: this.loadDataFunction, payload: { d: this.from, sd: this.until } })
      }
    },
    openInfoItem (item) {
      this.disableInputs = true
      this.openEditItem(item)
    },
    openEditItem (item) {
      this.editedIndex = this.computedItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    openDeleteItem (item) {
      this.editedIndex = this.computedItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      console.log('Delete Function')
      this.$store.dispatch(`${this.removeDataFunction}`, this.editedItem).then(response => {
        //  console.log(response)
        if (response.data.noErrors === 0) {
          // Delete Successfull
          this.loadData()
          this.close()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
      this.closeDelete()
    },
    close () {
      this.dialog = false
      this.disableInputs = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        console.log('Update Function')
        this.$store.dispatch(`${this.updateDataFunction}`, this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update Successfull
            this.loadData()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        //  console.log('Insert Function')
        // validate Plane
        this.$store.dispatch(`${this.insertDataFunction}`, this.editedItem).then(response => {
          console.log(response)
          if (response.data.noErrors === 0) {
            // Insert Successfull
            this.loadData()
            this.close()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    },
    rightClickHandler (event, item) {
      // do something with event and/or item

      //  console.log(item)
      this.TableMenu.x = event.clientX
      this.TableMenu.y = event.clientY
      this.TableMenu.showMenu = true
      this.lastSelectedTableItem = item
    },
    handleDbClick (event, value) {
      this.$emit('double-click-on-row', value.item)
    },
    sortTheHeadersAndUpdateTheKey (evt) {
      var headersTmp = this.selectedHeaders
      var oldIndex = evt.oldIndex
      var newIndex = evt.newIndex
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1
        while (k--) {
          headersTmp.push(undefined)
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0])
      this.table = headersTmp
      this.anIncreasingNumber += 1
    }
  },
  directives: {
    'sortable-table': {
      inserted: (el, binding) => {
        el.querySelectorAll('th').forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, 'sortHandle')
          draggableEl.classList.add('sortHandle')
        })
        Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {})
      }
    }
  }
}
</script>
