<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1900"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Verknüpfung erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <!-- TABELLE ANZEIGE WELCHER AUSZUG GEWÄHLT IST -->
              <v-col cols="12">
                <v-data-table
                  :headers="withdrawHeadersDialog"
                  :items="computedDialogItem"
                  single-expand
                  hide-default-footer
                  item-key="withdrawID"
                  show-expand
                  class="elevation-1"
                >
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                    Kommentar: {{ item.comment }}
                    </td>
                  </template>
                  <template v-slot:item.withdrawDate="{ item }">
                    {{ getDateFromTimestamp(item.withdrawDate) }}
                  </template>
                  <template v-slot:item.value="{ item }">
                    <table>
                      <tr>
                    <strong><span :class="`${getColorForValue(item.value)}--text`">{{ item.value }}&nbsp;{{ item.currency }}</span></strong></tr>
                    <tr>
                    <span :class="`${getColorForValue(computedLinkedPaybackValue)}--text`">{{ computedLinkedPaybackValue }}&nbsp;{{ item.currency }}</span>
                  </tr>
                  <tr>
                    <span :class="`${getColorForValue(computedLinkedTransactionValue)}--text`">{{ computedLinkedTransactionValue }}&nbsp;{{ item.currency }}</span>
                  </tr>
                    </table>
                  </template>
                  <template v-slot:item.saldoAfterWithdraw="{ item }">
                    <span :class="`${getColorForValue(item.saldoAfterWithdraw)}--text`">{{ item.saldoAfterWithdraw }}&nbsp;{{ item.currency }}</span>
                  </template>
                </v-data-table>
              </v-col>
              <!-- FILTER PAYBACK -->
              <v-col cols="1">
                  <!-- Filter for calories -->
                  <v-text-field
                      label="ID"
                      v-model="paybackFilters.receiptID"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                  <!-- Filter for calories -->
                  <v-autocomplete
                      :items="computedPaybackToUsersList"
                      v-model="paybackFilters.paybackTo"
                      item-value="userID"
                      item-text="fullname"
                      label="User"
                      clearable
                  >
                </v-autocomplete>
              </v-col>
              <v-col cols="1">
                  <v-text-field
                      label="Betrag"
                      type="number"
                      step="0.01"
                      v-model="paybackFilters.value"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                  <v-text-field
                      label="Von"
                      type="date"
                      v-model="paybackFilters.dateFrom"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                  <v-text-field
                      label="Bis"
                      type="date"
                      v-model="paybackFilters.dateTo"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      label="Kommentar"
                      type="text"
                      v-model="paybackFilters.comment"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-autocomplete v-model="paybackFilters.costCenter" :items="$store.state.CostCenterList" dense outlined
               item-value="costCenterID" item-text="name" label="Kostenstelle">
            </v-autocomplete>
              </v-col>
              <!-- TABELLE PAYBACK -->
              <v-col cols="12">
                <v-data-table v-if="computedPilotList.length > 0 && computedCategoryList.length > 0" :items="computedPaybackList" :items-per-page="5"
                  :headers="paybackHeaders" sort-by="creationTime" :sort-desc="true" no-data-text="Keine Rückzahlungen vorhanden..." multi-sort item-key="receiptID">
                  <template v-slot:item.creationTime="{ item }"><span>{{
                    getDateFromTimestamp(item.creationTime)
                  }}</span></template>
                  <template v-slot:item.amount="{ item }"><span :class="`${getColorForValue(item.amount * -1)}--text font-weight-bold`">{{
                    parseFloat(item.amount * -1).toFixed(2)
                  }}€</span></template>
                  <template v-slot:item.costCenter="{ item }"><span>{{
                      item.costCenter != null ? $store.state.CostCenterList.filter(e =>
                        e.costCenterID == item.costCenter)[0].name : 'ERROR'
                    }}</span></template>
                  <template v-slot:item.category="{ item }"><span>{{
                    item.category != null ? computedCategoryList.filter(e =>
                      e.typeID == item.category)[0].title : 'ERROR'
                  }}</span></template>
                  <template v-slot:item.paybackType="{ item }"><span>{{
                    item.paybackType != null ?
                      $store.state.PaybackTypeList.filter(e => e.categoryID == item.paybackType)[0].title : 'ERROR'
                  }}</span></template>
                  <template v-slot:item.paybackTo="{ item }"><span><v-icon x-small>fa-user</v-icon> {{
                    item.paybackTo != null ?
                      $store.state.PilotList.filter(e => e.userID == item.paybackTo)[0].firstname + " " +
                      $store.state.PilotList.filter(e => e.userID == item.paybackTo)[0].lastname : 'ERROR'
                  }}</span></template>
                  <template v-slot:item.approvedBy="{ item }"><span><v-icon x-small>fa-user</v-icon> {{
                    item.approvedBy != null ?
                      $store.state.PilotList.filter(e => e.userID == item.approvedBy)[0].firstname + " " +
                      $store.state.PilotList.filter(e => e.userID == item.approvedBy)[0].lastname : '---'
                  }}</span></template>
                  <template v-slot:item.approved="{ item }">
                    <span>
                      <v-chip v-if="item.approved == 0" color="orange" style="color: white"> In Bearbeitung</v-chip>
                      <v-tooltip right v-else-if="item.approved == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" color="green" style="color: white">Genehmigt</v-chip>
                        </template>
                        <span>Genehmigt von {{
                          item.approvedBy != null ? $store.state.PilotList.filter(e => e.userID ==
                            item.approvedBy)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID ==
                              item.approvedBy)[0].lastname : 'unbekannt'
                        }}</span>
                      </v-tooltip>
                      <v-tooltip right v-else-if="item.approved == -1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" color="red" style="color: white">Abgelehnt</v-chip>
                        </template>
                        <span>Abgelehnt von {{
                          item.approvedBy != null ? $store.state.PilotList.filter(e => e.userID ==
                            item.approvedBy)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID ==
                              item.approvedBy)[0].lastname : 'unbekannt'
                        }}</span>
                      </v-tooltip>
                      <v-chip v-else-if="item.approved == 2" color="grey"
                        style="color: white">Erledigt</v-chip>
                    </span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn icon @click="removeFromList(editedItem.paybackID, item.receiptID)" v-if="editedItem.paybackID.includes(item.receiptID)" color="red">Trennen</v-btn>
                    <v-btn icon @click="editedItem.paybackID.push(item.receiptID)" v-else color="primary">Verknüpfen</v-btn>
                  </template>
                  <template v-slot:item.downloadLink="{ item }">
                    <v-btn icon color="indigo" :href="fileDownloadLink(item.files)" target="_blank">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:footer.prepend>
                    <v-checkbox v-model="hideDeniedPaybacks" color="red darken-3" label="Abgelente Erstattungen/Rechnungen ausblenden?"></v-checkbox>
                    <v-spacer></v-spacer>
                    Gesamt: &nbsp; <span :class="`${getColorForValue(computedLinkedPaybackValue)}--text font-weight-bold`">{{ computedLinkedPaybackValue }} €</span>
                    <v-spacer></v-spacer>
                  </template>
                </v-data-table>
          </v-col>
              <!-- FILTER TRANSACTION -->
              <v-col cols="1">
                  <v-text-field
                      label="ID"
                      v-model="transactionFilters.transactionID"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                  <v-autocomplete
                      :items="computedTransactionToUsersList"
                      v-model="transactionFilters.userID"
                      item-value="userID"
                      item-text="fullname"
                      label="User"
                      clearable
                  >
                </v-autocomplete>
              </v-col>
              <v-col cols="1">
                  <!-- Filter for calories -->
                  <v-text-field
                      label="Betrag"
                      type="number"
                      step="0.01"
                      v-model="transactionFilters.value"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                  <v-text-field
                      label="Von"
                      type="date"
                      v-model="transactionFilters.dateFrom"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                  <v-text-field
                      label="Bis"
                      type="date"
                      v-model="transactionFilters.dateTo"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      label="Kommentar"
                      type="text"
                      v-model="transactionFilters.comment"
                      clearable
                  >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-autocomplete v-model="transactionFilters.costCenter" :items="$store.state.CostCenterList" dense outlined
               item-value="costCenterID" item-text="name" label="Kostenstelle">
            </v-autocomplete>
              </v-col>
              <!-- TABELLE TRANSACTION -->
              <v-col cols="12">
                <v-data-table v-if="computedPilotList.length > 0 && computedCategoryList.length > 0" :items="computedTransactionList" :items-per-page="5"
                    :headers="transactionHeaders" sort-by="date" :sort-desc="true" no-data-text="Keine Transaktionen vorhanden..." multi-sort item-key="transactionID">
                    <template v-slot:item.date="{ item }"><span>{{
                      getDateFromTimestamp(item.date)
                    }}</span></template>
                    <template v-slot:item.amount="{ item }"><span :class="`${getColorForValue(item.amount)}--text font-weight-bold`">{{
                      item.amount
                    }}€</span></template>
                    <template v-slot:item.costCenter="{ item }"><span>{{
                      item.costCenter != null ? $store.state.CostCenterList.filter(e =>
                        e.costCenterID == item.costCenter)[0].name : 'ERROR'
                    }}</span></template>
                    <template v-slot:item.paymentMethod="{ item }"><span>{{
                      item.paymentMethod != null ?
                        $store.state.PaymentTypeList.filter(e => e.paymenttypeID == item.paymentMethod)[0].title : 'ERROR'
                    }}</span></template>
                    <template v-slot:item.userID="{ item }"><span><v-icon x-small>fa-user</v-icon> {{
                      item.userID != null ?
                        $store.state.PilotList.filter(e => e.userID == item.userID)[0].firstname + " " +
                        $store.state.PilotList.filter(e => e.userID == item.userID)[0].lastname : 'ERROR'
                    }}</span></template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon @click="removeFromList(editedItem.transactionID, item.transactionID)" v-if="editedItem.transactionID.includes(item.transactionID)" color="red">Trennen</v-btn>
                      <v-btn icon @click="editedItem.transactionID.push(item.transactionID)" v-else color="primary">Verknüpfen</v-btn>
                    </template>
                    <template v-slot:footer.prepend>
                    <v-spacer></v-spacer>
                    Gesamt: &nbsp; <span :class="`${getColorForValue(computedLinkedTransactionValue)}--text font-weight-bold`">{{ computedLinkedTransactionValue }} €</span>
                    <v-spacer></v-spacer>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updateWithdraw()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
            <v-text-field type="text" label="Suche" v-model="search"></v-text-field>
  <v-data-table
      :headers="withdrawHeaders"
      :items="computedWithdraws"
      :search="search"
      single-expand
      :expanded.sync="expanded"
      item-key="withdrawID"
      show-expand
      class="elevation-1"
      sort-by="withdrawDate"
      :sort-desc="true"
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row style="transform: translateY(8px);">
          <v-col cols="2">
            <v-toolbar-title>Bankauszüge</v-toolbar-title>
          </v-col>
          <v-col cols="2">
            <v-text-field type="date" label="Vom" v-model="date.from"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field  type="date" label="Bis" v-model="date.until"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn @click="loadWithdraws()" :disabled="date.until === '' || date.from === ''" color="indigo accent-4"  outlined>Zeitraum ändern</v-btn>
          </v-col>
          <v-col></v-col>
          <v-col cols="2">
            <v-checkbox v-model="hideAlreadyLinkedWithdraws" color="red darken-3" label="Bereits verknüpfte Einträge ausblenden?"></v-checkbox>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.withdrawDate="{ item }">
      {{ getDateFromTimestamp(item.withdrawDate) }}
    </template>
    <template v-slot:item.value="{ item }">
      <strong><span :class="`${getColorForValue(item.value)}--text`">{{ item.value }}&nbsp;{{ item.currency }}</span></strong>
    </template>
    <template v-slot:item.saldoAfterWithdraw="{ item }">
      <span :class="`${getColorForValue(item.saldoAfterWithdraw)}--text`">{{ item.saldoAfterWithdraw }}&nbsp;{{ item.currency }}</span>
    </template>
    <template v-slot:item.paybackID="{ item }">
      <v-btn v-if="item.paybackID === null || item.paybackID.length === 0" @click="openDialog(item)" color="success">Verknüpfen</v-btn>
      <v-tooltip top v-else>
      <template v-slot:activator="{ on, attrs }" >
        <v-chip class="ma-2" color="primary" label  v-on:dblclick="openDialog(item)" v-bind="attrs" v-on="on">
      {{ item.paybackID }}
      <v-icon right>
        mdi-link-variant
      </v-icon>
      </v-chip>
      </template>
      <span>Zum bearbeiten Doppelklick</span>
    </v-tooltip>
    </template>
    <template v-slot:item.transactionID="{ item }">
      <v-btn v-if="item.transactionID === null || item.transactionID.length === 0" @click="openDialog(item)" color="success">Verknüpfen</v-btn>
      <v-tooltip top v-else>
      <template v-slot:activator="{ on, attrs }" >
        <v-chip class="ma-2" color="primary" label  v-on:dblclick="openDialog(item)" v-bind="attrs" v-on="on">
      {{ item.transactionID }}
      <v-icon right>
        mdi-link-variant
      </v-icon>
      </v-chip>
      </template>
      <span>Zum bearbeiten Doppelklick</span>
    </v-tooltip>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
       IBAN-Partner: {{ item.ibanPartner }}
       <br>
       BIC-Partner: {{ item.bicPartner }}
       <br>
       Text: {{ item.withdrawText }}
       <br>
       CLUB-IBAN: {{ item.ibanClubAccount }}
       <br>
       Ausführungs-Datum: {{ item.valutaDate }}
      </td>
    </template>
  </v-data-table>
</div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'adminAccounting',
  data () {
    return {
      search: '',
      withdrawHeaders: [
        { text: 'ID', value: 'withdrawID' },
        { text: 'Datum', value: 'withdrawDate' },
        { text: 'Partner', value: 'namePartner' },
        { text: 'Referenz', value: 'reference' },
        { text: 'Kommentar', value: 'internalComment' },
        { text: 'Betrag', value: 'value' },
        { text: 'Saldo', value: 'saldoAfterWithdraw' },
        { text: 'Rückzahlung', value: 'paybackID' },
        { text: 'Transaktion', value: 'transactionID' }
      ],
      withdrawHeadersDialog: [
        { text: 'ID', value: 'withdrawID' },
        { text: 'Datum', value: 'withdrawDate' },
        { text: 'Partner', value: 'namePartner' },
        { text: 'Referenz', value: 'reference' },
        { text: 'Betrag', value: 'value' }
      ],
      paybackHeaders: [
        { text: 'ID', value: 'receiptID', filter: this.paybackIDFilter },
        { text: 'Status', value: 'approved' },
        { text: 'Datum', value: 'creationTime', filter: this.paybackDateFilter },
        { text: 'Betrag', value: 'amount', filter: this.paybackValueFilter },
        { text: 'Kategorie', value: 'category' },
        { text: 'Kostenstelle', value: 'costCenter', filter: this.paybackCostCenterFilter },
        { text: 'Kommentar', value: 'comment', filter: this.paybackCommentFilter },
        { text: 'Rückzahlung', value: 'paybackType' },
        { text: 'An', value: 'paybackTo', filter: this.paybackUserFilter },
        { text: 'Download', value: 'downloadLink' },
        { text: 'Aktionen', value: 'actions' }
      ],
      transactionHeaders: [
        { text: 'ID', value: 'transactionID', filter: this.transactionIDFilter },
        { text: 'User', value: 'userID', filter: this.transactionUserFilter },
        { text: 'Wert', value: 'amount', filter: this.transactionValueFilter },
        { text: 'Datum', value: 'date', filter: this.transactionDateFilter },
        { text: 'Zahlart', value: 'paymentMethod' },
        { text: 'Kostenstelle', value: 'costCenter', filter: this.transactionCostCenterFilter },
        { text: 'Kommentar', value: 'comment', filter: this.transactionCommentFilter },
        { text: 'Aktionen', value: 'actions' }
      ],
      paybackFilters: {},
      paybackFiltersDefault: {
        receiptID: null,
        paybackTo: null,
        value: null,
        dateFrom: null,
        dateTo: null,
        costCenter: null,
        comment: null
      },
      transactionFilters: {},
      transactionFiltersDefault: {
        transactionID: null,
        userID: null,
        value: null,
        dateFrom: null,
        dateTo: null,
        costCenter: null,
        comment: null
      },
      withdraws: [],
      editedItem: {},
      defaultItem: {
        paybackID: [],
        transactionID: []
      },
      expanded: [],
      dialog: false,
      date: {
        from: null,
        until: null
      },
      hideAlreadyLinkedWithdraws: true,
      hideDeniedPaybacks: true
    }
  },
  computed: {
    computedPilotList () {
      return this.$store.state.PilotList
    },
    computedPaybackList () {
      if (this.hideDeniedPaybacks) {
        return this.$store.state.AdminPaybackData.filter(e => e.approved !== -1)
      }
      return this.$store.state.AdminPaybackData
    },
    computedTransactionList () {
      return this.$store.state.AllUserTransactions
    },
    computedCategoryList () {
      return this.$store.state.PaybackCategoryList
    },
    computedDialogItem () {
      if (this.editedItem === null) {
        return []
      }
      return [this.editedItem]
    },
    computedPaybackToUsersList () {
      return this.$store.state.PilotList.filter(e => [...new Set(this.computedPaybackList.map(item => item.paybackTo))].includes(e.userID))
    },
    computedTransactionToUsersList () {
      return this.$store.state.PilotList.filter(e => [...new Set(this.computedTransactionList.map(item => item.userID))].includes(e.userID))
    },
    computedWithdraws () {
      if (this.hideAlreadyLinkedWithdraws) {
        return this.withdraws.filter(e => (e.paybackID === null || e.paybackID.length === 0) && (e.transactionID === null || e.transactionID.length === 0))
      }
      return this.withdraws
    },
    computedLinkedPaybackValue () {
      var sum = 0.0
      this.editedItem.paybackID.forEach(id => {
        sum = sum + parseFloat(this.computedPaybackList.filter(e => e.receiptID === id)[0].amount * -1)
      })
      return sum.toFixed(2)
    },
    computedLinkedTransactionValue () {
      var sum = 0.0
      this.editedItem.transactionID.forEach(id => {
        sum = sum + parseFloat(this.computedTransactionList.filter(e => e.transactionID === id)[0].amount)
      })
      return sum.toFixed(2)
    }
  },
  methods: {
    fileDownloadLink (id) {
      return 'https://' + this.$store.state.User.auth.username + ':' + this.$store.state.User.auth.password + '@' + process.env.VUE_APP_MAIN_API_DOMAIN + process.env.VUE_APP_API_PATH_DOWNLOAD_PAYBACK_PATH + '?id=' + id + '&user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password
    },
    getDateFromTimestamp (stamp) {
      return moment(stamp, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY')
    },
    loadWithdraws () {
      this.$store.dispatch('loadAdminWithdraws', this.date).then(response => {
        var newList = response.data.result
        for (var i = 0; i < newList.length; i++) {
          for (var [key, value] of Object.entries(newList[i])) {
            if ((key === 'paybackID' || key === 'transactionID') && value !== null && value !== '') {
              newList[i][key] = value.split(';')
              for (var x = 0; x < newList[i][key].length; x++) {
                newList[i][key][x] = parseInt(newList[i][key][x])
              }
              continue
            }
            if (key === 'internalComment' || key === 'internalCategory' || key === 'namePartner') continue
            if (key === 'value' || key === 'saldoAfterWithdraw') {
              newList[i][key] = parseFloat(value).toFixed(2)
              continue
            }
            if (!isNaN(value) && value !== null) {
              newList[i][key] = parseInt(value)
            }
          }
        }
        this.withdraws = newList
      })
    },
    loadPaybacks () {
      this.$store.dispatch('loadAdminPayback')
    },
    loadTransactions () {
      this.$store.dispatch('loadAllTransactions')
    },
    getColorForValue (value) {
      if (parseFloat(value) < 0) {
        return 'red'
      } else {
        return 'green'
      }
    },
    openDialog (item) {
      this.editedItem = Object.assign({}, item)

      if (this.editedItem.paybackID === null) {
        this.editedItem.paybackID = []
      } else if (this.editedItem.paybackID.length > 0) {
        this.paybackFilters.receiptID = this.editedItem.paybackID.join(',')
      }

      if (this.editedItem.transactionID === null) {
        this.editedItem.transactionID = []
      } else if (this.editedItem.transactionID.length > 0) {
        this.transactionFilters.transactionID = this.editedItem.transactionID.join(',')
      }
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.transactionFilters = Object.assign({}, this.transactionFiltersDefault)
      this.paybackFilters = Object.assign({}, this.paybackFiltersDefault)
    },
    updateWithdraw () {
      // Update Withdraw
      this.$store.dispatch('updateWithdraw', this.editedItem).then(response => {
        //  console.log(response)
        if (parseInt(response.data.noErrors) === 0) {
          // Update Transaction
          this.loadWithdraws()
          this.closeDialog()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    paybackUserFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.paybackFilters.paybackTo) {
        return true
      }

      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.paybackFilters.paybackTo
    },
    paybackDateFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.paybackFilters.dateFrom && !this.paybackFilters.dateTo) {
        return true
      }
      if (this.paybackFilters.dateFrom !== null && this.paybackFilters.dateTo === null) {
        // return nur wenn value >= From
        return new Date(value).setHours(0, 0, 0, 0) >= new Date(this.paybackFilters.dateFrom).setHours(0, 0, 0, 0)
      }

      if (this.paybackFilters.dateFrom === null && this.paybackFilters.dateTo !== null) {
        // return nur wenn value <= TO
        return new Date(value).setHours(0, 0, 0, 0) <= new Date(this.paybackFilters.dateTo).setHours(0, 0, 0, 0)
      }

      if (this.paybackFilters.dateFrom !== null && this.paybackFilters.dateTo !== null) {
        // return nur wenn value >= From und <= To
        return (new Date(value).setHours(0, 0, 0, 0) >= new Date(this.paybackFilters.dateFrom).setHours(0, 0, 0, 0) && new Date(value).setHours(0, 0, 0, 0) <= new Date(this.paybackFilters.dateTo).setHours(0, 0, 0, 0))
      }

      return true
    },
    paybackIDFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.paybackFilters.receiptID) {
        return true
      }
      var array = this.paybackFilters.receiptID.split(',')
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      for (var i = 0; i < array.length; i++) {
        array[i] = parseInt(array[i])
      }
      return array.includes(value)
    },
    paybackValueFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.paybackFilters.value) {
        return true
      }

      return (parseInt(value) === parseInt(this.paybackFilters.value) || parseInt(value) === parseInt(this.paybackFilters.value * -1))
    },
    paybackCommentFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.paybackFilters.comment) {
        return true
      }
      if (!value) {
        return false
      }
      var foundWords = []
      this.paybackFilters.comment.split(' ').forEach(element => {
        if (value.toUpperCase().indexOf(element.toUpperCase()) > -1) {
          foundWords.push(1)
        } else {
          foundWords.push(0)
        }
      })
      const alleElementeSindEins = array => array.every(element => element === 1)
      return alleElementeSindEins(foundWords)
    },
    paybackCostCenterFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.paybackFilters.costCenter) {
        return true
      }
      return parseInt(value) === parseInt(this.paybackFilters.costCenter)
    },
    transactionCostCenterFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.transactionFilters.costCenter) {
        return true
      }
      return parseInt(value) === parseInt(this.transactionFilters.costCenter)
    },
    transactionUserFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.transactionFilters.userID) {
        return true
      }

      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.transactionFilters.userID
    },
    transactionIDFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.transactionFilters.transactionID) {
        return true
      }
      var array = this.transactionFilters.transactionID.split(',')
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      for (var i = 0; i < array.length; i++) {
        array[i] = parseInt(array[i])
      }
      return array.includes(value)
    },
    transactionValueFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.transactionFilters.value) {
        return true
      }

      return (parseInt(value) === parseInt(this.transactionFilters.value) || parseInt(value) === parseInt(this.transactionFilters.value * -1))
    },
    transactionDateFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.transactionFilters.dateFrom && !this.transactionFilters.dateTo) {
        return true
      }
      if (this.transactionFilters.dateFrom !== null && this.transactionFilters.dateTo === null) {
        // return nur wenn value >= From
        return new Date(value).setHours(0, 0, 0, 0) >= new Date(this.transactionFilters.dateFrom).setHours(0, 0, 0, 0)
      }

      if (this.transactionFilters.dateFrom === null && this.transactionFilters.dateTo !== null) {
        // return nur wenn value <= TO
        return new Date(value).setHours(0, 0, 0, 0) <= new Date(this.transactionFilters.dateTo).setHours(0, 0, 0, 0)
      }

      if (this.transactionFilters.dateFrom !== null && this.transactionFilters.dateTo !== null) {
        // return nur wenn value >= From und <= To
        return (new Date(value).setHours(0, 0, 0, 0) >= new Date(this.transactionFilters.dateFrom).setHours(0, 0, 0, 0) && new Date(value).setHours(0, 0, 0, 0) <= new Date(this.transactionFilters.dateTo).setHours(0, 0, 0, 0))
      }

      return true
    },
    transactionCommentFilter (value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.transactionFilters.comment) {
        return true
      }
      if (!value) {
        return false
      }
      var foundWords = []
      this.transactionFilters.comment.split(' ').forEach(element => {
        if (value.toUpperCase().indexOf(element.toUpperCase()) > -1) {
          foundWords.push(1)
        } else {
          foundWords.push(0)
        }
      })
      const alleElementeSindEins = array => array.every(element => element === 1)
      return alleElementeSindEins(foundWords)
    },
    removeFromList (list, item) {
      const index = list.indexOf(item)
      list = list.splice(index, 1)
    }
  },
  mounted () {
    this.editedItem = Object.assign({}, this.defaultItem)
    this.transactionFilters = Object.assign({}, this.transactionFiltersDefault)
    this.paybackFilters = Object.assign({}, this.paybackFiltersDefault)
    this.loadWithdraws()
    this.loadPaybacks()
    this.loadTransactions()
  }
}
</script>
