<template>
<v-container fluid>
<v-divider></v-divider>
  <CURDTable
            tableId="downloadTable"
            title="Downloads verfügbar"
            newItemText="Datei Hochladen"
            insertButtonText="Hochladen"
            :dataSchema="dataSchemaDownloads"
            loadDataFunction="loadDownloads"
            insertDataFunction="addDownload"
            storeData="Downloads"
            showNumberOfItems
            showSearch
            insert
            actionsLeft
            ></CURDTable>
</v-container>
</template>

<script>
import CURDTable from '../components/curdTable.vue'
export default {
  name: 'Downloads',
  data: () => ({
    dataSchemaDownloads: [
      { dataId: 'documentID', type: 'number', text: 'ID', defaultValue: '', showInTable: false, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'typ', type: 'number', text: 'typ', defaultValue: 0, showInTable: false, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'date', type: 'date', text: 'Datum', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDateTime' },
      { dataId: 'name', type: 'text', text: 'Datei', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'comment', type: 'text', text: 'Kommentar', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'creator', type: 'number', text: 'Hochgeladen von', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'access', type: 'number', text: 'Zugriffsrechte', showInTable: false, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'files', type: 'file', text: 'Download', customInputLabel: 'Datei', showInTable: true, defaultValue: [], sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'stringToDownloadButton', downloadId: 'documentID', downloadPath: process.env.VUE_APP_API_PATH_DOWNLOAD_BY_ID_PATH }
    ]
  }),
  components: {
    CURDTable
  },
  async mounted () {
    this.$store.dispatch('loadDownloads')
  }
}
</script>
