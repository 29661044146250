<template>
    <v-card
      class="mx-auto"
      width="100%"
    >
    <v-card-title>
      <!-- <v-icon
        left
      >
      fa-plane
      </v-icon>
      <v-spacer></v-spacer> -->
      <span class="text-h6 font-weight-bold">{{license.licenseName}}</span>
      <v-spacer></v-spacer>
      <v-chip
      v-if="license.category == 2"
      class="ma-2"
      color="red"
      outlined
    >
      Lehrer
    </v-chip>
    </v-card-title>
            <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr>
          <td>Ausgestellt</td>
          <td>{{formatDate(license.validFrom)}}</td>
        </tr>
        <tr>
          <td>Gültig bis</td>
          <td>{{formatDate(license.validUntil)}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
      <v-card-actions>
        <v-btn
          text
          color="red accent-4"
          width="80%"
          @click="deleteLicense"
        >
          löschen
        </v-btn>
        <v-btn
          icon
          x-small
          color="orange accent-4"
          @click="$emit('open-edit', license);"
          v-if="$store.state.UserInfo.adm == '1'"
        >
        <v-icon
        left
      >
      fa-pen
      </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
<script>
import moment from 'moment'
export default {
  name: 'LicenseCard',
  data: () => ({
  }),
  props: {
    license: {
      type: Object,
      required: true,
      default: null
    }
  },
  methods: {
    formatDate (date) {
      return date != null ? moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY') : 'Abhängig'
    },
    deleteLicense () {
      if (confirm('Lizenz ' + this.license.licenseName + ' löschen?') === true) {
        this.$emit('delete-license', this.license)
        // this.$store.dispatch('deleteUserLicense', this.license).then(response => {
        //   if (parseInt(response.data.noErrors) === 0) {
        //     this.$store.dispatch('loadUserLicenses')
        //   } else {
        //     this.$store.commit('setApiErrorResponse', response.data)
        //   }
        // })
      }
    }
  },
  mounted () {
  }
}
</script>
