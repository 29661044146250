<template>
 <v-navigation-drawer clipped app v-if="$store.state.User.userID != -1" v-model="drawer">
      <v-list>
        <v-list-item v-for="(item, index) in navItems" :key="index" :to="{ name: item.routerName}">
         <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
            <v-list-item-title v-if="'badges' in item && loadCount(item.badges) > 0" style="transform: translateY(-5px);"><v-badge
          color="red"
            inline
          :content="loadCount(item.badges)"
        >
          {{ item.title }}
        </v-badge></v-list-item-title>
        <v-list-item-title v-else v-text="item.title"></v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="(item, index) in externalItems" :key="index + navItems.length" :href="item.href"  target="_blank">
         <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title><v-icon small>fa-external-link-alt</v-icon>
        </v-list-item>
        <v-divider></v-divider>
        <div v-if="$store.state.UserInfo.adm == '1'">
        <v-list-item  v-for="(item, index) in adminItems" :key="index + externalItems.length + navItems.length"  :to="{ name: item.routerName}">
         <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="'badges' in item && loadCount(item.badges) > 0" style="transform: translateY(-5px);"><v-badge
          color="red"
            inline
          :content="loadCount(item.badges)"
        >
          {{ item.title }}
        </v-badge></v-list-item-title>
        <v-list-item-title v-else v-text="item.title"></v-list-item-title>
        </v-list-item>
        <v-list-item>
         <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
            <v-list-item-title></v-list-item-title>
        </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
  data: function () {
    return {
      drawer: true,
      navItems: [
        { title: 'Mein Account', routerName: 'myAccount', icon: 'fa-user' },
        { title: 'Mein Vereinskonto', routerName: 'myBills', icon: 'fa-list-alt' },
        { title: 'Meine Berechtigungen', routerName: 'LicenseManagement', icon: 'fa-id-card' },
        { title: 'Meine Dokumente', routerName: 'myDocuments', icon: 'fa-folder-open' },
        { title: 'Erstattung/Rechnungen', routerName: 'createPayback', icon: 'fa-money-bill-alt' },
        // { title: 'Flugbetrieb/Umfragen', routerName: 'survey', icon: 'fa-poll' },
        { title: 'Aufgaben/Orga', routerName: 'tasks', icon: 'fa-hammer', badges: 'UserInfo.assignedTasks' },
        { title: 'Hauptflugbuch', routerName: 'flightlists', icon: 'fa-bars' },
        { title: 'Zahlen, Daten, Fakten', routerName: 'statistic', icon: 'fa-chart-pie' },
        { title: 'Downloads', routerName: 'downloads', icon: 'fa-download' },
        { title: 'Buchhaltung', routerName: 'adminAccounting', icon: 'fa-tasks' }
      ],
      externalItems: [
        { title: 'Gildestone', href: 'https://glidestone.fcbb.de', icon: 'fa-plane-departure' },
        { title: 'Reservierung', href: 'https://glidestone.fcbb.de/booking', icon: 'fa-calendar-alt' },
        { title: 'Cloud', href: 'https://cloud.fcbb.de', icon: 'fa-cloud' },
        { title: 'Lernomat 1000', href: 'https://akaflieg-berlin.de/lernomat1000/', icon: 'fa-question' },
        { title: 'ECQB - PPL (A)', href: 'https://fcbb.de/ecqb', icon: 'fa-question' }
      ],
      adminItems: [
        { title: 'Verein', routerName: 'clubManagement', icon: 'fa-warehouse' },
        { title: 'Verwaltung', routerName: 'management', icon: 'fa-tasks', badges: 'UserInfo.openPaybacks' },
        { title: 'Flugzeugwartung', routerName: 'planeMaintenance', icon: 'fa-plane' }
      ]
    }
  },
  methods: {
    loadCount (target) {
      // eslint-disable-next-line no-eval
      // console.log(eval(`this.$store.state.${target}`))
      // console.log(`this.$store.state.${target}`)
      // eslint-disable-next-line no-eval
      return eval(`this.$store.state.${target}`)
    }
  },
  mounted () {
    this.$root.$on('toggleNavBarButton', data => {
      //  console.log(data)
      this.drawer = !this.drawer
    })
  }
}
</script>
