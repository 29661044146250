<template>
<v-container fill-height fluid>
  <v-row align="center" justify="center">
    <v-sheet
  color="white"
  elevation="24"
  width="400"
>
<v-container>
  <v-row justify="center">
    <h3>RFID-TAG zuweisen</h3>
    </v-row>
    <v-row>
    <v-divider></v-divider>
    <v-col cols="12">
      <v-text-field v-model="editedItem.uuid" label="UUID" outlined disabled></v-text-field>
    </v-col>
    <v-col cols="12" v-if="userIsAdmin">
      <v-autocomplete
                            v-model="editedItem.userID"
                            :items="$store.state.PilotList"
                            clearable
                            outlined
                            item-value="userID"
                            item-text="fullname"
                            label="Mitglied"
                          >
                          </v-autocomplete>
    </v-col>
    <v-col cols="12">
      <v-btn color="success" style="width: 100%;" @click="assignTag">{{ computedButtonText }}</v-btn>
    </v-col>
  </v-row>
</v-container>
</v-sheet>
  </v-row>
</v-container>

</template>

<script>
export default {
  name: 'assignTag',
  components: {
  },
  data () {
    return {
      editedItem: {
        userID: null,
        uuid: null
      }
    }
  },
  methods: {
    assignTag () {
      // console.log(this.editedItem)
      this.$store.dispatch('insertTag', this.editedItem).then(response => {
        if (response.data.noErrors === 0) {
          alert('Tag zugewiesen!')
          if (this.$store.state.User.userID === this.editedItem.userID) {
            this.$router.push({ name: 'myDocuments', hash: '#tags' })
          } else {
            this.$router.push({ name: 'adminTags' })
          }
        } else {
          console.log(response.data)
        }
      })
    }
  },
  computed: {
    getTagUUID () {
      return this.$route.params.uuid
    },
    userIsAdmin () {
      return this.$store.state.UserInfo.adm === 1
    },
    computedButtonText () {
      if (this.userIsAdmin) {
        return 'Tag zuweisen'
      }
      return 'Dieses Tag mir zuweisen'
    }
  },
  mounted () {
    this.editedItem.uuid = this.getTagUUID
    this.editedItem.userID = this.$store.state.User.userID
  }
}
</script>
