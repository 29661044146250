<template>
<v-container fluid>
<v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
<v-row style="margin-bottom: 15px; margin-top: 5px;">
<v-spacer></v-spacer>
<v-btn outlined  color="indigo accent-4" @click="previousMonth()" :diasbled="loading"><v-icon left>fa-arrow-left</v-icon>  {{previousBtnText}}</v-btn>
<v-spacer></v-spacer>
<strong class="display-1"> {{monthToSting(month)}} {{ year }}</strong>
<v-spacer></v-spacer>
<v-btn outlined color="indigo accent-4" @click="nextMonth()" :disabled="((year +1) > currentYear && (month + 1) > currentMonth ) || loading">{{ nextBtnText }} <v-icon right>fa-arrow-right</v-icon></v-btn>
<v-spacer></v-spacer>

</v-row>
<v-card v-if="$store.state.UserInfo.adm === 1">
    <input type="date" label="Von" v-model="from">
    <input type="date" v-model="until" label="Bis">
    <v-btn @click="loadFlightsByDateSpan(from, until)" :disabled="until === '' || from === ''" color="indigo accent-4"  outlined>Filter</v-btn>
    <v-btn :href="generateLink" color="orange accent-4" outlined :disabled="until === '' || from === ''" target="_blank">CSV Generieren und versenden</v-btn>
    <v-btn @click="generateVereinsfliegerExport" color="primary" outlined :disabled="until === '' || from === ''">Vereinsflieger Export</v-btn>
</v-card>
<v-divider></v-divider>
  <v-card>
    <v-card-title>
      Startliste {{formatDate(from)}} - {{formatDate(until)}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche nach Datum, Rufzeichen, Pilot, Begleiter, Start-/Landeort"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <FlightList v-bind:flightsExternal="flights" v-bind:searchExternal="search" :denseExternal="true" v-if="flights.length > 0" @toggle-data-reload="loadFlightsByDateSpan"></FlightList>
  <v-row v-else><v-spacer></v-spacer>Keine Flüge im {{monthToSting (month)}} {{year}}<v-spacer></v-spacer></v-row>
  </v-card>
</v-container>
</template>

<script>
import FlightList from '../components/FlightList.vue'
import moment from 'moment'
export default {
  name: 'FlightLists',
  data: () => ({
    flights: [],
    year: null,
    month: null,
    search: '',
    from: '',
    until: '',
    loading: true
  }),
  components: {
    FlightList
  },
  watch: {
    year (newYear, oldYear) {
      this.loading = true
      this.loadFlightsByDateSpan()
    },
    month (newMonth, oldMonth) {
      this.loading = true
      this.loadFlightsByDateSpan()
    }
  },
  methods: {
    generateVereinsfliegerExport () {
      this.$store.dispatch('exportVereinsflieger', { d: this.from, sd: this.until }).then(response => {
        if (parseInt(response.data.noErrors) === 0) {
          this.downloadCSV(moment().format('YYYYMMDD_HHmmss') + '_vereinsflieger.csv', response.data.result.CSV)
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    downloadCSV (filename, string) {
      var pom = document.createElement('a')
      var bb = new Blob([string], { type: 'text/plain' })
      pom.setAttribute('href', window.URL.createObjectURL(bb))
      pom.setAttribute('download', filename)
      pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':')
      pom.draggable = true
      pom.classList.add('dragout')
      pom.click()
    },
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    previousMonth () {
      if (this.month === 1) {
        this.year--
        this.month = 12
      } else {
        this.month--
      }
    },
    nextMonth () {
      if (this.month === 12) {
        this.year++
        this.month = 1
      } else {
        this.month++
      }
    },
    daysInMonth (year, month) {
      return new Date(year, month + 1, 0).getDate()
    },
    loadFlightsByDateSpan (fromDate = null, toDate = null) {
      if (fromDate === null) {
        fromDate = this.year + '-' + this.month + '-01'
        this.from = fromDate
      }
      if (toDate === null) {
        toDate = this.year + '-' + this.month + '-' + this.daysInMonth(this.year, this.month - 1)
        this.until = toDate
      }
      this.$store.dispatch('loadFlightListByDateSpan', { from: fromDate, to: toDate }).then(response => {
        const newList = response.data.result
        for (var i = 0; i < newList.length; i++) {
          for (var [key, value] of Object.entries(newList[i])) {
            if (key === 'totalCost') continue
            if (key === 'engineStart') continue
            if (key === 'engineStop') continue
            if (!isNaN(value) && value !== null) {
              newList[i][key] = parseInt(value)
            }
          }
        }
        this.flights = newList
        this.loading = false
      })
    },
    monthToSting (number) {
      switch (parseInt(number)) {
        case 0: return 'Dezember'
        case 1: return 'Januar'
        case 2: return 'Februar'
        case 3: return 'März'
        case 4: return 'April'
        case 5: return 'Mai'
        case 6: return 'Juni'
        case 7: return 'Juli'
        case 8: return 'August'
        case 9: return 'September'
        case 10: return 'Oktober'
        case 11: return 'November'
        case 12: return 'Dezember'
        case 13: return 'Januar'
        default: return 'FEHLER'
      }
    }
  },
  computed: {
    computedYearList () {
      var list = []
      var start = this.year - 10
      var ende = this.year + 10
      for (start; start <= ende; start++) {
        list.push(start)
      }
      return list
    },
    generateLink () {
      return 'https://' + this.$store.state.User.auth.username + ':' + this.$store.state.User.auth.password + '@' + process.env.VUE_APP_MAIN_API_DOMAIN + '/flight/getCSVtoday.php' + '?d=' + this.from + '&sd=' + this.until
    },
    previousBtnText () {
      if (this.month === 1) {
        return this.monthToSting(12) + ' ' + (this.year - 1)
      }
      return this.monthToSting((this.month - 1)) + ' ' + this.year
    },
    nextBtnText () {
      if (this.month === 12) {
        return this.monthToSting(1) + ' ' + (this.year + 1)
      }
      return this.monthToSting((this.month + 1)) + ' ' + this.year
    },
    currentYear () {
      return moment().format('YYYY')
    },
    currentMonth () {
      return moment().format('MM')
    }
  },
  mounted () {
    this.year = parseInt(this.currentYear)
    this.month = parseInt(this.currentMonth)
    this.from = moment().startOf('month').format('YYYY-MM-DD')
    this.until = moment().endOf('month').format('YYYY-MM-DD')
  }
}
</script>
