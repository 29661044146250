<template>
<vue-chart
              type="line"
              :data="chartData"
            ></vue-chart>
</template>

<script>
import VueChart from 'vue-chart-js'

export default {
  name: 'launchesPerMonthChart',
  components: { VueChart },
  data () {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  },
  computed: {
    computedDataProvider () {
      if (this.$store.state.Statistic.PlaneStat === undefined) return false
      return this.$store.state.Statistic.PlaneStat
    },
    chartData () {
      if (this.computedDataProvider === false) return
      var ChartDataArray = [0, 0, 0] // ["Winde", "F-Schlepp", "Eigenstart"]
      this.computedDataProvider.forEach(element => {
        ChartDataArray[0] = ChartDataArray[0] + element.landingsWinchLaunch
        ChartDataArray[1] = ChartDataArray[1] + element.landingsTowGlider + element.landingsTowMotor // Add Towmotor to towglider because both planes takeoff and the motor plane is not "selfLaunch"
        ChartDataArray[2] = ChartDataArray[2] + element.landingsSelfLaunch
      })

      return {
        labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktover', 'November', 'Dezember'],
        datasets: [
          {
            label: 'Gesamt',
            data: [0, 0, 30, 48, 205, 198, 345, 845, 200, 80, 5, 2],
            fill: false,
            borderColor: [
              'rgba(255, 99, 132, 1)'
            ]
          },
          {
            label: 'Club',
            fill: false,
            data: [2, 0, 30, 48, 205, 98, 100, 400, 200, 80, 5, 2],
            borderColor: [
              'rgba(54, 162, 235, 1)'
            ]
          },
          {
            label: 'andere',
            fill: false,
            data: [0, 0, 0, 0, 0, 0, 260, 445, 0, 0, 0, 0],
            borderColor: [
              'rgba(255, 206, 86, 1)'
            ]
          }
        ]
      }
    }
  }
}
</script>
