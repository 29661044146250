
<template>
<v-container class="bg" fluid fill-height>
 <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>{{ message }}</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                           <v-text-field
                              prepend-icon="fa-at"
                              v-model="auth.username"
                              v-on:keyup.enter="loginUser"
                              label="Username"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              prepend-icon="fa-lock"
                              v-model="auth.password"
                              v-on:keyup.enter="loginUser"
                              label="Passwort"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                      <v-row>
                        <v-col cols="9"><v-btn style="width: 100%;" outlined @click="backToWebsite()">Zurück zu fcbb.de</v-btn></v-col>
                        <v-col cols="3"><v-btn color="primary" style="width: 100%" @click="loginUser()">Login</v-btn></v-col>
                      </v-row>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
</v-container>
</template>
<style>
body, html {
  height: 100%;
}

.bg {
  /* The image used */
  background-image: url("~@/assets/loginBG/london.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
<script>
import axios from 'axios'
var qs = require('qs')
export default {
  name: 'login',
  data () {
    return {
      auth: {
        username: '',
        password: ''
      },
      message: 'Login'
    }
  },
  methods: {
    backToWebsite () {
      window.location.href = 'https://fcbb.de'
    },
    loginUser: function () {
      // Validate user Input here
      this.validateUserCredentials().then(response => {
        //  console.log(response)
        if (response.data !== 'NOT AUTHORIZED') {
          if (parseInt(response.data.noErrors) > 0) {
            //  console.log('Login Error')
            this.message = response.data.errorMsg
          } else {
            // console.log(response)
            // Store Correct User data
            this.$store.dispatch('loginUser', { userID: parseInt(response.data.result.userID), auth: { username: this.auth.username, password: response.data.result.token, authStr: response.data.result.authStr } })
            // Reset Form
            this.message = 'Login'
            this.auth.username = ''
            this.auth.password = ''
            if (this.$route.query.redirect === undefined) {
              this.$router.push({ name: 'myAccount' })
            } else {
              this.$router.push(this.$route.query.redirect)
            }
          }
        } else {
          this.message = 'NOT AUTHORIZED'
        }
      })
    },
    async validateUserCredentials () {
      //  console.log(this.auth)
      return axios.post(process.env.VUE_APP_API_PATH_AUTH_USER, qs.stringify({
        userName: this.auth.username,
        password: this.auth.password
      }))
    }
  },
  mounted () {
    if (this.$store.state.User.userID !== -1) {
      this.$router.push({ name: 'myAccount' })
    }
  }
}
</script>
