<template>
  <div>
    <v-divider></v-divider>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ editedItem.firstname + ' ' + editedItem.lastname }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save()">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="editedItem.firstname" label="Vorname" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="editedItem.lastname" label="Nachname" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-text-field v-model="editedItem.userName" label="Username" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-autocomplete :items="[{ short: 'M', long: 'Male' }, { short: 'F', long: 'Female' }]"
                  v-model="editedItem.gender" item-text="long" item-value="short" label="Gender" required>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-text-field v-model="editedItem.birthday" label="Geburtstag" type="date" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="editedItem.mail" label="E-Mail" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.tel" type="text" label="Tel" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.tel2" type="text" label="Tel 2" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.country" label="Land" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="2">
                <v-text-field v-model="editedItem.town" label="Stadt" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="1">
                <v-text-field v-model="editedItem.zip" type="number" label="PLZ" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="3">
                <v-text-field v-model="editedItem.street" label="Straße" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-text-field v-model="editedItem.houseNo" label="Nr." required></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field v-model="editedItem.club" label="Club" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-autocomplete :items="$store.state.MembershipTypeList" v-model="editedItem.membership"
                  item-text="title" item-value="typeID" label="Mitglied" required></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="2">
                <v-text-field v-model="editedItem.position" label="Position"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.accountingName" label="accountingName"></v-text-field>
              </v-col>
              <v-col cols="12" sm="9" md="2">
                <v-text-field v-model="editedItem.iban" label="IBAN"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="1">
                <v-text-field v-model="editedItem.bic" label="BIC"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <v-text-field v-model="editedItem.bankName" label="bankName"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.bankOffset" type="Number" step="0.01" label="bankOffset"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.bankMinimum" type="Number" step="0.01" label="Minimum"></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="2">
                <v-text-field v-model="editedItem.mandateReference" label="mandateReference"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.mandateDate" label="mandateDate" type="date"></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="3">
                <v-text-field v-model="editedItem.bankComment" label="bankComment"></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="2">
                <v-text-field v-model="editedItem.bankAccountOwner" label="Konto Inhaber"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="1">
                <v-text-field v-model="editedItem.joinDate" label="Beigetreten am" type="date"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="1">
                <v-checkbox v-model="editedItem.loginActive" :true-value="1" :false-value="0" label="Login Aktiv?"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" md="1">
                <v-checkbox v-model="editedItem.passwordReset" :true-value="1" :false-value="0" label="Password Reset"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" md="1">
                <v-checkbox v-model="editedItem.reducedRate" :true-value="1" :false-value="0" label="Verminderter Betrag"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" md="1">
                <v-checkbox v-model="editedItem.initMail" :true-value="1" :false-value="0" label="Einladungsmail versenden?"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field v-model="editedItem.lastLog" label="Letzter Login" disabled type="datetime">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.role" label="ROLE" type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.vf_uid" label="VF-ID"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.vf_status" label="VF-STATUS" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.membershipFee1" label="membershipFee1"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.membershipFee1Date" label="membershipFee1Date" type="date">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.membershipFee2" label="membershipFee2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field v-model="editedItem.membershipFee2Date" label="membershipFee2Date" type="date">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <div style="flex: 1 1 auto;"></div>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-top-transition" max-width="1000" v-model="invoiceDialog">
      <v-card>
        <v-toolbar color="red" dark>Rechnung erstellen</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-textarea v-model="invoiceMailText"></v-textarea>
            </v-row>
            <v-row v-for="member in selectedMembers" :key="member.userID">
              <v-col cols="9" md="9">
                <h3>{{member.userName}}</h3>
              </v-col>
              <v-col cols="3" md="3">
                <h3>{{member.balance}}€</h3>
              </v-col>
            </v-row>
          </v-container>
          <v-row style="margin-bottom: 5px;">
            <v-spacer></v-spacer>
            <v-btn color="red" outlined @click="sendInvoice" :disabled="invoiceLoading">
              <v-icon left>fa-file-invoice-dollar</v-icon>
              Rechnung senden
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="closeInvoice">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row style="margin-top: 1px">
        <v-btn color="success" dark class="mb-2" @click="dialog=true; newUser=true">
                Neu
        </v-btn>
        <v-spacer></v-spacer>
        <div v-if="$store.state.AdminMemberDataLoadTime != -1">Daten zuletzt aktualisiert vor: &nbsp;<strong>{{ msToTime(timeSinceReload) }}</strong></div>
        <v-spacer></v-spacer>
        <v-btn color="warning" dark class="mb-2" @click="loadMemberData()">
              <v-icon>mdi-reload</v-icon>&nbsp;Daten aktualisieren
        </v-btn>
      </v-row>
      <v-text-field v-model="search" label="SUCHEN (nur in eingeblendeten Kategorien)"></v-text-field>
      <v-row>
        <v-col cols="3" md="3">
          <v-checkbox v-model="showPersonalHeaders" label="Persönliche Daten" color="green" hide-details></v-checkbox>
        </v-col>
        <v-col cols="3" md="3">
          <v-checkbox v-model="showGeneralHeaders" label="Generelle Daten" color="primary" hide-details></v-checkbox>
        </v-col>
        <v-col cols="3" md="3">
          <v-checkbox v-model="showAccountingHeaders" label="Abrechnungs Daten" color="red" hide-details></v-checkbox>
        </v-col>
        <v-col cols="3" md="3">
          <v-checkbox v-model="showBalanceHeaders" label="Balance" color="orange" hide-details></v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <p v-if="computedTotalMemberCost != null">Gesamt ({{ selectedMembers.length }}): {{ computedTotalMemberCost.toFixed(2) }} €
      <v-btn color="red" outlined @click="invoiceDialog = true">
              <v-icon left>fa-file-invoice-dollar</v-icon>
              Rechnung erstellen
            </v-btn>
          </p>
    <v-data-table :items="computedMembers" :headers="computedHeaders" :search="search"   @dblclick:row="handleClick" v-model="selectedMembers" show-select item-key="userID"
      no-data-text="Keine Mitglieder vorhanden...">
      <template v-if="showBalanceHeaders" v-slot:item.balance="{ item }">
        <strong  :class="classColor(item.balance) + '--text'">{{item.balance}}</strong>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="editItem(item)">fa-pen</v-icon>
      </template>
      <template v-slot:item.loginActive="{ item }">
        {{ item.loginActive === 1 ? 'ACTIVE' : 'SUSPEND' }}
      </template>
      <template v-slot:item.reducedRate="{ item }">
        {{ item.reducedRate === 1 ? 'REDUZIERT' : 'VOLL' }}
      </template>
      <template v-slot:item.passwordReset="{ item }">
        {{ item.passwordReset === 1 ? 'RESET' : 'NO' }}
      </template>
      <template v-slot:item.birthday="{ item }">
        {{ formatDate(item.birthday) }}
      </template>
      <template v-slot:item.mandateDate="{ item }">
        {{ formatDate(item.mandateDate) }}
      </template>
      <template v-slot:item.iban="{ item }">
        {{ formatIban(item.iban) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'AdminMembers',
  data: () => ({
    search: '',
    dialog: false,
    newUser: false,
    editedItem: {
      iban: ''
    },
    selectedMembers: [],
    timeSinceReload: 0,
    dataLoading: false,
    interval: [],
    invoiceMailText: '',
    invoiceDialog: false,
    invoiceLoading: false,
    menu: false,
    activePicker: null,
    showPersonalHeaders: true,
    showGeneralHeaders: false,
    showAccountingHeaders: false,
    showBalanceHeaders: false,
    balanceHeaders: [
      { text: 'Balance', value: 'balance' }
    ],
    staticHeaders: [
      { text: '', value: 'actions' },
      { text: 'ID', value: 'userID' },
      { text: 'Name', value: 'name' },
      { text: 'UNamer', value: 'userName' },
      { text: 'E-Mail', value: 'mail' }
    ],
    personalHeaders: [
      { text: 'Tel', value: 'tel' },
      { text: 'Tel2', value: 'tel2' },
      { text: 'Adresse', value: 'adress' },
      { text: 'Geburtstag', value: 'birthday' },
      { text: 'Gender', value: 'gender' }
    ],
    generalHeaders: [
      { text: 'Position', value: 'position' },
      { text: 'Club', value: 'club' },
      { text: 'Status', value: 'membershipTitle' },
      { text: 'Beitritt', value: 'joinDate' },
      { text: 'Login', value: 'loginActive' },
      { text: 'role', value: 'role' },
      { text: 'PW Reset', value: 'passwordReset' },
      { text: 'last Login', value: 'lastLog' },
      { text: 'VF-ID', value: 'vf_uid' },
      { text: 'VF-Status', value: 'vf_status' }
    ],
    accountingHeaders: [
      { text: 'Abrechnungs Name', value: 'accountingName' },
      { text: 'Vermindert', value: 'reducedRate' },
      { text: 'MB 1', value: 'membershipFee1' },
      { text: 'MB 1 Datum', value: 'membershipFee1Date' },
      { text: 'MB 2', value: 'membershipFee2' },
      { text: 'MB 2 Datum', value: 'membershipFee2Date' },
      { text: 'Bank Offset', value: 'bankOffset' },
      { text: 'Minimum', value: 'bankMinimum' },
      { text: 'Bank Name', value: 'bankName' },
      { text: 'Bank Kommentar', value: 'bankComment' },
      { text: 'Kontoinhaber alt.', value: 'bankAccountOwner' },
      { text: 'BIC', value: 'bic' },
      { text: 'IBAN', value: 'iban' },
      { text: 'Mandat Referenz', value: 'mandateReference' },
      { text: 'Mandat Datum', value: 'mandateDate' }
    ]
  }),
  computed: {
    computedMembers () {
      const data = []
      this.computedMemberData.forEach(element => {
        // STATIC DATA
        element.name = element.firstname + ' ' + element.lastname
        // PERSONAL DATA
        element.adress = element.street + ' ' + element.houseNo + ' | ' + element.zip + ' ' + element.town + ' | ' + element.country
        element.membership = parseInt(element.membership)
        // GENERAL DATA
        element.membershipTitle = this.$store.state.MembershipTypeList.filter(e => e.typeID === element.membership)[0].title
        //  element.loginActive = element.loginActive === 1 ? 'ACTIVE' : 'SUSPEND'
        element.lastLog = this.formatTimestamp(element.lastLog)
        // ACCOUNTING DATA

        data.push(element)
      })
      return data
    },
    computedHeaders () {
      const headers = []
      this.staticHeaders.forEach(element => {
        headers.push(element)
      })

      if (this.showPersonalHeaders) {
        this.personalHeaders.forEach(element => {
          headers.push(element)
        })
      }
      if (this.showGeneralHeaders) {
        this.generalHeaders.forEach(element => {
          headers.push(element)
        })
      }
      if (this.showAccountingHeaders) {
        this.accountingHeaders.forEach(element => {
          headers.push(element)
        })
      }
      if (this.showBalanceHeaders) {
        this.balanceHeaders.forEach(element => {
          headers.push(element)
        })
      }
      return headers
    },
    computedTotalMemberCost () {
      var price = null
      if (this.selectedMembers.length > 0) {
        this.selectedMembers.forEach(element => {
          price += parseFloat(element.balance)
        })
      }
      return price
    },
    computedMemberData () {
      return this.$store.state.AdminMemberData
    }
  },
  watch: {
    editedItem: {
      handler: function (newVal, oldVal) {
        console.log(newVal.iban)
        this.editedItem.iban = this.formatIban(newVal.iban)
      },
      deep: true
    }
  },
  methods: {
    formatIban (iban) {
      if (iban == null) return iban
      return iban.replace(/\s/g, '').replace(/(.{4})/g, '$1 ').trim()
    },
    editItem (item) {
      this.editedItem = item
      this.dialog = true
    },
    sendInvoice () {
      this.invoiceLoading = true
      var invoiceData = []
      invoiceData.userID = -3
      invoiceData.ids = ''
      this.selectedMembers.forEach(element => {
        invoiceData.ids += element.userID + ','
      })
      invoiceData.ids = invoiceData.ids.slice(0, -1)
      invoiceData.mailText = this.invoiceMailText
      this.$store.dispatch('sendInvoiceToMultipleUsers', invoiceData).then(response => {
        if (parseInt(response.data.noErrors) === 0) {
          this.downloadXML(moment().format('YYYYMMDD_HHmmss') + '_fcbbSepa.xml', response.data.result.SEPA)
          this.closeInvoice()
        } else {
          this.invoiceLoading = false
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    downloadXML (filename, string) {
      var pom = document.createElement('a')
      var bb = new Blob([string], { type: 'text/plain' })
      pom.setAttribute('href', window.URL.createObjectURL(bb))
      pom.setAttribute('download', filename)
      pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':')
      pom.draggable = true
      pom.classList.add('dragout')
      pom.click()
    },
    save () {
      if (this.editedItem.iban != null) {
        this.editedItem.iban = this.editedItem.iban.replace(/\s/g, '')
      }
      if (this.newUser) {
        // add
        this.$store.dispatch('validateUser', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Add USER
            this.$store.dispatch('addUser', this.editedItem).then(response => {
              if (parseInt(response.data.noErrors) === 0) {
                this.$store.dispatch('loadPilotList')
                this.close()
              } else {
                this.$store.commit('setApiErrorResponse', response.data)
              }
            })
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      } else {
        // validate USER
        // Edit User

        this.$store.dispatch('validateUser', this.editedItem).then(response => {
          //  console.log(response)
          if (response.data.noErrors === 0) {
            // Update USER
            this.$store.dispatch('updateUser', this.editedItem).then(response => {
              if (parseInt(response.data.noErrors) === 0) {
                this.$store.dispatch('loadPilotList')
                this.close()
              } else {
                this.$store.commit('setApiErrorResponse', response.data)
              }
            })
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = []
        this.newUser = false
      })
    },
    closeInvoice () {
      this.invoiceDialog = false
      this.$nextTick(() => {
        this.invoiceLoading = false
        this.selectedMembers = []
      })
    },
    classColor (value) {
      if (parseFloat(value) < 0) {
        return 'red'
      }

      return 'green'
    },
    saveBirthDate (date) {
      this.$refs.menu.save(date)
    },
    async loadMemberData () {
      this.dataLoading = true
      await axios.get(process.env.VUE_APP_API_PATH_ADMIN_CLUBMEMBERS + '?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password, { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } }).then(response => {
        this.$store.commit('saveAdminMemberData', response.data.result)
        this.dataLoading = false
      })
    },
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    formatTimestamp (timestamp) {
      return moment(timestamp).format('DD.MM.YYYY HH:mm:ss')
    },
    handleClick (event, value) {
      const routeData = this.$router.resolve({ name: 'adminUserProfile', params: { id: value.item.userID } })
      window.open(routeData.href, '_blank')
    },
    msToTime (s) {
      var ms = s % 1000
      s = (s - ms) / 1000
      var secs = s % 60
      s = (s - secs) / 60
      var mins = s % 60

      if (mins === 0) {
        return secs + 's'
      } else {
        return mins + 'min ' + secs + 's'
      }
    }
  },
  mounted () {
    if (!this.computedMemberData.length > 0) {
      this.loadMemberData()
    }
    this.invoiceMailText = process.env.VUE_APP_INVOICE_DEFAULT_TEXT
    var that = this
    this.interval = setInterval(function () {
      that.timeSinceReload = Date.now() - that.$store.state.AdminMemberDataLoadTime
      if (that.timeSinceReload > 300000 && !that.dataLoading) { // 300000 = 5 Min
        that.loadMemberData() // Wenn zeit x in (ms) rum, lade daten neu
      }
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
