<template>
<v-container fluid>
  <CURDTable tableId="tagsTable" title="NFC/RFID-Tags" newItemText="Hinzufügen"
              editItemText="Bearbeiten" :dataSchema="dataSchemaTags" loadDataFunction="loadAllTags"
              insertDataFunction="insertTag" updateDataFunction="updateTag" removeDataFunction="deleteTag"
              storeData="AdminAllTags" showNumberOfItems showSearch update remove insert></CURDTable>
</v-container>
</template>

<script>
import CURDTable from '../../components/curdTable.vue'
export default {
  name: 'AdminTags',
  data: () => ({
    dataSchemaTags: [
      { dataId: 'tagID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'userID', type: 'autocomplete', text: 'Mitglied', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'uuid', type: 'text', text: 'UUID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'lastUseDate', type: 'datetime', text: 'zuletzt benutzt am', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDateTime' },
      { dataId: 'lastUseStation', type: 'text', text: 'letzte Station', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'tagType', type: 'autocomplete', text: 'Typ', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'TagTypeList', autocompleteValue: 'typeID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' }
    ]
  }),
  components: {
    CURDTable
  }

}
</script>
