import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import AxiosPlugin from 'vue-axios-cors'
import VueCookies from 'vue-cookies'

export const bus = new Vue()

Vue.config.productionTip = false

Vue.use(VueCookies)
if (process.env.VUE_APP_ENV === 'production') {
  Vue.$cookies.config('8h', '/', '.fcbb.de') // Set cookie expire global SSO CONF
} else {
  Vue.$cookies.config('8h') // Set cookie expire global DEV CONF
}
Vue.use(AxiosPlugin)
Vue.use(DatetimePicker)

new Vue({
  store,
  vuetify,
  beforeCreate () {
    this.$store.commit('initialiseStore')
    this.$store.dispatch('initialiseAction')
  },
  router,
  render: h => h(App)
}).$mount('#app')
