<template>
<v-container fluid>
    <curdTable
            tableId="mailinglistTable"
            title="Verteiler"
            newItemText="Neuer Verteiler"
            editItemText="Verteiler bearbeiten"
            :dataSchema="dataSchemaMailingList"
            loadDataFunction="loadMailingList"
            insertDataFunction="createMailingList"
            removeDataFunction="deleteMailingList"
            updateDataFunction="updateMailAttachedUsers"
            storeData="MailingLists"
            insert
            update
            remove
            showNumberOfItems
></curdTable>
</v-container>
</template>

<script>
import curdTable from '../components/curdTable.vue'
export default {
  name: 'MailingList',
  data: () => ({
    dataSchemaMailingList: [
      { dataId: 'id', type: 'text', text: '#ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'title', type: 'text', text: 'Titel', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'email', type: 'email', text: 'Adresse', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'sender', type: 'text', text: 'Sender', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'dmz', type: 'checkbox', text: 'Extern?', showInTable: true, defaultValue: 0, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'intToYesNo' },
      { dataId: 'users', type: 'autocomplete', autocompleteMultiple: true, text: 'Read - Write', defaultValue: '', showInTable: false, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname' },
      { dataId: 'usersWO', type: 'autocomplete', autocompleteMultiple: true, text: 'Read - Only', defaultValue: '', showInTable: false, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname' }
    ]
  }),
  components: {
    curdTable
  }
}
</script>
