<template>
<v-container fluid>
  <CURDTable tableId="contractTable" title="Verträge" newItemText="Hinzufügen"
              editItemText="Bearbeiten" :dataSchema="dataSchemaContracts" loadDataFunction="loadAllContracts"
              insertDataFunction="insertContract" updateDataFunction="updateContract" removeDataFunction="deleteContract"
              storeData="AdminContracts" showNumberOfItems showSearch update remove insert dialogWidth="1000px"></CURDTable>
  <CURDTable tableId="contractDocumentTable" title="Vertragsdokumente" newItemText="Hinzufügen"
              editItemText="Bearbeiten" :dataSchema="dataSchemaContractDocuments" loadDataFunction="loadAllContractDocuments"
              insertDataFunction="insertContractDocument" updateDataFunction="updateContractDocument" removeDataFunction="deleteContractDocument"
              storeData="AdminContractDocuments" showNumberOfItems showSearch update remove insert></CURDTable>
  <CURDTable tableId="contractPaymentTable" title="Vertragszahlungen" newItemText="Hinzufügen"
              editItemText="Bearbeiten" :dataSchema="dataSchemaContractPayments" loadDataFunction="loadAllContractPayments"
              insertDataFunction="insertContractPayment" updateDataFunction="updateContractPayment" removeDataFunction="deleteContractPayment"
              storeData="AdminContractPayments" showNumberOfItems showSearch update remove insert></CURDTable>
</v-container>
</template>

<script>
import CURDTable from '../../components/curdTable.vue'
export default {
  name: 'AdminContracts',
  data: () => ({
    dataSchemaContracts: [
      { dataId: 'contractID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'contractType', type: 'text', text: 'Typ', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'contractName', type: 'text', text: 'Titel', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'annualAmount', type: 'float', steps: 0.01, text: 'Jährl. Betrag', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 2, showInInputDialog: true },
      { dataId: 'paymentMethod', type: 'autocomplete', text: 'Zahlart', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true, autocompleteItems: 'AdminContractPaymentMethods', autocompleteValue: 'paymentMethodID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'IBAN', type: 'text', text: 'IBAN', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'BIC', type: 'text', text: 'BIC', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 3, showInInputDialog: true },
      { dataId: 'customerReference', type: 'text', text: 'Kunden Ref', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 2, showInInputDialog: true },
      { dataId: 'bookingReference', type: 'text', text: 'Buchungs Ref', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 2, showInInputDialog: true },
      { dataId: 'contractReference', type: 'text', text: 'Vertrags Ref', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 2, showInInputDialog: true },
      { dataId: 'comment', type: 'text', text: 'Kommentar', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 10, showInInputDialog: true },
      { dataId: 'effectiveSince', type: 'date', text: 'Ab', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 2, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'effectiveUntil', type: 'date', text: 'Bis', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 2, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'companyName', type: 'text', text: 'Firma', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 8, showInInputDialog: true },
      { dataId: 'accountManagerName', type: 'text', text: 'Manager', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'accountManagerMail', type: 'text', text: 'Mail', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true },
      { dataId: 'accountManagerPhone', type: 'text', text: 'Tel', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 4, showInInputDialog: true }
    ],
    dataSchemaContractDocuments: [
      { dataId: 'contractDocID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'contractID', type: 'autocomplete', text: 'Vertrag', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'AdminContracts', autocompleteValue: 'contractID', autocompleteText: 'contractName', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'category', type: 'text', text: 'Kategorie', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDateTime' },
      { dataId: 'path', type: 'text', text: 'Pfad', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'filename', type: 'text', text: 'Datei', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'comment', type: 'text', text: 'Kommentar', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'uploadDate', type: 'date', text: 'hochgeladen am', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'creatorID', type: 'autocomplete', text: 'erstellt von', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'current', type: 'checkbox', text: 'Gültig', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'TagTypeList', autocompleteValue: 'typeID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'files', type: 'file', text: 'Download', customInputLabel: 'Datei', showInTable: true, defaultValue: [], sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'stringToDownloadButton', downloadId: 'documentID', downloadPath: process.env.VUE_APP_API_PATH_ADMIN_CONTRACT_DOCUMENT_DOWNLOAD }
    ],
    dataSchemaContractPayments: [
      { dataId: 'paymentID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'contractID', type: 'autocomplete', text: 'Vertrag', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'AdminContracts', autocompleteValue: 'contractID', autocompleteText: 'contractName', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'amount', type: 'float', steps: 0.01, text: 'Betrag', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'date', type: 'date', text: 'Datum', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'recurring', type: 'checkbox', text: 'Wiederkehrend?', defaultValue: 0, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true }
    ]
  }),
  components: {
    CURDTable
  }

}
</script>
