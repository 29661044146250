<template>
<div>  <AdminNavigation></AdminNavigation>
<v-container fluid>
<router-view></router-view>
</v-container>
</div>
</template>

<script>
import AdminNavigation from '../components/admin/topbar.vue'
export default {
  name: 'ClubManagement',
  components: {
    AdminNavigation
  }
}
</script>
