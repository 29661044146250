<template>
<div>
  <v-row v-if="$store.state.User.userID != -1">
          <v-col cols="12">
           <iframe :src="iframeLink" frameBorder="0" id="myiframe" :style="`min-height: ${iframeHeight}px; width: inherit;`"></iframe>
          </v-col>
   </v-row>
</div>
  </template>
<script>
export default {
  name: 'UserFlightPrivileges',
  data: () => ({
  }),
  props: {
    customUserID: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    iframeLink () {
      return 'https://api.fcbb.de/user/getUserFlightPrivileges.php?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password + (this.customUserID !== null ? '&userID=' + this.customUserID : '')
    },
    iframeHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 800
        case 'sm': return 800
        case 'md': return 400
        case 'lg': return 400
        case 'xl': return 400
      }
      return 400
    }
  }
}
</script>
