<template>
  <v-app>
    <Navigation v-if="currentRouteName != 'login'"></Navigation>

    <TopBar v-if="currentRouteName != 'login'"></TopBar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <ErrorDialog></ErrorDialog>
  </v-app>
</template>
<style>
</style>
<script>
import Navigation from './components/Navigation.vue'
import TopBar from './components/TopBar.vue'
import ErrorDialog from './components/ErrorDialog.vue'
export default {
  name: 'App',

  data: () => ({
    noDataCouter: 0
  }),
  components: {
    TopBar,
    Navigation,
    ErrorDialog
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    startTimer () {
      var that = this
      setInterval(function () {
        if (Object.keys(that.$store.state.UserInfo).length === 0 && that.currentRouteName !== 'login') {
          console.log('FE logged in but no UserData found! Logout and redirect to login')
          that.$store.dispatch('logoutBackend') //  Logout User
          location.reload(true) // reload page, force no cache to get newest version
        }
      }, 3000)
    }
  },
  mounted () {

  }
}
</script>
