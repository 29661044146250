<template>
    <v-toolbar dense v-if="$store.state.UserInfo.adm === 1">
      <v-slide-group
      multiple
      show-arrows
    >
      <v-slide-item
        v-for="(item, index) in navItems"
        :key="index"
        v-slot="{ active, toggle }"
      >
      <v-btn  :to="{ name: item.child}" text @click="toggle" :input-value="active">
        {{ item.title }}
      </v-btn>
      </v-slide-item>
    </v-slide-group>

    </v-toolbar>
</template>
<script>
export default {
  data: () => ({
    navItems: [
      { title: 'Mitglieder', child: 'adminMembers' },
      { title: 'Flugfreigaben', child: 'adminlicenses' },
      { title: 'Mailinglists', child: 'adminMailing' },
      { title: 'Gutscheine', child: 'adminCoupons' },
      { title: 'Auslagen/Rechnungen', child: 'adminPayback' },
      { title: 'Transaktionen', child: 'adminTransactions' },
      { title: 'Mitglieder Einzüge', child: 'adminInvoice' },
      { title: 'Kostenstellen', child: 'adminCostCenters' },
      { title: 'Buchhaltung', child: 'adminAccounting' },
      { title: 'Verträge', child: 'adminContracts' },
      { title: 'RFID/NFC-Tags', child: 'adminTags' },
      { title: 'Experten', child: 'adminExpertView' }
    ]
  })
}
</script>
