<template>
<v-container fluid>
<v-divider></v-divider>
<PaybackCheck></PaybackCheck>
</v-container>
</template>

<script>
import PaybackCheck from '../components/PaybackCheck.vue'
export default {
  name: 'Management',
  components: {
    PaybackCheck
  }
}
</script>
