import Vue from 'vue'
import VueRouter from 'vue-router'
import Account from '../views/Account.vue'
import Bill from '../views/Bill.vue'
import Payback from '../views/Payback.vue'
import LoginPage from '../views/Login.vue'
import Downloads from '../views/Downloads.vue'
import store from '../store'
import Management from '../views/Management.vue'
import FlightLists from '../views/FlightLists.vue'
import Survey from '../views/Survey.vue'
import Statistic from '../views/Statistics.vue'
import ClubManagement from '../views/ClubManagement.vue'
import AdminMailinglist from '../views/Mailinglist.vue'
import AdminPayback from '../views/admin/payback.vue'
import AdminMembers from '../views/admin/members.vue'
import AdminUserProfile from '../views/admin/userProfile.vue'
import PlaneMaintenance from '../views/PlaneMaintenance.vue'
import LicenseView from '../views/LicenseView.vue'
import DocumentView from '../views/UserDocuments.vue'
import AdminTransactions from '../views/admin/transactions.vue'
import AdminCostCenters from '../views/admin/costcenters.vue'
import AdminCostCentersInfo from '../views/admin/costcentersinfo.vue'
import Adminlicenses from '../views/admin/licenses.vue'
import AdminCoupons from '../views/admin/coupons.vue'
import AdminInvoice from '../views/admin/invoice.vue'
import AdminAccounting from '../views/admin/accounting.vue'
import AdminTags from '../views/admin/tags.vue'
import TaskView from '../views/TaskView.vue'
import AssignTag from '../views/AssignTag.vue'
import AdminContracts from '../views/admin/contracts.vue'
import AdminExpertView from '../views/admin/expert.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { name: 'myAccount' } },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/myAccount',
    name: 'myAccount',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/myBills',
    name: 'myBills',
    component: Bill,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payback',
    name: 'createPayback',
    component: Payback,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: Downloads,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/statistic',
    name: 'statistic',
    component: Statistic,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/management',
    name: 'management',
    component: Management,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/startlisten',
    name: 'flightlists',
    component: FlightLists,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/survey',
    name: 'survey',
    component: Survey,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TaskView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/assignTag/:uuid',
    name: 'assignTag',
    component: AssignTag,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/club',
    name: 'clubManagement',
    component: ClubManagement,
    children: [
      {
        path: 'payback',
        name: 'adminPayback',
        component: AdminPayback
      },
      {
        path: 'members',
        name: 'adminMembers',
        component: AdminMembers
      },
      {
        path: 'members/:id',
        name: 'adminUserProfile',
        component: AdminUserProfile
      },
      {
        path: 'mailinglists',
        name: 'adminMailing',
        component: AdminMailinglist
      },
      {
        path: 'transactions',
        name: 'adminTransactions',
        component: AdminTransactions
      },
      {
        path: 'costcenters',
        name: 'adminCostCenters',
        component: AdminCostCenters
      },
      {
        path: 'costcenters/:id/info',
        name: 'adminCostCentersInfo',
        component: AdminCostCentersInfo
      },
      {
        path: 'coupons',
        name: 'adminCoupons',
        component: AdminCoupons
      },
      {
        path: 'licenses',
        name: 'adminlicenses',
        component: Adminlicenses
      },
      {
        path: 'invoice',
        name: 'adminInvoice',
        component: AdminInvoice
      },
      {
        path: 'accounting',
        name: 'adminAccounting',
        component: AdminAccounting
      },
      {
        path: 'tags',
        name: 'adminTags',
        component: AdminTags
      },
      {
        path: 'contracts',
        name: 'adminContracts',
        component: AdminContracts
      },
      {
        path: 'expert',
        name: 'adminExpertView',
        component: AdminExpertView
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/planes',
    name: 'planeMaintenance',
    component: PlaneMaintenance,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Licenses',
    name: 'LicenseManagement',
    component: LicenseView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Documents',
    name: 'myDocuments',
    component: DocumentView,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    await store.dispatch('checkToken', store.state.User)
    if (store.getters.loggedIn) {
      next()
      return
    }
    next({ name: 'login', query: { redirect: to.path } })
  } else {
    next()
  }
})

export default router
