<template>
  <v-app-bar clipped-left app dense style="background: rgb(2,0,36);background: linear-gradient(45deg, rgb(2, 0, 36) 94%, rgb(24 202 220) 94%);">
    <span class="white--text hidden-lg-and-up">
      <v-app-bar-nav-icon style="color: white;" @click="$root.$emit('toggleNavBarButton')"></v-app-bar-nav-icon>
    </span>
    <span class="white--text">
      <h2 class="hidden-sm-and-down">GlideStone User Interface</h2>
      <h2 class="hidden-md-and-up">GS IU</h2>
    </span>
    <span class="white--text" >
      <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <h6 v-on="on" v-bind="attrs" style="position: absolute;bottom: 0;">{{ getCurrentVersion() }}</h6>
      </template>
      <span>{{getCurrentDeployDate()}}</span>
    </v-tooltip>
    </span>
    <v-spacer></v-spacer>
    <v-btn icon color="white" @click="changeDarkMode"><v-icon>fa-lightbulb</v-icon></v-btn>
    <v-spacer></v-spacer>
    <v-btn icon color="red" @click="logoutCurrentUser">
      <v-icon>fa-sign-out-alt</v-icon>
    </v-btn>
  </v-app-bar>
</template>
<style>
.custom {
  transform: translateY(35px) !important;
}
</style>
<script>

export default {
  name: 'TopBar',
  data: () => ({
  }),
  methods: {
    logoutCurrentUser: function () {
      this.$store.dispatch('logoutBackend')
      window.location.href = 'https://fcbb.de'
    },
    changeDarkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    getCurrentVersion () {
      return process.env.VUE_APP_VERSION
    },
    getCurrentDeployDate () {
      return process.env.VUE_APP_DEPLOY_TIME
    }
  },
  mounted () {
  }
}
</script>
