<template>
  <v-container fluid>
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="dialog" @click:outside="closeEditDialog"
      v-if="checkItem != null">
      <v-card>
        <v-toolbar color="orange" dark>Rückzahlung Bearbeiten</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field v-model="checkItem.amount" type="number" step="0.01" label="Betrag in €" />
              </v-col>
            </v-row><v-row>
              <v-col cols="12" md="12">
                <v-text-field v-model="checkItem.billingDate" type="date" label="Rechnungsdatum" />
              </v-col>
            </v-row><v-row>
              <v-col cols="12" md="12">
                <v-select :items="computedCategoryList" v-model="checkItem.category" item-text="title"
                  item-value="typeID" label="Art der Rechnung" dense outlined></v-select>
              </v-col>
            </v-row><v-row>
              <v-col cols="12" md="12">
                <v-select :items="$store.state.PaybackTypeList" v-model="checkItem.paybackType" item-text="title"
                  item-value="categoryID" label="Art der Rückzahlung" dense outlined></v-select>
              </v-col>
            </v-row><v-row>
              <v-col cols="12" md="12">
                <v-autocomplete v-model="checkItem.paybackTo" :items="computedPaybackTo" dense outlined
                  :disabled="checkItem.paybackType === 5" item-value="userID" item-text="fullname"
                  label="Rückzahlung an">
                </v-autocomplete>
              </v-col>
            </v-row><v-row>
              <v-col cols="12" md="8">
                <v-text-field v-model="checkItem.iban" label="IBAN" />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="checkItem.bic" label="BIC" />
              </v-col>
            </v-row><v-row>
              <v-col cols="12" md="8">
                <v-text-field v-model="checkItem.partnerName" label="Partner Name" />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="checkItem.invoiceNo" label="Rechnungs Nr" />
              </v-col>
            </v-row><v-row>
              <v-col cols="12" md="12">
                <v-text-field v-model="checkItem.customerRef" label="Kundenref." />
              </v-col>
            </v-row><v-row>
              <v-col cols="12" md="12">
                <v-autocomplete v-model="checkItem.costCenter" :items="$store.state.CostCenterList" dense outlined
                  item-value="costCenterID" item-text="name" label="Kostenstelle">
                </v-autocomplete>
              </v-col>
            </v-row><v-row>
              <v-col cols="12">
                <v-textarea filled v-model="checkItem.comment" label="Kommentar" rows="2" auto-grow></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-row style="margin-bottom: 5px;">
            <v-spacer></v-spacer>
            <v-btn color="primary" :href="fileDownloadLink" download target="_blank"><v-icon
                left>fa-angle-double-down</v-icon>Download<v-icon right>fa-angle-double-down</v-icon></v-btn>
            <v-spacer></v-spacer>
          </v-row>
          <v-row style="margin-bottom: 5px;">
            <v-spacer></v-spacer>
            <v-btn color="red" outlined @click="submitApproval(-1)">
              <v-icon left>fa-ban</v-icon>
              Ablehnen
            </v-btn>
            <v-btn color="orange" outlined @click="submitApproval(0)">
              <v-icon left>fa-undo</v-icon>
              in Bearbeitung
            </v-btn>
            <v-btn color="success" outlined @click="submitApproval(1)">
              Genehmigt
              <v-icon right>fa-check</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
          <v-btn color="grey" style="width:100%" outlined @click="submitApproval(2)">
            Vorgang abgeschlossen
          </v-btn>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="success" @click="saveEditDialog">Speichern</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="closeEditDialog">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="userAccountingInformationDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Kontoinformationen
        </v-card-title>

        <v-card-text v-if="userAccountingInformation != null">
        IBAN: {{ userAccountingInformation.iban }}<br>
        BIC: {{ userAccountingInformation.bic }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="userAccountingInformationDialog = false; userAccountingInformation = null"
          >
            Fertig
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-autocomplete v-model="statusFilter" :items="statusList" item-text="text" item-value="id" multiple label="Status Filter"></v-autocomplete>
    <v-text-field v-model="search" label="Suchen" class="mx-4" placeholder="Datum: YYYY-MM-DD, Kommentar, Nutzer ID, Status ID"></v-text-field>
    <v-switch v-model="extendedHeaders" label="Kontoinformationen einblenden"></v-switch>
    <p v-if="computedPaybackCost != null">Gesamt ({{ selectedPaybacks.length }}): {{ computedPaybackCost.toFixed(2) }} €
      <v-btn color="red" outlined @click="submitMultipleApproval(-1)">
              <v-icon left>fa-ban</v-icon>
              Alle markierten (Ablehnen)
            </v-btn>
            <v-btn color="orange" outlined @click="submitMultipleApproval(0)">
              <v-icon left>fa-undo</v-icon>
              Alle markierten (in Bearbeitung)
            </v-btn>
            <v-btn color="success" outlined @click="submitMultipleApproval(1)">
              Alle markierten (Genehmigt)
              <v-icon right>fa-check</v-icon>
            </v-btn>
            <v-btn color="grey" outlined @click="submitMultipleApproval(2)">
            Alle markierten (Vorgang abgeschlossen)
          </v-btn>
          </p>
    <v-data-table v-if="computedPilotList.length > 0 && computedCategoryList.length > 0" :items="computedPaybackList" :search="search"
      :headers="computedHeaders" sort-by="creationTime" :sort-desc="true" no-data-text="Keine Rückzahlungen vorhanden..." multi-sort show-select item-key="receiptID" v-model="selectedPaybacks">
      <template v-slot:item.creationTime="{ item }"><span>{{
        getDateFromTimestamp(item.creationTime)
      }}</span></template>
      <template v-slot:item.amount="{ item }"><span>{{
        item.amount
      }}€</span></template>
      <template v-slot:item.category="{ item }"><span>{{
        item.category != null ? computedCategoryList.filter(e =>
          e.typeID == item.category)[0].title : 'ERROR'
      }}</span></template>
      <template v-slot:item.paybackType="{ item }"><span>{{
        item.paybackType != null ?
          $store.state.PaybackTypeList.filter(e => e.categoryID == item.paybackType)[0].title : 'ERROR'
      }}</span></template>
      <template v-slot:item.paybackTo="{ item }"><span @click="showAccountingInformation(item.paybackTo)"><v-icon x-small>fa-user</v-icon> {{
        item.paybackTo != null ?
          $store.state.PilotList.filter(e => e.userID == item.paybackTo)[0].firstname + " " +
          $store.state.PilotList.filter(e => e.userID == item.paybackTo)[0].lastname : 'ERROR'
      }}</span></template>
      <template v-slot:item.approvedBy="{ item }"><span @click="showAccountingInformation(item.approvedBy)"><v-icon x-small>fa-user</v-icon> {{
        item.approvedBy != null ?
          $store.state.PilotList.filter(e => e.userID == item.approvedBy)[0].firstname + " " +
          $store.state.PilotList.filter(e => e.userID == item.approvedBy)[0].lastname : '---'
      }}</span></template>
      <template v-slot:item.approved="{ item }">
        <span>
          <v-chip v-if="item.approved == 0" color="orange" style="color: white"> In Bearbeitung</v-chip>
          <v-tooltip right v-else-if="item.approved == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" color="green" style="color: white">Genehmigt</v-chip>
            </template>
            <span>Genehmigt von {{
              item.approvedBy != null ? $store.state.PilotList.filter(e => e.userID ==
                item.approvedBy)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID ==
                  item.approvedBy)[0].lastname : 'unbekannt'
            }}</span>
          </v-tooltip>
          <v-tooltip right v-else-if="item.approved == -1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" color="red" style="color: white">Abgelehnt</v-chip>
            </template>
            <span>Abgelehnt von {{
              item.approvedBy != null ? $store.state.PilotList.filter(e => e.userID ==
                item.approvedBy)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID ==
                  item.approvedBy)[0].lastname : 'unbekannt'
            }}</span>
          </v-tooltip>
          <v-chip v-else-if="item.approved == 2" color="grey"
            style="color: white">Erledigt</v-chip>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="openEditDialog(item)"><v-icon>fa-pen</v-icon></v-btn>
      </template>
    </v-data-table>
    <CURDTable
            tableId="payback_category_table"
            title="Payback Category"
            newItemText="Neue Kategorie"
            :dataSchema="dataSchemaPaybackCategory"
            loadDataFunction="loadPaybackCategory"
            insertDataFunction="insertPaybackCategory"
            storeData="PaybackCategoryList"
            showNumberOfItems
            info
            insert
            ></CURDTable>
  </v-container>
</template>

<script>
import CURDTable from '../../components/curdTable.vue'
import axios from 'axios'
import moment from 'moment'
var qs = require('qs')
export default {
  name: 'Payback',
  data: () => ({
    loading: false,
    dialog: false,
    userAccountingInformationDialog: false,
    userAccountingInformation: null,
    extendedHeaders: false,
    search: '',
    statusFilter: [],
    selectedPaybacks: [],
    statusList: [
      { id: -1, text: 'Abgelehnt' },
      { id: 0, text: 'Offen/in Bearbeitung' },
      { id: 1, text: 'Genehmigt' },
      { id: 2, text: 'Erledigt' }
    ],
    headers: [
      { text: 'ID', value: 'receiptID' },
      { text: 'Status', value: 'approved' },
      { text: 'Datum', value: 'creationTime' },
      { text: 'Betrag', value: 'amount' },
      { text: 'Kategorie', value: 'category' },
      { text: 'Kostenstelle', value: 'costCenterName' },
      { text: 'Kommentar', value: 'comment' },
      { text: 'Rückzahlung', value: 'paybackType' },
      { text: 'An', value: 'paybackTo' },
      { text: 'Bearbeitet von', value: 'approvedBy' },
      { text: 'Aktionen', value: 'actions' }
    ],
    accountingHeaders: [
      { text: 'IBAN', value: 'iban' },
      { text: 'BIC', value: 'bic' },
      { text: 'Partner', value: 'partnerName' },
      { text: 'Kunden Nr.', value: 'customerRef' }
    ],
    checkItem: null,
    dataSchemaPaybackCategory: [
      { dataId: 'title', type: 'text', text: 'Titel', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'ideell', type: 'float', steps: 0.01, text: 'Ideell', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'vermoegensverwaltung', type: 'float', steps: 0.01, text: 'Vermögensverwaltung', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'zweckbetrieb', type: 'float', steps: 0.01, text: 'Zweckbetrieb', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'wirtschaftsbetrieb', type: 'float', steps: 0.01, text: 'Wirtschaftsbetrieb', showInTable: true, defaultValue: '', sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'comment', type: 'text', text: 'Kommentar', showInTable: true, defaultValue: 0, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true }
    ]
  }),
  computed: {
    computedHeaders () {
      if (this.extendedHeaders) {
        var headers = []
        this.headers.forEach(header => {
          headers.push(header)
        })
        this.accountingHeaders.forEach(header => {
          headers.push(header)
        })
        return headers
      }
      return this.headers
    },
    computedPilotList () {
      return this.$store.state.PilotList
    },
    computedPaybackTo () {
      if (this.checkItem.paybackType === 5) {
        return this.$store.state.PilotList.filter(e => e.userID === this.$store.state.User.userID)
      }
      return this.$store.state.PilotList
    },
    computedPaybackList () {
      var list = []

      if (this.statusFilter.length > 0) {
        this.statusFilter.forEach(status => {
          this.$store.state.AdminPaybackData.filter(e => e.approved === status).forEach(payback => {
            list.push(payback)
          })
        })
      } else {
        list = this.$store.state.AdminPaybackData
      }
      return list
    },
    computedCategoryList () {
      return this.$store.state.PaybackCategoryList
    },
    fileDownloadLink () {
      if (this.checkItem != null) {
        return 'https://' + this.$store.state.User.auth.username + ':' + this.$store.state.User.auth.password + '@' + process.env.VUE_APP_MAIN_API_DOMAIN + process.env.VUE_APP_API_PATH_DOWNLOAD_PAYBACK_PATH + '?id=' + this.checkItem.files + '&user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password
      }
      return ''
    },
    computedPaybackCost () {
      var price = null
      if (this.selectedPaybacks.length > 0) {
        this.selectedPaybacks.forEach(element => {
          price += parseFloat(element.amount)
        })
      }
      return price
    }
  },
  mounted () {
    axios.get(process.env.VUE_APP_API_PATH_ADMIN_CLUBMEMBERS + '?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password, { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } }).then(response => {
      this.$store.commit('saveAdminMemberData', response.data.result)
    })
    this.loadPaybackData()
  },
  components: {
    CURDTable
  },
  methods: {
    showAccountingInformation (userID) {
      this.userAccountingInformation = this.$store.state.AdminMemberData.filter(e => e.userID === userID)[0]
      this.userAccountingInformationDialog = true
    },
    getDateFromTimestamp (stamp) {
      return moment(stamp, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY')
    },
    submitMultipleApproval: function (approved) {
      if (confirm('Bist du sicher, dass du alle ausgewählte Einträge bearbeiten willst?')) {
        this.selectedPaybacks.forEach(element => {
          const check = { app: approved, id: element.receiptID }
          this.sendApproval(check)
        })
        this.selectedPaybacks = [] // Reset Select MAYBE REMOVE IF NOT WANTED....
      }
    },
    submitApproval: function (approved) {
      const check = { app: approved, id: this.checkItem.receiptID }
      this.sendApproval(check)
    },
    sendApproval: function (data) {
      this.$store.dispatch('submitPaybackCheck', data).then(response => {
        if (parseInt(response.data.noErrors) === 0) {
          this.$store.dispatch('getPaybackRequests')
          this.closeEditDialog()
          this.loadPaybackData()
        } else {
          this.$store.commit('setApiErrorResponse', response.data)
        }
      })
    },
    async loadPaybackData () {
      this.$store.dispatch('loadAdminPayback')
    },
    openEditDialog: function (item) {
      this.checkItem = item
      this.dialog = true
    },
    saveEditDialog: async function () {
      //  const that = this
      const response = await axios.post(process.env.VUE_APP_API_PATH_ADMIN_PAYBACK_UPDATE + '?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password, qs.stringify(this.checkItem), { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } })
      if (parseInt(response.data.noErrors) === 0) {
        this.closeEditDialog()
        this.loadPaybackData()
      } else {
        alert(response.data.errorMsg)
      }
    },
    closeEditDialog: function () {
      this.checkItem = null
      this.dialog = false
    }
  }
}
</script>
