<template>
  <div>
  <v-container fluid>
    <userLicenses></userLicenses>
   <UserFlightPrivilegesIframe></UserFlightPrivilegesIframe>
    <v-row>
          <v-col cols="12">
            <userFlightStatistic></userFlightStatistic>
          </v-col>
        </v-row>
    </v-container>
  </div>
  </template>

<script>
import userLicenses from '../components/UserLicenses.vue'
import userFlightStatistic from '../components/UserFlightStatisticCard.vue'
import UserFlightPrivilegesIframe from '../components/UserFlightPrivilegesIframe.vue'

export default {
  name: 'License',
  data: () => ({
  }),
  components: {
    userFlightStatistic,
    userLicenses,
    UserFlightPrivilegesIframe
  }
}
</script>
