<template>
<v-container fluid>
   <CURDTable tableId="couponTable" title="Gutscheine" newItemText="Gutschein anlegen"
              editItemText="Gutschein bearbeiten" :dataSchema="dataSchemaCoupons" loadDataFunction="loadAllCoupons"
              insertDataFunction="insertCoupon" updateDataFunction="updateCoupon" removeDataFunction="deleteCoupon"
              storeData="Coupons" showNumberOfItems showSearch update remove info insert></CURDTable>
 </v-container>
</template>

<script>
import CURDTable from '../../components/curdTable.vue'
export default {
  name: 'AdminTransactions',
  data: () => ({
    dataSchemaCoupons: [
      { dataId: 'couponID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'code', type: 'text', text: 'CODE', defaultValue: '', showInTable: true, sortable: false, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'creatorID', type: 'autocomplete', text: 'Erstellt von', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'creationDate', type: 'date', text: 'Erstellt am', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'validUntil', type: 'date', text: 'Gültig bis', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'value', type: 'float', steps: 0.01, text: 'Wert', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'givenBy', type: 'autocomplete', text: 'Ausgegeben von', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'paymentMethod', type: 'text', text: 'zahlart', defaultValue: '', showInTable: false, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true },
      { dataId: 'status', type: 'autocomplete', text: 'Status', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'CouponStatus', autocompleteValue: 'statusID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList', disableInputOnInsert: true },
      { dataId: 'comment', type: 'text', text: 'Kommentar', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true }
    ]
  }),
  components: {
    CURDTable
  }

}
</script>
