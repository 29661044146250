<template>
<v-container fluid>
   <CURDTable tableId="transactionTable" title="Transaktionen" newItemText="Neue Transaktion"
              editItemText="Transaktion Bearbeiten" :dataSchema="dataSchemaUserTransactions" loadDataFunction="loadAllTransactions"
              insertDataFunction="insertTransaction" updateDataFunction="updateTransaction" removeDataFunction="deleteTransaction"
              storeData="AllUserTransactions" showNumberOfItems showSearch update remove info insert showSelectHeaders></CURDTable>
 </v-container>
</template>

<script>
import CURDTable from '../../components/curdTable.vue'
export default {
  name: 'AdminTransactions',
  data: () => ({
    allUserTransactions: [],
    dataSchemaUserTransactions: [
      { dataId: 'transactionID', type: 'number', text: 'ID', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: false },
      { dataId: 'userID', type: 'autocomplete', text: 'Mitglied', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PilotList', autocompleteValue: 'userID', autocompleteText: 'fullname', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'amount', type: 'float', steps: 0.01, text: 'Betrag', customInputLabel: 'Betrag   (NEGATIV: Rechnung an Mitglied; POSITIV: Gutschrift)', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'floatToCurrency' },
      { dataId: 'date', type: 'date', text: 'Datum', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, tableCustomTemplate: true, tableCustomTemplateReplace: 'formatDate' },
      { dataId: 'paymentMethod', type: 'autocomplete', text: 'Zahlart', defaultValue: 1, showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'PaymentTypeList', autocompleteValue: 'paymenttypeID', autocompleteText: 'title', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'costCenter', type: 'autocomplete', text: 'Kostenstelle', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true, autocompleteItems: 'CostCenterList', autocompleteValue: 'costCenterID', autocompleteText: 'name', tableCustomTemplate: true, tableCustomTemplateReplace: 'loadReferenceList' },
      { dataId: 'comment', type: 'text', text: 'Kommentar', defaultValue: '', showInTable: true, sortable: true, cols: 12, colSm: 12, colMd: 12, showInInputDialog: true }
    ]
  }),
  components: {
    CURDTable
  }

}
</script>
