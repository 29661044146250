<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
        <v-row flex>
          <v-col cols="12" md="2">
            <v-file-input label="Beleg" v-model="payback.files" :rules="fileRules" outlined type="file" dense
              accept="image/*, .pdf" required :disabled="editMode"></v-file-input>
          </v-col>
          <v-col cols="12" md="1">
            <v-text-field v-model="payback.amount" type="number" required step="0.01" label="Betrag in €" />
          </v-col>
          <v-col cols="12" md="1">
            <v-text-field v-model="payback.billingDate" type="date" required :rules="dateRules" label="Rechnungsdatum" />
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete :items="computedCategoryList" required :rules="selectRules" v-model="payback.category" item-text="title" item-value="typeID"
              label="Art der Rechnung" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete v-model="payback.costCenter"  required :items="$store.state.CostCenterList" dense outlined
               item-value="costCenterID" item-text="name" label="Kostenstelle">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete :items="$store.state.PaybackTypeList" required v-model="payback.paybackType" item-text="title"
              item-value="categoryID" label="Art der Rückzahlung" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete v-model="payback.paybackTo" required :items="computedPaybackTo" dense outlined
              :disabled="payback.paybackType === 5" item-value="userID" item-text="fullname" label="Rückzahlung an">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" v-if="payback.paybackType === 5 || payback.paybackType === 2">
            <v-text-field v-model="payback.iban" type="text" outlined label="IBAN" :rules="[ complexIbanRule, checkIbanValue ]" />
          </v-col>
          <v-col cols="12" md="2" v-if="payback.paybackType === 5 || payback.paybackType === 2">
            <v-text-field v-model="payback.bic" type="text" outlined label="BIC" />
          </v-col>
          <v-col cols="12" md="2" v-if="payback.paybackType === 5 || payback.paybackType === 2">
            <v-text-field v-model="payback.partnerName" type="text" outlined label="Partner" :rules="[ complexIbanRule ]" />
          </v-col>
          <v-col cols="12" md="2" v-if="payback.paybackType === 5 || payback.paybackType === 2">
            <v-text-field v-model="payback.customerRef" type="text" outlined label="Kundenreferenz" :rules="[ complexIbanRule ]" />
          </v-col>
          <v-col cols="12" md="2" v-if="payback.paybackType === 5 || payback.paybackType === 2">
            <v-text-field v-model="payback.invoiceNo" type="text" outlined label="Rechnungsnummer" :rules="[ complexIbanRule ]" />
          </v-col>
          <v-col cols="12">
            <v-textarea filled v-model="payback.comment" :rules="commentRules" label="Kommentar (min. 10 zeichen)" rows="2" auto-grow></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="payback.agreedWith" required :items="AgreeWithList" :rules="agreedWithRule" dense outlined clearable
              item-value="id" item-text="name" label="Anschaffung vorher abgesprochen mit">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-checkbox v-model="payback.checkbox"
          :rules="[v => !!v || 'Bitte bestätige deine Angaben!']"
          label="Meine Angaben sind alle korrekt und der Beleg ist angehangen." required></v-checkbox>

        <v-btn :disabled="(!valid && !editMode) || loading" color="success" class="mr-4"
          @click="send">
          {{ editMode ? 'Bearbeiten' : 'Hinzufügen'}}
        </v-btn>
        <v-btn v-if="editMode" outlined color="error" class="mr-4"
          @click="resetEdit">
          Abbrechen
        </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-data-table v-if="computedPilotList.length > 0 && computedCategoryList.length > 0" :items="computedPaybackList"
      :headers="headers" sort-by="creationTime" :sort-desc="true" no-data-text="Keine Rückzahlungen vorhanden...">
      <template v-slot:item.creationTime="{ item }"><span>{{
        getDateFromTimestamp(item.creationTime)
      }}</span></template>
      <template v-slot:item.amount="{ item }"><span>{{
        item.amount
      }}€</span></template>
      <template v-slot:item.category="{ item }"><span>{{
        item.category != null ? computedCategoryList.filter(e =>
          e.typeID == item.category)[0].title : 'ERROR'
      }}</span></template>
      <template v-slot:item.paybackType="{ item }"><span>{{
        item.paybackType != null ?
          $store.state.PaybackTypeList.filter(e => e.categoryID == item.paybackType)[0].title : 'ERROR'
      }}</span></template>
      <template v-slot:item.paybackTo="{ item }"><span><v-icon x-small>fa-user</v-icon> {{
        item.paybackTo != null ?
          $store.state.PilotList.filter(e => e.userID == item.paybackTo)[0].firstname + " " +
          $store.state.PilotList.filter(e => e.userID == item.paybackTo)[0].lastname :
          item.pilotName
      }}</span></template>
      <template v-slot:item.approved="{ item }">
        <span>
          <v-chip v-if="item.approved == 0" color="orange" style="color: white"> In Bearbeitung</v-chip>
          <v-tooltip right v-else-if="item.approved == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" color="green" style="color: white">Genehmigt</v-chip>
            </template>
            <span>Genehmigt von {{
              item.approvedBy != null ? $store.state.PilotList.filter(e => e.userID ==
                item.approvedBy)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID ==
                  item.approvedBy)[0].lastname : 'unbekannt'
            }}</span>
          </v-tooltip>
          <v-tooltip right v-else-if="item.approved == -1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip  v-bind="attrs" v-on="on"  color="red" style="color: white">Abgelehnt</v-chip>
            </template>
            <span>Abgelehnt von {{
              item.approvedBy != null ? $store.state.PilotList.filter(e => e.userID ==
                item.approvedBy)[0].firstname + " " + $store.state.PilotList.filter(e => e.userID ==
                  item.approvedBy)[0].lastname : 'unbekannt'
            }}</span>
          </v-tooltip>
          <v-chip v-else-if="item.approved == 2"  color="grey"
            style="color: white">Erledigt</v-chip>
        </span>
      </template>
      <template v-slot:item.action="{ item }">
      <v-btn icon :disabled="item.approved !== 0" @click="editPayback(item)"><v-icon small>mdi-lead-pencil</v-icon></v-btn></template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Payback',
  data: () => ({
    loading: false,
    valid: true,
    editMode: false,
    headers: [
      { text: 'Status', value: 'approved' },
      { text: 'Datum', value: 'creationTime' },
      { text: 'Betrag', value: 'amount' },
      { text: 'Kategorie', value: 'category' },
      { text: 'Kostenstelle', value: 'costCenterName' },
      { text: 'Kommentar', value: 'comment' },
      { text: 'Rückzahlung', value: 'paybackType' },
      { text: 'An', value: 'paybackTo' },
      { text: 'Aktion', value: 'action' }
    ],
    payback: {
      checkbox: false,
      files: null,
      amount: 0,
      category: 0,
      date: null,
      paybackType: 0,
      costCenter: 1,
      paybackTo: null,
      agreedWith: null,
      iban: '',
      bic: '',
      customerRef: '',
      parnerName: '',
      invoiceNo: '',
      comment: ''
    },
    defaultItem: {
      checkbox: false,
      files: null,
      amount: 0,
      category: 0,
      date: null,
      paybackType: 0,
      paybackTo: null,
      agreedWith: null,
      costCenter: 1,
      iban: '',
      bic: '',
      customerRef: '',
      parnerName: '',
      invoiceNo: '',
      comment: ''
    },
    commentRules: [
      v => !!v || 'Kommentar ist ein Pflichtfeld',
      v => (v && v.length > 9) || 'Kommentar muss mindestens 10 Zeichen lang sein.'
    ],
    dateRules: [
      v => !!v || 'Datum ist ein Pflichtfeld'
    ],
    agreedWithRule: [
      v => !!v || 'Mit wem wurde die Rückzahlung abgesprochen?'
    ],
    selectRules: [
      v => !!v || 'Pflichtfeld - Bitte wähle eine Option'
    ],
    fileRules: [
      v => !!v || 'Du musst mindestens eine Datei anhängen!'
    ],
    AgreeWithList: [
      { name: 'Steffen Dienst', id: 32 },
      { name: 'Florian Spinty', id: 10 },
      { name: 'Leonie Fritsch', id: 42 },
      { name: 'Torsten Gutzeit', id: 45 },
      { name: 'Stephan Schmidt', id: 72 },
      { name: 'Matthias Langrock', id: 54 },
      { name: 'Sophia Dienst', id: 31 },
      { name: 'Sven Büchner', id: 29 }
    ]
  }),
  computed: {
    computedPilotList () {
      return this.$store.state.PilotList
    },
    computedPaybackTo () {
      if (this.payback.paybackType === 5) {
        return this.$store.state.PilotList.filter(e => e.userID === this.$store.state.User.userID)
      }
      return this.$store.state.PilotList
    },
    computedPaybackList () {
      return this.$store.state.UserPayback
    },
    computedCategoryList () {
      return this.$store.state.PaybackCategoryList
    }
  },
  mounted () {
    this.defaultItem.paybackTo = this.$store.state.User.userID
    this.payback = Object.assign({}, this.defaultItem)
    this.$store.dispatch('loadUserPaybackList')
  },
  methods: {
    /*
    * Returns 1 if the IBAN is valid
    * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
    * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
    */
    isValidIBANNumber (input) {
      var CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
        AL: 28,
        BY: 28,
        CR: 22,
        EG: 29,
        GE: 22,
        IQ: 23,
        LC: 32,
        SC: 31,
        ST: 25,
        SV: 28,
        TL: 23,
        UA: 29,
        VA: 22,
        VG: 24,
        XK: 20
      }
      var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
      var code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
      var digits
      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55
      })
      // final check
      return this.mod97(digits) === 1
    },
    mod97 (string) {
      var checksum = string.slice(0, 2)
      var fragment
      for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7)
        checksum = parseInt(fragment, 10) % 97
      }
      return checksum
    },
    editPayback (item) {
      this.editMode = true
      this.payback = item
      this.payback.checkbox = true
    },
    complexIbanRule (value) {
      if (this.payback.paybackType === 5 && value.length === 0) {
        return 'Diese Felder sind Pflichtfelder!'
      }
      return true
    },
    checkIbanValue (value) {
      var check = this.isValidIBANNumber(value)
      console.log(value, check)
      if (check === true) {
        return true
      }
      return 'Die IBAN ist nicht VALIDE'
    },
    resetEdit () {
      this.payback = Object.assign({}, this.defaultItem)
      this.resetValidation()
      this.editMode = false
    },
    async validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
      this.editMode = false
    },
    getDateFromTimestamp (stamp) {
      return moment(stamp, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY')
    },
    async send () {
      await this.validate()
      if (this.valid === false && !this.editMode) return // Abbruch wenn nicht valide form
      this.loading = true
      if (this.payback.paybackType === 5) {
        this.payback.paybackTo = this.$store.state.User.userID
      }
      var that = this
      if (this.editMode) {
        // send update
        this.$store.dispatch('updatePayback', this.payback).then(response => {
          //  console.log(response)

          if (parseInt(response.data.noErrors) === 0) {
            // Update Plane
            this.$store.dispatch('loadUserPaybackList')
            this.payback = Object.assign({}, this.defaultItem)
            this.resetValidation()
            this.editMode = false
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
          this.loading = false
        }).catch(function () {
          that.loading = false
        })
      } else {
        // send create
        this.$store.dispatch('insertPayback', this.payback).then(response => {
          //  console.log(response)

          if (parseInt(response.data.noErrors) === 0) {
            // Update Plane
            this.$store.dispatch('loadUserPaybackList')
            this.payback = Object.assign({}, this.defaultItem)
            this.resetValidation()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
          this.loading = false
        }).catch(function () {
          that.loading = false
        })
      }
    }
  }
}
</script>
