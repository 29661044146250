<template>
  <div>
    <v-text-field v-if="showSearch" v-model="search" label="Suchen" class="mx-4"></v-text-field>
    <v-data-table :headers="computedSelectedHeaders" :items="computedItems" :search="search" multi-sort
      :id="tableId" class="elevation-1"
      :items-per-page="itemsPerPage"
      :mobile-breakpoint="computedMobileBreakingPoint" :show-select="itemsSelectable" :single-select="!itemsSelectableMultiple" v-model="selectedItems" :item-key="itemSelectKey">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> {{ showNumberOfItems? computedNumberOfItems: null }} {{ title }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <input type="date" label="Von" v-model="from" v-if="enableDateSpan">
          <input type="date" v-model="until" label="Bis"  v-if="enableDateSpan">
          <v-btn @click="loadDataByDateSpan(from, until)" :disabled="until === '' || from === ''" color="indigo accent-4"  outlined  v-if="enableDateSpan">Filter</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-for="(button, index) in customTableButtonsForSelectedItems" :key="index" :color="button.color" :disabled="selectedItems.length <= 0"
          @click="button.function == 'emit' ? $emit(button.functionName, selectedItems) : button.function == 'dispatch' ? $store.dispatch(button.functionName, selectedItems) : ''">
            {{ button.text }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-autocomplete v-if="showSelectHeaders" v-model="selectedHeaders" :items="computedItemHeader" label="Spalten" multiple chips deletable-chips return-object style="transform:translateY(10px)">
            <!-- <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">(+{{ selectedHeaders.length - 1 }} others)</span>
            </template> -->
          </v-autocomplete>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" :max-width="dialogWidth">
            <template v-slot:activator="{ on, attrs }" v-if="insert">
              <v-btn color="success" dark class="mb-2" v-bind="attrs" v-on="on">
                {{ insertButtonText }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col v-for="input in computedInputFields" :key="input.dataId" cols="12" sm="12"
                      md="4">
                      <v-text-field
                        v-model="editedItem[input.dataId]" type="text"
                        :label="input.dataId"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="red darken-1" text @click="closeNew">
                  Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="saveNew" :disabled="disableInputs">
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-text> <strong>{{ dataUIDField }}: {{ editedItem[dataUIDField] }}</strong></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-for="header in computedSelectedHeaders"  v-slot:[`item.${header.value}`]="{ item }">
        <v-edit-dialog
          :key="header.dataId"
          :return-value.sync="item[header.value]"
          @save="sendUpdate(item)"
          @cancel="cancel"
          @open="open"
          @close="close"
        >
          {{ item[header.value] }}
          <template v-slot:input>
            <v-text-field
              v-model="item[header.value]"
              label="Edit"
              persistent-hint
              :disabled="header.value == dataUIDField"
              :hint="`${header.value} of Entry #${item[dataUIDField]}`"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="info" small class="mr-2" @click="openInfoItem(item)" color="blue">
          mdi-information-outline
        </v-icon>
        <v-icon v-if="update" small class="mr-2" @click="openEditItem(item)" color="green">
          mdi-pencil
        </v-icon>
        <v-icon v-if="remove" small @click="openDeleteItem(item)" color="red">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        Keine Daten vorhanden.
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from 'axios'
import Sortable from 'sortablejs'
var qs = require('qs')
// Add back the sortHandle class if it gets stripped away by external code
function watchClass (targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch)
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i]
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch)
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle')
          }
        }
      }
    }
  })
  observer.observe(targetNode, { attributes: true })
}

export default {
  props: {
    tableId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    newItemText: {
      type: String,
      required: false,
      default () {
        return 'Neuer Eintrag'
      }
    },
    infoItemText: {
      type: String,
      required: false,
      default () {
        return 'Information'
      }
    },
    customActionButtons: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    customTableButtonsForSelectedItems: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    showNumberOfItems: {
      type: Boolean,
      required: false,
      default: false
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    insert: {
      type: Boolean,
      required: false,
      default: false
    },
    insertButtonText: {
      type: String,
      required: false,
      default () {
        return 'Hinzufügen'
      }
    },
    dataUIDField: {
      type: String,
      required: true
    },
    actionsLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    update: {
      type: Boolean,
      required: false,
      default: false
    },
    remove: {
      type: Boolean,
      required: false,
      default: false
    },
    info: {
      type: Boolean,
      required: false,
      default: false
    },
    rightClickMenu: {
      type: Boolean,
      required: false,
      default: false
    },
    loadDataPath: {
      type: String,
      required: true
    },
    loadDataFunctionPayload: {
      required: false,
      default: null
    },
    insertDataPath: {
      type: String,
      required: false
    },
    updateDataPath: {
      type: String,
      required: false
    },
    removeDataPath: {
      type: String,
      required: false
    },
    dialogWidth: {
      type: String,
      required: false,
      default: '1000px'
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    },
    showSelectHeaders: {
      type: Boolean,
      required: false,
      default: false
    },
    disableMobileView: {
      type: Boolean,
      required: false,
      default: false
    },
    itemsSelectable: {
      type: Boolean,
      required: false,
      default: false
    },
    itemSelectKey: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    itemsSelectableMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    enableDateSpan: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    snack: false,
    snackColor: '',
    snackText: '',
    search: '',
    dialog: false,
    dialogDelete: false,
    disableInputs: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedItems: [],
    selectedHeaders: [],
    anIncreasingNumber: 1,
    from: '',
    until: '',
    data: []
  }),
  computed: {
    dataSchema () {
      if (this.data.length === 0) return []
      var array = []
      for (const [key] of Object.entries(this.data[0])) {
        // console.log(`${key}: ${value}`)
        array.push({ dataId: key })
      }
      return array
    },
    computedHeadersInTable () {
      return this.dataSchema
    },
    computedItemHeader () {
      // Filter all Items in dataSchema where showInTable TRUE
      var headersToRender = this.computedHeadersInTable
      if (headersToRender.length === 0) return []
      var headers = []
      // Filter to Header Array
      headersToRender.forEach(element => {
        headers.push({ text: element.dataId, value: element.dataId, sortable: true })
      })

      // Add Action Header if update or remove Prop isset AND richtclickMenu is DISABLED (FALSE)
      var actionHeader = { text: 'Aktion', value: 'actions', sortable: false }

      if (this.update === true || this.remove === true || this.info === true) {
        this.actionsLeft ? headers.unshift(actionHeader) : headers.push(actionHeader)
      }
      return headers
    },
    formTitle () {
      return this.newItemText
    },
    computedNumberOfItems () {
      return this.data.length
    },
    computedInputFields () {
      return this.dataSchema
    },
    computedItems () {
      return this.data
    },
    computedSelectedHeaders () {
      if (this.selectedHeaders.length === 0) {
        return this.computedItemHeader
      }
      return this.selectedHeaders
    },
    computedMobileBreakingPoint () {
      if (this.disableMobileView) {
        return '0' // 0 = Disable Mobile Table Breaking
      }
      return '600' // Vuetify Default Value
    }
  },
  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    },
    loadDataFunctionPayload (newVal, oldVal) {
      this.loadData()
    }
  },
  mounted () {
    // DISABLE RIGHT CLICK
    document.getElementById(this.tableId).addEventListener('contextmenu', function (e) { e.preventDefault() }, false)
    // Load or Reload data in Store
    this.loadData()
    // Create Default Item from Data Schema an assign defaultItem to editedItem
    this.dataSchema.forEach(element => {
      this.defaultItem[element.dataId] = element.defaultValue
    })
    this.editedItem = Object.assign({}, this.defaultItem)
  },
  created () {
    this.selectedHeaders = this.computedItemHeader
  },
  methods: {
    sendUpdate (item) {
      axios.get(process.env[this.updateDataPath] + '?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password + '&' + qs.stringify(item), { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } })
        .then(response => {
          if (response.data.noErrors === 0) {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Data saved'
            this.loadData()
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'ERROR: ' + response.data.errorMsg
          }
        })
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close () {
      console.log('Dialog closed')
    },
    loadData () {
      console.log('load Data Triggered')
      axios.get(process.env[this.loadDataPath] + '?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password, { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } })
        .then(response => {
          this.data = response.data.result
        })
    },
    loadDataByDateSpan () {
      console.log('load Data with time span Triggered')
      axios.get(process.env[this.loadDataPath] + '?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password + '&d=' + this.from + '&sd=' + this.until, { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } })
        .then(response => {
          this.data = response.data.result
        })
    },
    openDeleteItem (item) {
      this.editedIndex = this.computedItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      console.log('Delete Function')
      axios.get(process.env[this.removeDataPath] + '?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password + '&' + qs.stringify(this.editedItem), { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } })
        .then(response => {
          if (response.data.noErrors === 0) {
            // Insert Successfull
            this.loadData()
            this.closeDelete()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeNew () {
      this.dialog = false
      this.disableInputs = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    saveNew () {
      axios.get(process.env[this.insertDataPath] + '?user=' + this.$store.state.User.auth.username + '&pw=' + this.$store.state.User.auth.password + '&' + qs.stringify(this.editedItem), { headers: { Authorization: `Basic ${this.$store.state.User.auth.authStr}` } })
        .then(response => {
          if (response.data.noErrors === 0) {
            // Insert Successfull
            this.loadData()
            this.closeNew()
          } else {
            this.$store.commit('setApiErrorResponse', response.data)
          }
        })
    }
  },
  directives: {
    'sortable-table': {
      inserted: (el, binding) => {
        el.querySelectorAll('th').forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, 'sortHandle')
          draggableEl.classList.add('sortHandle')
        })
        Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {})
      }
    }
  }
}
</script>
